import {LoginEvent} from './LoginEvent';
import {LogoutEvent} from './LogoutEvent';
import eventBus from '../eventsbus/EventBus';
import { jwtDecode } from 'jwt-decode';
import {AuthJWTPayload} from '@app/auth/jwt/AuthJWTPayload';
import clock from '@app/time/Clock';
import refreshTokenService from '@app/auth/RefreshTokenService';
import {Permission} from '@app/auth/permissions/Permission';
import {Uuid} from '@app/uuid/Uuid';
import {Serializer, useStorage} from '@vueuse/core';
import {computed} from 'vue';

/**
 * Custom token localStorage serializer.
 *
 * When passing undefined as default to useStorage, it strictly serializes everything as a string.
 * See: https://github.com/vueuse/vueuse/blob/bacf40299b328af7f4acd0c14a3ec72a35197821/packages/core/useStorage/guess.ts#L2.
 * So we parse the stringified undefined value "undefined" as undefined.
 */
const tokenSerializer: Serializer<string | undefined> = {
    read(raw: string): string | undefined {
        if (raw === String(undefined)) {
            return undefined;
        }
        return raw;
    }, write(value: string | undefined): string {
        return String(value);
    },
};

const authToken = useStorage<string | undefined>('authToken', undefined, undefined, {serializer: tokenSerializer});
const refreshToken = useStorage<string | undefined>('refreshToken', undefined, undefined, {serializer: tokenSerializer});

const authTokenPayload = computed(() => {
    if (!authToken.value) {
        return undefined;
    }

    return jwtDecode<AuthJWTPayload>(authToken.value);
});

export const hasAuthToken = computed(() => !!authToken.value);

export const userId = computed(() => authTokenPayload.value?.userId ? Uuid.fromString(authTokenPayload.value.userId) : undefined);

export const userPermissions = computed(() => authTokenPayload.value ? new Set<Permission>(authTokenPayload.value.permissions) : new Set<Permission>);

export const userCompanyId = computed(() => authTokenPayload.value ? Uuid.fromString(authTokenPayload.value.companyId) : undefined);

// this file is currently split in the auth class and the "vuey" refs.
// the refs are newer and should be prefered, as they fit better into the vue application

class Auth {
    private runningRefreshRequest?: Promise<void>;

    setTokenPair(newAuthToken: string, newRefreshToken: string): void {
        authToken.value = newAuthToken;
        refreshToken.value = newRefreshToken;

        const loginEvent = new LoginEvent;
        eventBus.emit(loginEvent);
    }
    /**
     * @throws MissingAuthTokenError
     */
    private getAuthTokenPayload() {
        if (!authTokenPayload.value) {
            throw new MissingAuthTokenError();
        }

        return authTokenPayload.value;
    }

    /**
     * @throws MissingRefreshTokenError
     */
    getCompanyId() {
        return Uuid.fromString(this.getAuthTokenPayload().companyId);
    }

    logout() {
        eventBus.emit(new LogoutEvent());

        authToken.value = undefined;
        refreshToken.value = undefined;
    }

    /**
     * @throws MissingAuthTokenError
     */
    getAuthToken() {
        if (!authToken.value) {
            throw new MissingAuthTokenError();
        }
        return authToken.value;
    }

    /**
     * @throws MissingRefreshTokenError
     */
    getRefreshToken() {
        if (!refreshToken.value) {
            throw new MissingRefreshTokenError();
        }
        return refreshToken.value;
    }

    /**
     * @throws MissingAuthTokenError
     */
    isAuthTokenExpired() {
        const expiryTimestamp = this.getAuthTokenPayload().exp;
        const nowTimestamp = clock.now()
            .timestamp;

        // 30 seconds buffer for token expiry
        return (nowTimestamp + 30) > expiryTimestamp;
    }

    async ensureValidToken() {
        if (!hasAuthToken.value) {
            return;
        }
        if (!this.isAuthTokenExpired()) {
            return;
        }

        try {
            if (!this.runningRefreshRequest) {
                this.runningRefreshRequest = this.refreshToken();
            }

            await this.runningRefreshRequest;
        } catch (e) {
            this.logout();
            throw new SessionExpiredError;
        } finally {
            this.runningRefreshRequest = undefined;
        }
    }

    private async refreshToken() {
        const {data} = await refreshTokenService.refreshToken({
            refreshToken: this.getRefreshToken(),
        });

        this.setTokenPair(data.authToken, data.refreshToken);
    }
}

class MissingTokenError extends Error {
}

export class SessionExpiredError extends Error {
}

export class MissingAuthTokenError extends MissingTokenError {
}

export class MissingRefreshTokenError extends MissingTokenError {
}

export default new Auth();
