import {StatisticsServiceContract} from '@app/statistics/StatisticsServiceContract';
import {ResponseContract} from '@app/http/ResponseContract';
import {Statistics} from '@app/statistics/Statistics';
import httpClient from '@app/http/HttpClient';

export interface StatisticResponsePayload {
    totalTendersCurrentMonth: number,
    completedTendersCurrentMonth: number,
    openTenders: number,
    pendingPartialOrders: number,
}

class StatisticsService implements StatisticsServiceContract{
    async fetch(): Promise<ResponseContract<Statistics>> {
        const responseContract: ResponseContract<StatisticResponsePayload> = await httpClient.get('/secure/statistic/');
        return {
            ...responseContract,
            data: this.mapResponseData(responseContract.data),
        };
    }

    private mapResponseData(data: StatisticResponsePayload): Statistics {
        const mapped: Statistics & {totalCompletedTendersCurrentMonth?: number} = {
            ...data,
            completedTendersCurrentMonth: data.completedTendersCurrentMonth,
        };

        delete mapped.totalCompletedTendersCurrentMonth;

        return mapped;
    }
}

export const statisticsService: StatisticsServiceContract = new StatisticsService;
