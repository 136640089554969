<template>
  <TrSection label-id="dashboard-statistics">
    <template #head>
      <SectionHeading id="dashboard-statistics">
        {{ $t('dashboard.statistics.head') }}
      </SectionHeading>
    </template>

    <div
      v-if="loading"
      class="px-4 py-5"
    >
      <Spinner />
    </div>

    <dl
      v-if="!loading && statistics"
      class="grid grid-cols-1 divide-y divide-gray-200 lg:grid-cols-4 lg:divide-x lg:divide-y-0 -my-6"
    >
      <Statistic
        class="pr:0 lg:pr-4"
        :label="$t('dashboard.statistics.totalTendersCurrentMonth')"
        :value="statistics.totalTendersCurrentMonth"
      />

      <Statistic
        class="px-0 lg:px-4"
        :label="$t('dashboard.statistics.completedTendersCurrentMonth')"
        :value="statistics.completedTendersCurrentMonth"
      />

      <Statistic
        class="px-0 lg:px-4"
        :label="$t('dashboard.statistics.openTenders')"
        :value="statistics.openTenders"
      />

      <Statistic
        class="pl-0 lg:pl-4"
        :label="$t('dashboard.statistics.pendingPartialOrders')"
        :value="statistics.pendingPartialOrders"
      />
    </dl>
  </TrSection>
</template>

<script lang="ts" setup>
import {statisticsService} from '@app/statistics/StatisticsService';
import {ref} from 'vue';
import {Statistics} from '@app/statistics/Statistics';
import Spinner from '@app/support/Spinner.vue';
import Statistic from '@app/dashboard/Statistics/Statistic.vue';
import TrSection from '@app/support/TrSection.vue';
import SectionHeading from '@app/support/SectionHeading.vue';

const loading = ref(false);
const statistics = ref<Statistics>();

const fetchStatistics = async () => {
  loading.value = true;

  try {
    const { data } = await statisticsService.fetch();
    statistics.value = data;
  } finally {
    loading.value = false;
  }
};

fetchStatistics();
</script>
