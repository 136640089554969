import {Activity} from '@app/activities/types/Activity';
import {User} from '@app/auth/User';
import {ActivityType} from '@app/activities/ActivityType';
import {DateTime} from '@app/time/DateTime';
import {Uuid} from '@app/uuid/Uuid';
import {ActivityGenerator} from '@app/activities/ActivityGenerator';
import {ActivitiesResponseItem} from '@app/activities/ActivitiesResponsePayload';
import {UuidString} from '@app/uuid/UuidString';

export class UserInvitedActivity implements Activity {
    readonly id: Uuid;
    readonly timestamp: DateTime;
    readonly type: ActivityType;
    readonly user: User;
    readonly invitedUserId: Uuid;
    readonly invitedUserName: string;

    constructor(id: Uuid, timestamp: DateTime, user: User, invitedUserId: Uuid, invitedUserName: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.user = user;
        this.type = ActivityType.USER_INVITED;
        this.invitedUserId = invitedUserId;
        this.invitedUserName = invitedUserName;
    }
}

export class UserInvitedActivityGenerator implements ActivityGenerator<UserInvitedActivity> {
    generate(data: ActivitiesResponseItem): UserInvitedActivity {
        return new UserInvitedActivity(
            Uuid.fromString(data.id),
            DateTime.parseIsoUTC(data.activityTimestamp),
            {
                ...data.triggeredBy,
                id: Uuid.fromString(data.triggeredBy.id),
            },
            Uuid.fromString(data.activityPayload.invitedUserId as UuidString),
            data.activityPayload.invitedUserName as string,
        );
    }

    getType(): ActivityType {
        return ActivityType.USER_INVITED;
    }

}
