<template>
  <Component
    :is="isCTAToast(toastDefinition) ? CTAToast : SimpleToast"
    v-for="toastDefinition in toastDefinitions"
    :key="toastDefinition.uuid.toString()"
    :title="toastDefinition.title"
    show
    :duration="toastDefinition.duration"
    :body="toastDefinition.body"
    :mode="toastDefinition.mode"
    :cta-text="isCTAToast(toastDefinition) ? toastDefinition.ctaText : undefined"
    :data-ct="toastDefinition.testingIdentifier"
    :data-cy="toastDefinition.testingIdentifier"
    @hide="removeToast(toastDefinition.uuid)"
    @cta-clicked="toastAction(toastDefinition)"
  />
</template>

<script lang="ts" setup>
import SimpleToast from '@app/overlays/toast/SimpleToast.vue';
import toastManager, {ToastDefinition, removeToast, CTAToastDefinition} from '@app/overlays/toast/ToastManager';
import {computed, ref} from 'vue';
import CTAToast from '@app/overlays/toast/CTAToast.vue';

const toastDefinitionData = ref<ToastDefinition[]>();

const toastDefinitions = computed(() => toastDefinitionData.value ? toastDefinitionData.value : []);

toastManager.subscribe((newDefinitions) => toastDefinitionData.value = newDefinitions);

function isCTAToast(toastDefinition: ToastDefinition): toastDefinition is CTAToastDefinition {
    return Object.hasOwn(toastDefinition, 'ctaText');
}

function toastAction(toastDefinition: ToastDefinition) {
    if (isCTAToast(toastDefinition)) {
        toastDefinition.ctaAction();
    }
}
</script>
