import {EventContract} from './EventContract';

interface EventSubscriber {
    (event: EventContract): void
}

class EventBus {
    private subscribers: {
        [eventName: string]: EventSubscriber[]
    } = {};

    on(eventName: string, callback: EventSubscriber) {
        if (!Array.isArray(this.subscribers[eventName])) {
            this.subscribers[eventName] = [];
        }

        this.subscribers[eventName].push(callback);
    }

    emit(event: EventContract) {
        const eventSubscribers = this.subscribers[event.getEventName()];

        if (!Array.isArray(eventSubscribers)) {
            return;
        }

        eventSubscribers.forEach((subscriber) => {
            subscriber(event);
        });
    }
}

export default new EventBus();
