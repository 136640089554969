<template>
  <Link
    :to="`/truckoffer/${truckOffer.id}`"
    class="block hover:bg-gray-50 bg-white rounded"
  >
    <div class="px-4 py-4 sm:px-6">
      <div class="flex items-center justify-between">
        <p class="text-sm font-medium text-primary-600 truncate">
          {{ truckOffer.title }}
        </p>
        <div
          class="ml-2 flex-shrink-0 flex"
        >
          <Badge :type="badgeType">
            {{ translatedStatus }}
          </Badge>
        </div>
      </div>
      <div class="mt-2 sm:flex sm:space-x-4">
        <div class="mt-2 sm:flex-none flex items-center text-sm text-gray-500 sm:mt-0">
          <TruckIcon
            class="flex-none mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <p>
            {{ truckText }}
          </p>
        </div>
        <div class="mt-2 flex sm:flex-1 items-center text-sm text-gray-500 sm:mt-0 min-w-0">
          <MapPinIcon
            class="flex-none mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span
            class="truncate"
            :title="truckOffer.locationDisplayName"
          >{{ truckOffer.locationDisplayName }}</span>&nbsp;
        </div>
        <div class="mt-2 flex sm:flex-none items-center text-sm text-gray-500 sm:mt-0">
          <CalendarIcon
            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <p>
            {{ $t('tender.timing.timeTo') }}
            {{ ' ' }}
            <time :datetime="truckOffer.dateOfExecution.toISO()">{{ truckOffer.dateOfExecution.toHuman() }}</time>
          </p>
        </div>
      </div>
    </div>
  </Link>
</template>

<script setup lang="ts">
import Link from '@app/navigation/Link.vue';
import {TruckOfferSearchResultItem} from '@app/truckoffer/truckOfferService';
import Badge from '@app/support/badge/Badge.vue';
import {CalendarIcon, MapPinIcon, TruckIcon} from '@heroicons/vue/20/solid';
import {computed, toRefs} from 'vue';
import {BadgeType} from '@app/support/badge/BadgeType';
import {TruckOfferStatus} from '@app/truckoffer/TruckOfferStatus';
import {useI18n} from 'vue-i18n';
import {useTruckTranslations} from '@app/i18n/useTruckTranslations';

interface Props {
  truckOffer: TruckOfferSearchResultItem
}

const props = defineProps<Props>();

const {truckOffer} = toRefs(props);

const {t} = useI18n();

const translatedStatus = computed(() => ({
  [TruckOfferStatus.OPEN]: t('truckOffer.status.open'),
  [TruckOfferStatus.DONE]: t('truckOffer.status.done'),
  [TruckOfferStatus.TAKEN]: t('truckOffer.status.taken'),
  [TruckOfferStatus.CANCELED]: t('truckOffer.status.canceled'),
})[truckOffer.value.status]);

const badgeType = computed(() => ({
  [TruckOfferStatus.OPEN]: BadgeType.SUCCESS,
  [TruckOfferStatus.DONE]: BadgeType.NEUTRAL,
  [TruckOfferStatus.TAKEN]: BadgeType.WARNING,
  [TruckOfferStatus.CANCELED]: BadgeType.WARNING,
})[truckOffer.value.status]);

const {
  truckTypeTranslation,
  truckBedTypeTranslation,
  truckFeaturesAnd,
} = useTruckTranslations();

const truckText = computed(() => {
  if (!truckOffer.value) {
    return '';
  }

  let truckText = `${truckTypeTranslation(truckOffer.value.truckType)}. ${truckBedTypeTranslation(truckOffer.value?.truckBedType)}.`;

  if (truckOffer.value.truckFeatures.length) {
    truckText += ` ${truckFeaturesAnd(truckOffer.value.truckFeatures)}`;
  }

  return truckText;
});
</script>
