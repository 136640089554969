import Time from '@app/time/Time';
import {DateTime, Minutes} from '@app/time/DateTime';

class TimeOptionsGenerator {
    private fromTime: Time = Time.parseIsoUTC('00:00');
    private toTime: Time = Time.parseIsoUTC('23:59');

    static make() {
        return new TimeOptionsGenerator;
    }

    private interval: Minutes = 30;

    generateStrings(): Set<string> {
        const timeOptions: Set<string> = new Set;

        const iterationsForFromTime = this.fromTime.totalMinutes === 0 ? 0 : Math.floor(this.fromTime.totalMinutes / this.interval) + 1;
        const iterationsForToTime = Math.floor(this.toTime.totalMinutes / this.interval);

        for (let i = iterationsForFromTime; i <= iterationsForToTime; i++){
            const hour = Math.floor((i * this.interval) / DateTime.MINUTES_IN_HOUR);
            const minute = i * this.interval - hour * DateTime.MINUTES_IN_HOUR;

            const hourString = hour.toString().padStart(2, '0');
            const minuteString = minute.toString().padStart(2, '0');
            timeOptions.add(`${hourString}:${minuteString}`);
        }

        return timeOptions;
    }

    withFrom(fromTime: Time): TimeOptionsGenerator {
        this.fromTime = fromTime;
        return this;
    }

    withTo(toTime: Time): TimeOptionsGenerator {
        this.toTime = toTime;
        return this;
    }

    withInterval(interval: number) {
        this.interval = interval;
        return this;
    }
}

export default TimeOptionsGenerator;
