import auth, {hasAuthToken} from '@app/auth/Auth';
import {EventSourceMessage, EventStreamContentType, fetchEventSource} from '@microsoft/fetch-event-source';
import {SseMessage} from '@app/sse/SseMessage';

class RetriableError extends Error {}
class FatalError extends Error {}

const fetchWithAuthToken: typeof fetch = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    let internalInit = init;
    let internalInput = input;

    if (typeof internalInput === 'string' && internalInput.startsWith('/')) {
        internalInput = `${import.meta.env.VITE_APP_BACKEND_URL}/api${internalInput}`;
    }

    try {
        await auth.ensureValidToken();
    } catch (e) {
        throw new FatalError();
    }

    if (!internalInit) {
        internalInit = {};
    }

    if (hasAuthToken.value) {
        internalInit.headers = new Headers(internalInit.headers);

        internalInit.headers.set('Authorization', `Bearer ${auth.getAuthToken()}`);
    }

    return fetch(internalInput, init);
};

class SseClient {
    subscribe<E extends SseMessage>(url: string): SseConnection<E> {
        const abortController = new AbortController();
        const connection = new SseConnection<E>(abortController);

        fetchEventSource(url, {
            fetch: fetchWithAuthToken,

            signal: abortController.signal,

            onmessage(ev) {
                connection.handleMessage(ev);
            },
            async onopen(res) {
                if (res.ok && res.headers.get('content-type') === EventStreamContentType) {
                    return; // everything's good
                } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
                    // client-side errors are usually non-retriable
                    throw new FatalError();
                } else {
                    throw new RetriableError();
                }
            },
            onclose() {
                throw new RetriableError();
            },
            onerror(err) {
                if (err instanceof FatalError) {
                    throw err; // rethrow to stop the operation
                }

                if(err instanceof Error && err.message === 'Failed to fetch') {
                    return 5_000; // wait 5 seconds before retrying
                }
            },
        });

        return connection;
    }
}

type SseConsumerMessage<E extends SseMessage> = {
    id: E['id'],
    name: E['name'],
    data: E['data'],
    retry: E['retry']
};
export class SseConnection<E extends SseMessage> {
    private abortController: AbortController;
    private consumers: Map<E['name'], ((event: SseConsumerMessage<E>) => void)[]> = new Map;

    constructor(abortController: AbortController) {
        this.abortController = abortController;
    }

    handleMessage(ev: EventSourceMessage) {
        const eventConsumers = this.consumers.get(ev.event);

        eventConsumers?.forEach((eventConsumer) => eventConsumer({
            id: ev.id,
            name: ev.event,
            data: ev.data,
            retry: ev.retry,
        }));
    }

    onEventName(eventName: E['name'], consumer: (event: SseConsumerMessage<E>) => void) {
        if (!this.consumers.has(eventName)) {
            this.consumers.set(eventName, []);
        }

        this.consumers.get(eventName)?.push(consumer);
    }

    close() {
        this.abortController.abort();
    }
}

export const sseClient = new SseClient();
