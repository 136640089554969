<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-md mb-8">
    <Link
      :to="{ name: tendersRoute.name }"
      :color-scheme="LinkColorScheme.BLANK"
      class="mb-4"
    >
      <TrLogo class="text-primary-600 mx-auto h-16 w-auto" />
    </Link>
  </div>

  <TrBaseGuestWrapper>
    <TrForm
      class="space-y-6"
      @validated="register"
    >
      <TrLabeledInput
        v-model="form.firstName"
        name="firstName"
        :rules="[required()]"
        :label="$t('auth.register.firstName')"
      />

      <TrLabeledInput
        v-model="form.lastName"
        name="lastName"
        :rules="[required()]"
        :label="$t('auth.register.lastName')"
      />

      <TrLabeledInput
        v-model="form.email"
        name="email"
        autocomplete="email"
        :rules="[required()]"
        :label="$t('auth.register.email')"
        type="email"
      />

      <TrLabeledInput
        v-model="form.phoneNumber"
        name="phone-number"
        :rules="[required()]"
        :label="$t('auth.register.phoneNumber')"
      />

      <TrLabeledInput
        v-model="form.password"
        name="password"
        autocomplete="new-password"
        :rules="[required(), minLength(8)]"
        :label="$t('auth.register.password')"
        type="password"
      />

      <TrLabeledInput
        v-model="form.passwordConfirm"
        name="password-verify"
        autocomplete="new-password"
        :rules="[required(), minLength(8)]"
        :label="$t('auth.register.confirmPassword')"
        type="password"
        :errors="errors.passwordConfirm"
        @input="errors.passwordConfirm = []"
      />

      <TrLabeledInput
        v-model="form.companyName"
        name="companyName"
        :rules="[required()]"
        :label="$t('auth.register.company')"
      />

      <tr-button
        type="submit"
        :loading="loading"
        class="w-full"
      >
        {{ $t('auth.register.submit') }}
      </tr-button>

      <Divider />

      <TrButton
        :color-scheme="ButtonColorScheme.SECONDARY"
        class="w-full"
        is-link
        :to="{name: loginRoute.name}"
      >
        {{ $t('auth.register.toLogin') }}
      </TrButton>

      <Divider />

      <p class="text-gray-800">
        Noch Fragen?
      </p>

      <TrButton
        class="w-full"
        is-link
        to="mailto:support@transport-x.de"
        :color-scheme="ButtonColorScheme.TERTIARY"
      >
        Schreiben Sie uns!
      </TrButton>
    </TrForm>
  </TrBaseGuestWrapper>
</template>

<script setup lang="ts">
import TrLabeledInput from '@app/forms/LabeledInput.vue';
import TrButton from '../support/TrButton.vue';
import {reactive} from 'vue';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import auth from './Auth';
import {authService} from '@app/auth/AuthService';
import TrBaseGuestWrapper from '@app/shell/BaseGuestWrapper.vue';
import {makeToast} from '@app/overlays/toast/ToastManager';
import {ValidationErrorCode} from '@app/http/ValidationErrorCode';
import TrLogo from '@app/support/Logo.vue';
import TrForm from '@app/forms/TrForm.vue';
import {required} from '@app/forms/rules/required';
import {minLength} from '@app/forms/rules/minLength';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import {loginRoute} from '@app/auth/routes';
import Divider from '@app/support/Divider.vue';
import {tendersRoute} from '@app/tender/routes';
import {LinkColorScheme} from '@app/navigation/LinkColorScheme';
import Link from '@app/navigation/Link.vue';
import {urlParamRef} from '@app/routing/urlParamRef';
import {useAsyncAction} from '@app/http/useAsyncAction';
import {HttpErrorHandler} from '@app/http/HttpErrorHandler';


const {t} = useI18n();

const form = reactive({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  companyName: '',
  phoneNumber: '',
});

const errors = reactive({
  passwordConfirm: [] as string[],
});

const from = urlParamRef('from');
const router = useRouter();

const companyAlreadyExistsConsumer = () => makeToast({
  title: t('generic.error'),
  body: t('auth.register.companyAlreadyExists'),
  testingIdentifier: 'register-error-company-already-exists',
  mode: ToastMode.ERROR,
});

function errorHandlerConfigurator(errorHandler: HttpErrorHandler) {
  return errorHandler.onValidationError(ValidationErrorCode.COMPANY_ALREADY_EXIST, companyAlreadyExistsConsumer);
}

const {execute: register, isFetching: loading} = useAsyncAction(async function () {
  if (form.password !== form.passwordConfirm) {
    errors.passwordConfirm = [t('auth.register.passwordMisMatch')];
    return;
  }

  const {data} = await authService.register(form);
  auth.setTokenPair(data.authToken, data.refreshToken);
  await router.push(from.value ?? {path: '/', query: {mailToVerify: form.email}});
}, errorHandlerConfigurator);
</script>
