import {computed, Ref, ref} from 'vue';
import {HttpErrorHandler} from '@app/http/HttpErrorHandler';

/**
 * Wrap common functionality of performing an async action
 * Exposes loading state and a function to execute action
 *
 * @param fn function to execute, most probably an HTTP Call
 * @param errorHandlerConfigurator configure HttpErrorHandler used for any error thrown inside of given fn, standard handler will already have genericErrorHandler
 *
 * Prefer useQuery for querying a pages data
 *
 * @example
 * // simple
 * const {fetching, execute: executeRequest } = useHttpRequest(() => httpClient.post('ROUTE', payload))
 *
 * @example
 * // custom error handler
 * const {fetching, execute: executeRequest } = useHttpRequest(() => httpClient.post('ROUTE', payload), (errorHandler) => errorHandler.onValidationError(ValidationErrorCode.TOKEN_EXPIRED, () => {}))
 */
export const useAsyncAction = <T, P extends Array<unknown>>(fn: (...args: P) => Promise<T>, errorHandlerConfigurator?: (errorHandler: HttpErrorHandler) => HttpErrorHandler) => {
    const hasFetched = ref(false);
    const isFetching = ref(false);
    const data: Ref<Awaited<T>|undefined> = ref();
    const isError = ref(false);

    function handleError(e: unknown) {
        const errorHandler = HttpErrorHandler.for(e);

        const configuredErrorHandler = errorHandlerConfigurator?.(errorHandler) ?? errorHandler;

        configuredErrorHandler.execute();
    }

    async function execute(...args: P) {
        isFetching.value = true;

        try {
            data.value = await fn(...args);
            isError.value = false;
        } catch (e) {
            isError.value = true;
            handleError(e);
        } finally {
            hasFetched.value = true;
            isFetching.value = false;
        }

        return data.value;
    }

    /** if we are currently fetching for the first time */
    const isPending = computed(() => !hasFetched.value && isFetching.value);
    /** if we are currently fetching and have already fetched at least once */
    const isReFetching = computed(() => hasFetched.value && isFetching.value);

    return {
        data,
        isReFetching,
        isFetching,
        isPending,
        execute,
        isError,
    };
};
