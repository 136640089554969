import {ResponseContract} from '@app/http/ResponseContract';
import httpClient from '@app/http/HttpClient';

export interface RefreshTokenRequestPayload {
    refreshToken: string,
}

export interface RefreshTokenResponsePayload {
    authToken: string,
    refreshToken: string,
}

class RefreshTokenService {
    refreshToken(refreshTokenRequestPayload: RefreshTokenRequestPayload): Promise<ResponseContract<RefreshTokenResponsePayload>> {
        return httpClient.post('/public/tokens', refreshTokenRequestPayload);
    }
}

export default new RefreshTokenService;
