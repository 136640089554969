import {mapValues, toPairs} from 'lodash';
import {TruckType, truckTypes} from '@app/tender/trucks/TruckType';
import {ref} from 'vue';
import {useI18n} from 'vue-i18n';

export function useTruckTypeSelect() {
    const {t} = useI18n();

    return {
        availableTruckTypes: ref(new Map(
            toPairs(
                mapValues(truckTypes, (type: TruckType) => t(`tender.truckType.${type}`)),
            ),
        )),
    };
}
