<template>
  <label
    :for="forProp"
    class="block text-sm font-medium text-gray-700"
  >
    <slot />
  </label>
</template>

<script lang="ts" setup>
import {toRefs} from 'vue';

interface Props {
  for: string
}

const props = defineProps<Props>();

const {for: forProp} = toRefs(props);
</script>
