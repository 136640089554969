<template>
  <TrBaseGuestWrapper>
    <div class="flex justify-center">
      <div
        v-if="isPending"
        class="flex flex-col items-center"
      >
        <TrCircleLoader />
        <p class="text-gray-600 mt-4">
          {{ $t('truckOffer.deactivateFilterNotification.deactivating') }}
        </p>
      </div>

      <div
        v-else-if="hasError"
        class="flex flex-col items-center"
      >
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
          <ExclamationTriangleIcon
            class="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <p
          class="text-lg font-medium mt-4"
          v-text="$t('truckOffer.deactivateFilterNotification.error')"
        />
        <div class="flex flex-col items-center mt-4 space-y-2">
          <TrButton @click="deactivate">
            {{ $t('truckOffer.deactivateFilterNotification.errorTryAgain') }}
          </TrButton>
          <TrButton
            is-link
            to="mailto:support@transport-x.de?subject=Fehler beim Deaktivieren von Filtern"
            :color-scheme="ButtonColorScheme.TERTIARY"
          >
            {{ $t('truckOffer.deactivateFilterNotification.supportMail') }}
          </TrButton>
        </div>
      </div>

      <div
        v-else
        class="flex flex-col items-center"
      >
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <CheckIcon
            class="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        </div>
        <p
          class="text-lg font-medium my-4"
          v-text="$t('truckOffer.deactivateFilterNotification.deactivated')"
        />
        <TrButton
          is-link
          :to="{ name: hasAuthToken ? dashboardRoute.name : loginRoute.name }"
        >
          {{
            hasAuthToken ? $t('truckOffer.deactivateFilterNotification.toDashboard') : $t('auth.register.toLogin')
          }}
        </TrButton>
      </div>
    </div>
  </TrBaseGuestWrapper>
</template>

<script lang="ts" setup>
import TrBaseGuestWrapper from '@app/shell/BaseGuestWrapper.vue';
import {CheckIcon, ExclamationTriangleIcon} from '@heroicons/vue/24/outline';
import TrCircleLoader from '@app/loader/CircleLoader.vue';
import {computed, ref} from 'vue';
import TrButton from '@app/support/TrButton.vue';
import {hasAuthToken, userId} from '@app/auth/Auth';
import {loginRoute} from '@app/auth/routes';
import {dashboardRoute} from '@app/dashboard/routes';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import {useRoute} from 'vue-router';
import {Uuid} from '@app/uuid/Uuid';
import {makeToast} from '@app/overlays/toast/ToastManager';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import {ValidationErrorCode} from '@app/http/ValidationErrorCode';
import {useMutation} from '@app/http/useMutation';
import {deactivateTruckOfferFilterNotification} from '@app/truckoffer/truckOfferService';

const hasError = ref(false);

const route = useRoute();

const filterId = computed(() => {
  const idParam = route.params.id as string || undefined;

  if (!idParam) {
    makeToast({
      title: 'Fehler',
      body: 'Keine filter id gegeben',
      mode: ToastMode.ERROR,
    });
  }

  return idParam ? Uuid.fromString(idParam) : undefined;
});

function handleFilterNotFoundError() {
  makeToast({
    title: 'Filter nicht gefunden.',
    body: 'Der Filter konnte nicht gefunden werden.',
    mode: ToastMode.ERROR,
  });

  hasError.value = true;
}

const {
  execute: deactivate,
  isPending,
} = useMutation(
    async () => {
      if (!filterId.value) {
        return;
      }

      if (!userId.value) {
        throw new Error('Cannot deactivate filter. User not logged in!');
      }

      await deactivateTruckOfferFilterNotification(userId.value, filterId.value);
    },
    (errorHandler) => errorHandler.onValidationError(ValidationErrorCode.FILTER_NOT_FOUND, handleFilterNotFoundError).onAnyError(() => hasError.value = true),
);

deactivate();
</script>
