<template>
  <Back class="mb-4" />

  <TrForm
    id="create-tender-form"
    @validated="createTender"
  >
    <TrSection
      label-id="tender-section-head"
      with-bottom
    >
      <template #head>
        <SectionHeading id="tender-section-head">
          {{ $t('tender.create.title') }}
        </SectionHeading>

        <p class="max-w-4xl text-sm text-gray-500">
          {{ $t('tender.create.explain') }}
        </p>
      </template>

      <div class="flex flex-col space-y-6">
        <TenderForm
          v-model:participants="participants"
          v-model:title="title"
          v-model:description="description"
          v-model:date-of-execution="dateOfExecution"
          v-model:payment-scheme="paymentScheme"
          v-model:payment-amount-suggestion="paymentAmountSuggestion"
          v-model:location="location"
          v-model:location-option="locationOption"
          v-model:unload-location="unloadLocation"
          v-model:unload-location-option="unloadLocationOption"
          v-model:slots="slots"
        />
      </div>

      <template #bottom>
        <div class="flex justify-end">
          <TrButton
            class="flex-grow lg:flex-none"
            :loading="isLoadingCreate"
            type="submit"
          >
            {{ $t('tender.create.submit') }}
          </TrButton>
        </div>
      </template>
    </TrSection>
  </TrForm>
</template>

<script lang="ts" setup>
import TrButton from '@app/support/TrButton.vue';
import TrForm from '@app/forms/TrForm.vue';
import tenderService from '@app/tender/TenderService';
import {useRouter} from 'vue-router';
import {tenderDetailsRoute} from '@app/tender/routes';
import TenderForm from '@app/tender/create/TenderForm.vue';
import {useTenderForm} from '@app/tender/create/useTenderForm';
import Back from '@app/routing/Back.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import UuidFactory from '@app/uuid/UuidFactory';
import {tenderRoles} from '@app/tender/TenderRole';
import {truckTypes} from '@app/tender/trucks/TruckType';
import {truckBedTypes} from '@app/tender/trucks/TruckBedType';
import TrSection from '@app/support/TrSection.vue';
import SectionHeading from '@app/support/SectionHeading.vue';

const router = useRouter();

const {
    prepareCreateTenderPayload,
    participants,
    title,
    description,
    dateOfExecution,
    paymentScheme,
    paymentAmountSuggestion,
    locationOption,
    unloadLocationOption,
    location,
    unloadLocation,
    slots,
} = useTenderForm();

participants.value = {
    [UuidFactory.v4().toString()]: {
        role: tenderRoles.CONSTRUCTION_MANAGER,
    },
};
slots.value = {
    [UuidFactory.v4().toString()]: {
        truckFeatures: [],
        timeFrom: '08:00',
        timeTo: '17:00',
        truckTypes: [truckTypes.AXLES_3],
        truckBedTypes: [truckBedTypes.TROUGH],
        isTaken: false,
    },
};

const {isFetching: isLoadingCreate, execute: createTender} = useAsyncAction(async function () {
    const createTenderPayload = prepareCreateTenderPayload();

    const {data} = await tenderService.create(createTenderPayload);

    await router.push({name: tenderDetailsRoute.name, params: {id: data.toString()}});
});
</script>
