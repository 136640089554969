import {computed, inject, onMounted, onUnmounted, Ref, ref} from 'vue';
import FormContext from '@app/forms/FormContext';
import Rule from '@app/forms/rules/Rule';
import UuidFactory from '@app/uuid/UuidFactory';
import {FormContextValidator} from '@app/forms/FormContextContract';
import {Err, Ok} from '@app/result/Result';

export function useErrorHandling<T>(
    rules: Ref<Rule<T>[]>,
    modelValue: Ref<T>,
    comp: string,
    externalErrors: Ref<string[]>,
) {
    const formContext = inject<FormContext | null>('formContext', null);

    const internalErrors = ref<string[]>([]);
    const resetInternalErrors = () => internalErrors.value = [];

    const combinedErrors = computed(() => [
        ...externalErrors.value,
        ...internalErrors.value,
    ]);
    const hasErrors = computed(() => !!combinedErrors.value.length);

    if (!formContext) {
        return {internalErrors, combinedErrors, hasErrors, resetInternalErrors};
    }

    const validator: FormContextValidator = async () => {
        const result: string[] = [];

        for (const rule of rules.value) {
            const {isError, value} = rule(modelValue.value);

            if (isError) {
                console.error(comp, modelValue.value, value, rule);
                result.push(value);
            }
        }

        internalErrors.value = result;

        if (result.length) {
            console.error(comp, modelValue.value);
            return Err('Validation failed');
        }

        return Ok.EMPTY;
    };

    const validatorId = UuidFactory.v4();

    onMounted(() => {
        formContext.registerValidator(validatorId, validator);
    });

    onUnmounted(() => {
        formContext.removeValidator(validatorId);
    });

    return {internalErrors, combinedErrors, hasErrors, resetInternalErrors};
}
