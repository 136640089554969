import {computed, Ref} from 'vue';
import {useUrlSearchParams} from '@app/navigation/useUrlSearchParams';

export function urlParamRef(name: string): Ref<string|undefined> {
    const urlParams = useUrlSearchParams({});

    function get() {
        const value = urlParams.value[name];
        if (Array.isArray(value)) {
            throw 'Array URL params not supported';
        }
        return value;
    }

    function set(newValue: string) {
        if (newValue === undefined || newValue === null) {
            const {[name]: _, ...newUrlParams} = urlParams.value;
            urlParams.value = newUrlParams;
            return;
        }

        urlParams.value = {
            ...urlParams.value,
            [name]: newValue,
        };
    }

    return computed<string>({
        get,
        set,
    });
}
