import {CompanyUser, MinimalCompanyUser} from './CompanyUser';
import httpClient from '../http/HttpClient';
import {ResponseContract} from '@app/http/ResponseContract';
import {RequestConfigContract} from '@app/http/RequestConfigContract';
import {Uuid} from '@app/uuid/Uuid';
import {UuidString} from '@app/uuid/UuidString';
import {Permission} from '@app/auth/permissions/Permission';
import {Company} from '@app/company/Company';


export interface CompanyResponsePayload {
    companyId: UuidString,
    name: string
    addresses: CompanyResponsePayloadAddress[]
}

interface CompanyResponsePayloadAddress {
    id: UuidString,
    addressType: AddressType
    name: string,
    additionalInfo: string,
    zipCode: string,
    city: string,
    street: string,
    houseNumber: string
}

export enum AddressType {
    BILLING = 'BILLING'
}

interface CompanyUpdateParam extends Omit<Company, 'billingAddress'> {
    billingAddress: Omit<CompanyResponsePayloadAddress, 'id' | 'addressType'>
}

interface CompanyUpdateRequestPayload extends Omit<CompanyResponsePayload, 'addresses'> {
    addresses: Omit<CompanyResponsePayloadAddress, 'id'>[]
}

class CompanyService {
    async getAllCompanyUsers(companyId: Uuid, fullNameFilter?: string, includeInactive = false): Promise<ResponseContract<MinimalCompanyUser[]>> {
        const requestConfig: AllCompanyUsersRequestConfig = {
            params: {
                search: fullNameFilter,
            },
        };
        if (includeInactive) {
            requestConfig.params['include-inactive'] = 'true';
        }
        const usersResponse: ResponseContract<AllCompanyUsersResponsePayload> = await httpClient.get(`/secure/companies/${companyId}/users/`, requestConfig);

        const users: MinimalCompanyUser[] = usersResponse.data.users.map((user) => ({
            id: Uuid.fromString(user.id),
            firstName: user.firstName,
            lastName: user.lastName,
            permissions: user.permissions,
            email: user.email,
            active: user.active,
            companyCreator: user.companyCreator,
        }));

        return {
            ...usersResponse,
            data: users,
        };
    }

    inviteUser(companyId: Uuid, payload: InviteUserRequestPayload): Promise<ResponseContract<InviteUserResponsePayload>> {
        return httpClient.post(`/secure/companies/${companyId}/users/`, payload);
    }

    async getUserDetails(userId: Uuid): Promise<ResponseContract<CompanyUser>> {
        const userDetailsResponse = await httpClient.get<CompanyUserDetailsResponsePayload>(`/secure/users/${userId.toString()}`);

        return {
            ...userDetailsResponse,
            data: {
                id: userId,
                firstName: userDetailsResponse.data.firstName,
                lastName: userDetailsResponse.data.lastName,
                email: userDetailsResponse.data.email,
                permissions: userDetailsResponse.data.permissions,
                phoneNumber: userDetailsResponse.data.phoneNumber,
                active: userDetailsResponse.data.active,
            },
        };
    }

    async updatePermissions(userId: Uuid, companyId: Uuid, permissions: Permission[]): Promise<ResponseContract<Record<string, never>>> {
        const payload: UpdatePermissionsRequestPayload = {
            permissions,
        };
        return await httpClient.post(`/secure/companies/${companyId}/users/${userId.toString()}/permissions`, payload);
    }

    async updateUserDetails(userId: Uuid, payload: UpdateUserRequestPayload): Promise<ResponseContract<Record<string, never>>> {
        return await httpClient.post(`/secure/users/${userId.toString()}`, payload);
    }

    async deactivateUser(userId: Uuid, companyId: Uuid) {
        const payload: UserActiveRequestPayload = {
            active: false,
        };

        return await httpClient.post(`/secure/companies/${companyId}/users/${userId.toString()}/active`, payload);
    }

    async activateUser(userId: Uuid, companyId: Uuid) {
        const payload: UserActiveRequestPayload = {
            active: true,
        };
        return await httpClient.post(`/secure/companies/${companyId}/users/${userId.toString()}/active`, payload);
    }

    async fetchCompany(companyId: Uuid): Promise<Company> {
        const {data: { addresses, ...data }} = await httpClient.get<CompanyResponsePayload>(`/secure/companies/${companyId.toString()}/`);

        const billingAddress = addresses.find((address) => address.addressType === AddressType.BILLING);

        return {
            ...data,
            id: Uuid.fromString(data.companyId),
            billingAddress: billingAddress ? {
                ...billingAddress,
                id: Uuid.fromString(billingAddress.id),
            } : undefined,
        };
    }

    async updateCompany(company: CompanyUpdateParam): Promise<void> {
        const billingAddress = company.billingAddress;

        const payload: CompanyUpdateRequestPayload = {
            companyId: company.id.toString(),
            name: company.name,
            addresses: [{
                addressType: AddressType.BILLING,
                name: billingAddress.name,
                additionalInfo: billingAddress.additionalInfo,
                city: billingAddress.city,
                zipCode: billingAddress.zipCode,
                street: billingAddress.street,
                houseNumber: billingAddress.houseNumber,
            }],
        };

        await httpClient.put(`/secure/companies/${company.id.toString()}/`, payload);
    }
}

export default new CompanyService();

interface AllCompanyUsersRequestConfig extends RequestConfigContract{
    params: {
        search?: string,
        'include-inactive'?: string
    }
}

export interface UpdatePermissionsRequestPayload {
    permissions: Permission[]
}

export interface UpdateUserRequestPayload {
    firstName: string,
    lastName: string,
    phoneNumber: string
}

export interface CompanyUsersResponseItem {
    id: UuidString;
    firstName: string;
    lastName: string;
    permissions: Permission[];
    email: string;
    active: boolean
    companyCreator: boolean
}

export interface AllCompanyUsersResponsePayload {
    users: Array<CompanyUsersResponseItem>
}

interface InviteUserRequestPayload {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
}

interface InviteUserResponsePayload {
    /** userId of newly created user */
    userId: string,
}

export interface CompanyUserDetailsResponsePayload {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    companyName: string,
    permissions: Permission[],
    active: boolean
}

export interface UserActiveRequestPayload {
    active: boolean
}
