import {Uuid} from '@app/uuid/Uuid';
import httpClient from '@app/http/HttpClient';

class TenderCancelService {
    cancel(tenderId: Uuid) {
        return httpClient.delete(`/secure/tenders/${tenderId.toString()}`);
    }
}

export const tenderCancelService = new TenderCancelService();
