<template>
  <NoData v-if="!loading && bills && !bills.length">
    {{ $t('billing.bills.none') }}
  </NoData>

  <div
    v-if="!loading && bills && bills.length"
    class="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
  >
    <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
          >
            {{ $t('billing.bills.billingPeriod') }}
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {{ $t('billing.bills.value') }}
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {{ $t('billing.bills.status') }}
          </th>
          <th
            scope="col"
            class="relative py-3.5 pl-3 pr-4 sm:pr-6"
          >
            <span class="sr-only">{{ $t('billing.bills.toDetails') }}</span>
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <BillListItem
          v-for="bill in bills"
          :key="bill.id.toString()"
          :bill="bill"
        />
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import {billingService} from '@app/billing/BillingService';
import {ref} from 'vue';
import {MinimalBill} from '@app/billing/MinimalBill';
import BillListItem from '@app/billing/BillListItem.vue';
import NoData from '@app/support/NoData.vue';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';

const bills = ref<MinimalBill[]>();

const loading = ref(false);
useAppShellBarLoader(loading);

const getAll = async () => {
  loading.value = true;

  try {
    const { data } = await billingService.fetchAll();
    bills.value = data;
  } finally {
    loading.value = false;
  }
};

getAll();
</script>
