import {googleMapsMapProvider} from '@app/location/GoogleMapsMapProvider';

const mapProviderEnv: MapProvider | undefined = import.meta.env.VITE_APP_MAP_PROVIDER;

export enum MapProvider {
    GOOGLE = 'GOOGLE'
}

export const mapProvider = () => {
    if (mapProviderEnv === MapProvider.GOOGLE) {
        return googleMapsMapProvider;
    }
    throw Error('Unknown map provider');
};