<template>
  <component
    :is="showUserShell ? TrAppShell : TrBlankShell"
  >
    <router-view />
  </component>

  <ToastCollection />
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import TrAppShell from './shell/AppShell.vue';
import TrBlankShell from './shell/BlankShell.vue';
import {useRoute, useRouter} from 'vue-router';
import {LogoutEvent} from './auth/LogoutEvent';
import eventBus from './eventsbus/EventBus';
import {MissingTokenEvent} from './auth/MissingTokenEvent';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import ToastCollection from '@app/overlays/toast/ToastCollection.vue';
import {makeToast} from '@app/overlays/toast/ToastManager';
import {useI18n} from 'vue-i18n';
import {UncaughtErrorEvent} from '@app/errors/UncaughtErrorEvent';
import {useGenericErrorToast} from '@app/overlays/toast/useGenericErrorToast';
import {SessionExpiredErrorEvent} from '@app/auth/SessionExpiredErrorEvent';
import {NetworkErrorEvent} from '@app/http/NetworkErrorEvent';

const {t} = useI18n();

const router = useRouter();
const route = useRoute();

// show user shell if route doesn't want blank shell
const showUserShell = computed(() => !route.meta['blank']);

eventBus.on(LogoutEvent.eventName, async () => {
  await router.push('/login');
});

eventBus.on(MissingTokenEvent.eventName, () => {
  makeToast({
    title: t('generic.error'),
    body: t('auth.missingToken'),
    mode: ToastMode.ERROR,
  });
});

eventBus.on(SessionExpiredErrorEvent.eventName, () => {
  makeToast({
    title: t('auth.sessionExpired'),
    body: t('auth.plsLoginAgain'),
    mode: ToastMode.INFO,
    id: 'session-expired',
  });
});

eventBus.on(NetworkErrorEvent.eventName, () => {
  makeToast({
    title: t('generic.networkError'),
    body: t('generic.tryAgain'),
    mode: ToastMode.ERROR,
    id: 'network-error',
  });
});

const {showGenericErrorToast} = useGenericErrorToast();
eventBus.on(UncaughtErrorEvent.eventName, () => showGenericErrorToast());
</script>
