import {BillingServiceContract} from '@app/billing/BillingServiceContract';
import {ResponseContract} from '@app/http/ResponseContract';
import {MinimalBill} from '@app/billing/MinimalBill';
import {BillsResponseItem, BillsResponsePayload} from '@app/billing/BillsResponsePayload';
import {Uuid} from '@app/uuid/Uuid';
import httpClient from '@app/http/HttpClient';
import {YearMonth} from '@app/time/YearMonth';
import {Currency} from '@app/money/Currency';
import {Money} from '@app/money/Money';
import {Bill} from '@app/billing/Bill';
import {BillDetailsResponsePayload} from '@app/billing/BillDetailsResponsePayload';
import {BillItem} from '@app/billing/BillItem';
import {DateTime} from '@app/time/DateTime';

class BillingService implements BillingServiceContract{
    async fetchAll(): Promise<ResponseContract<MinimalBill[]>> {
        const response: ResponseContract<BillsResponsePayload> = await httpClient.get('/secure/bills/');

        const bills = response.data.bills.map(this.mapBillsResponseItemToMinimalBill);

        return {
            ...response,
            data: bills,
        };
    }

    private mapBillsResponseItemToMinimalBill(item: BillsResponseItem): MinimalBill {
        return {
            id: Uuid.fromString(item.id),
            period: YearMonth.parseIsoUTC(item.billingMonth),
            value: Money.fromNumber(item.value, Currency.EUR),
            status: item.status,
        };
    }

    async fetchDetails(id: Uuid): Promise<ResponseContract<Bill>> {
        const response: ResponseContract<BillDetailsResponsePayload> = await httpClient.get(`/secure/bills/${id.toString()}`);
        const data = response.data;

        return {
            ...response,
            data: {
                id: Uuid.fromString(data.id),
                period: YearMonth.parseIsoUTC(data.billingMonth),
                value: Money.fromNumber(data.value, Currency.EUR),
                items: this.mapBillItems(data),
                status: data.status,
                billedAt: data.billedAt ? DateTime.parseIsoUTC(data.billedAt) : undefined,
            },
        };
    }

    private mapBillItems(data: BillDetailsResponsePayload) {
        return data.billItems.map((responseBillItem): BillItem => ({
            id: Uuid.fromString(responseBillItem.id),
            value: Money.fromNumber(responseBillItem.value, Currency.EUR),
            type: responseBillItem.billItemType,
            originType: responseBillItem.originType,
            originId: Uuid.fromString(responseBillItem.originId),
            title: responseBillItem.title,
        }));
    }
}

export const billingService = new BillingService as BillingServiceContract;
