<template>
  <Link
    :to="`/tenders/${id}`"
    class="block hover:bg-gray-50 bg-white rounded"
  >
    <div class="px-4 py-4 sm:px-6">
      <div class="flex items-center justify-between">
        <p class="text-sm font-medium text-primary-600 truncate">
          {{ title }}
        </p>
        <div
          class="ml-2 flex-shrink-0 flex"
          data-cy="tender-list-items-status"
          :data-status="status"
        >
          <Badge :type="badgeType">
            {{ translatedStatus }}
          </Badge>
        </div>
      </div>
      <div class="mt-2 sm:flex sm:space-x-4">
        <div class="mt-2 sm:flex-none flex items-center text-sm text-gray-500 sm:mt-0">
          <TruckIcon
            class="flex-none mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <p>
            {{ trucksLeft }} {{ $t('tender.trucks.leftOf') }} {{ trucksRequired }} {{ $t('tender.trucks.left') }}
          </p>
        </div>
        <div class="mt-2 flex sm:flex-1 items-center text-sm text-gray-500 sm:mt-0 min-w-0">
          <MapPinIcon
            class="flex-none mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span
            class="truncate"
            :title="closestLocationDisplayName"
          >{{ closestLocationDisplayName }}</span>&nbsp;
          <span
            v-if="closestDistance.asNumber()"
            class="flex-none"
          >({{
            closestDistance.asNumber()
          }}km {{ $t('tender.distance.away') }})</span>
        </div>
        <div class="mt-2 flex sm:flex-none items-center text-sm text-gray-500 sm:mt-0">
          <CalendarIcon
            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <p>
            {{ $t('tender.timing.timeTo') }}
            {{ ' ' }}
            <time :datetime="dateOfExecution.toISO()">{{ dateOfExecution.toHuman() }}</time>
          </p>
        </div>
      </div>
    </div>
  </Link>
</template>

<script lang="ts" setup>
import {CalendarIcon, MapPinIcon, TruckIcon} from '@heroicons/vue/20/solid';
import Time from '@app/time/Time';
import Calendar from '@app/time/Calendar';
import {computed, toRefs} from 'vue';
import Link from '@app/navigation/Link.vue';
import Kilometers from '@app/location/distance/Kilometers';
import {Uuid} from '@app/uuid/Uuid';
import {TenderStatus} from '@app/tender/TenderStatus';
import {useI18n} from 'vue-i18n';
import Badge from '@app/support/badge/Badge.vue';
import {BadgeType} from '@app/support/badge/BadgeType';

const props = defineProps<{
  id: Uuid,
  title: string,
  trucksLeft: number,
  trucksRequired: number,
  dateOfExecution: Calendar,
  timeFrom: Time,
  timeTo: Time,
  locationDisplayName: string,
  unloadLocationDisplayName: string,
  distance: Kilometers,
  unloadDistance: Kilometers,
  status: TenderStatus
}>();

const {
  status,
  distance,
  unloadDistance,
  locationDisplayName,
  unloadLocationDisplayName,
} = toRefs(props);

const {t} = useI18n();

const translatedStatus = computed(() => {
  if (status.value === TenderStatus.OPEN) {
    return t('tender.open');
  }
  if (status.value === TenderStatus.CANCELED) {
    return t('tender.canceled');
  }
  if (status.value === TenderStatus.DONE) {
    return t('tender.done');
  }
  if (status.value === TenderStatus.FULL) {
    return t('tender.full');
  }
  return '';
});

const badgeType = computed(() => ({
  [TenderStatus.OPEN]: BadgeType.SUCCESS,
  [TenderStatus.DONE]: BadgeType.NEUTRAL,
  [TenderStatus.FULL]: BadgeType.WARNING,
  [TenderStatus.CANCELED]: BadgeType.WARNING,
})[status.value]);

const closestDistance = computed<Kilometers>(() => distance.value.compareTo(unloadDistance.value) === -1 ? distance.value : unloadDistance.value);
const closestLocationDisplayName = computed<string>(() => distance.value.compareTo(unloadDistance.value) === -1 ? locationDisplayName.value : unloadLocationDisplayName.value);
</script>
