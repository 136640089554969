import {computed, ref} from 'vue';
import type {Permission} from '@app/auth/permissions/Permission';
import {permissions} from '@app/auth/permissions/permissions';

export function useUserForm() {
    const grantedPermissions = ref<Permission[]>();

    return {
        grantedPermissions: grantedPermissions,
        hydratedPermissions: computed(() =>
            Object.values(permissions)
                // SYSTEM_READ_REGISTERED_COMPANIES is an internal permission which should not be seen on the UI
                .filter((permission) => permission !== permissions.SYSTEM_READ_REGISTERED_COMPANIES)
                .map(permission => ({
                    id: permission,
                    isGranted: grantedPermissions.value?.includes(permission),
                })),
        ),
    };
}
