import {userId} from '@app/auth/Auth';
import {
    fetchActiveTenderFilterFromBackend,
    updateActiveTenderFilterInBackend,
} from '@app/tender/filter/TenderFilterStorage';
import {NotifiableTenderSearchParams} from '@app/tender/search/TenderSearchParams';

export async function fetchActiveTenderFilter(): Promise<NotifiableTenderSearchParams> {
    if (!userId.value) {
        throw 'Cannot fetch filter of unauthenticated user!';
    }

    return fetchActiveTenderFilterFromBackend(userId.value);
}

export async function updateActiveTenderFilter(searchParams: NotifiableTenderSearchParams): Promise<void> {
    if (!userId.value) {
        throw 'Cannot update filter of unauthenticated user!';
    }

    return updateActiveTenderFilterInBackend(userId.value, searchParams);
}
