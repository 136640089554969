<template>
  <StatusModal
    :title="$t('truckOffer.cancelModal.title')"
    :show="show"
    :mode="ModalMode.WARNING"
    @hide="closeModal"
  >
    {{ $t('truckOffer.cancelModal.message') }}
    <template #actions>
      <div class="mb-4 sm:mb-0">
        <TrButton
          class="w-full"
          :loading="loading"
          @click="cancelTruckOffer"
        >
          {{ $t('generic.confirm') }}
        </TrButton>
      </div>
      <div class="sm:mr-4">
        <TrButton
          :color-scheme="ButtonColorScheme.TERTIARY"
          class="w-full"
          @click="closeModal"
        >
          {{ $t('generic.cancel') }}
        </TrButton>
      </div>
    </template>
  </StatusModal>
</template>

<script lang="ts" setup>
import StatusModal from '@app/overlays/modals/StatusModal.vue';
import TrButton from '@app/support/TrButton.vue';
import {ModalMode} from '@app/overlays/modals/ModalMode';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';

interface Props {
  show: boolean
  loading: boolean
}

interface Events {
  (e: 'hide'): void;
  (e: 'cancel'): void;
}

defineProps<Props>();

const emit = defineEmits<Events>();

const closeModal = () => {
  emit('hide');
};

const cancelTruckOffer = () => {
  emit('cancel');
};

</script>
