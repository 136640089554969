import Bills from './Bills.vue';
import BillDetails from './BillDetails.vue';
import {RouteRecordRaw} from 'vue-router';

export const billsRoute: RouteRecordRaw = {
    path: '/bills',
    name: 'bills',
    component: Bills,
};

export const billDetailsRoute: RouteRecordRaw = {
    path: '/bills/:id',
    name: 'billDetails',
    component: BillDetails,
};
