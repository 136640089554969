<template>
  <div
    v-if="!loadingInitial && tenderList && tenderList.items && !tenderList.items.length"
    class="pt-6"
  >
    <NoData>
      {{ $t('tender.list.page.empty') }}
    </NoData>
  </div>

  <div v-if="!loadingInitial && tenderList && tenderList.items && tenderList.items.length">
    <h1 class="truncate text-2xl font-bold text-gray-900 mb-6">
      {{ tenderList.name }}
    </h1>

    <TrTenderList
      :tenders="tenderList.items"
    />
  </div>
</template>

<script lang="ts" setup>
import TrTenderList from '@app/tender/TenderList.vue';
import tenderService from '@app/tender/TenderService';
import NoData from '@app/support/NoData.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import {useRoute} from 'vue-router';
import {computed} from 'vue';
import {Uuid} from '@app/uuid/Uuid';
import {ValidationErrorCode} from '@app/http/ValidationErrorCode';
import {makeToast} from '@app/overlays/toast/ToastManager';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';

const route = useRoute();

const tenderListId = computed(() => route.params.id as string);

function handleMissingPrivilegesToast() {
  makeToast({
    title: 'Fehlende Berechtigungen',
    body: 'Sie sind nicht berechtigt diese Ausschreibungen zu sehen.',
    mode: ToastMode.ERROR,
  });
}

const {
  execute: loadTenders,
  isPending: loadingInitial,
  isFetching: loading,
  data: tenderList,
} = useAsyncAction(async () => {
  if (!tenderListId.value) {
    throw 'Tender list ID not set.';
  }

  const {data} = await tenderService.fetchTenderList(Uuid.fromString(tenderListId.value));

  return data;
}, (errorHandler) => errorHandler.onValidationError(ValidationErrorCode.MISSING_PRIVILEGES, () => handleMissingPrivilegesToast()));
useAppShellBarLoader(loading);

loadTenders();
</script>
