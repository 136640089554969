<template>
  <TrLabeledSelect
    :model-value="modelValue"
    :items="availableTruckBedTypes"
    :label="label"
    :rules="rules"
    :multiple="multiple"
    @update:model-value="$emit('update:model-value', $event as typeof modelValue)"
  />
</template>

<script lang="ts" setup>
import {mapValues, toPairs} from 'lodash';
import {useI18n} from 'vue-i18n';
import TrLabeledSelect from '@app/forms/LabeledSelect.vue';
import Rule from '@app/forms/rules/Rule';
import {TruckBedType, truckBedTypes} from '@app/tender/trucks/TruckBedType';

interface Props {
  modelValue: TruckBedType[] | TruckBedType,
  label: string,
  rules?: Rule<unknown>[],
  multiple?: boolean
}

withDefaults(defineProps<Props>(), {
  rules: () => [],
  multiple: false,
});

interface Events {
  (e: 'update:model-value', value: Props['modelValue']): void
}

defineEmits<Events>();

const {t} = useI18n();

const availableTruckBedTypes = new Map(
    toPairs(
        mapValues(truckBedTypes, (type: TruckBedType) => t(`tender.truckBedType.${type}`)),
    ),
);
</script>
