import {DateTime} from '@app/time/DateTime';
import {Zone} from 'luxon';

class Calendar {
    private readonly dateTime: DateTime;

    get day(): number {
        return this.dateTime.day;
    }

    get month(): number {
        return this.dateTime.month;
    }

    get year(): number {
        return this.dateTime.year;
    }

    public get zone(): Zone {
        return this.dateTime.zone;
    }

    private constructor(dateTime: DateTime) {
        this.dateTime = dateTime;
    }

    static parseIsoUTC(isoString: string) {
        return new Calendar(DateTime.parseIsoUTC(isoString));
    }

    static fromDateTime(dateTime: DateTime) {
        return new Calendar(dateTime);
    }

    toISO() {
        return this.dateTime.toFormat('yyyy-MM-dd');
    }

    public toHuman(): string {
        return this.dateTime.toHumanDate();
    }

    addDays(days: number) {
        return new Calendar(this.dateTime.addDays(days));
    }

    subtractDays(days: number) {
        return new Calendar(this.dateTime.subtractDays(days));
    }

    toJSDate() {
        return this.dateTime.toJSDate();
    }
}

export default Calendar;
