import Rule from '@app/forms/rules/Rule';
import {Err, Ok} from '@app/result/Result';

export const minSize = (min: number): Rule<unknown> =>
    (val: unknown) => {
        if (Array.isArray(val) && val.length >= min) {
            return Ok.EMPTY;
        }

        if (min === 1) {
            return Err('Es muss mindestens ein Wert ausgewählt werden.');
        } else {
            return Err(`Es müssen mindestens ${min} Werte ausgewählt werden.`);
        }
    };
