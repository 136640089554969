import Rule from '@app/forms/rules/Rule';
import {Err, Ok} from '@app/result/Result';

export function required(): Rule<unknown> {
    return (val: unknown) => {
        if (val || val === 0) {
            return Ok(undefined);
        }

        return Err('Das Feld darf nicht leer sein');
    };
}
