<template>
  <div class="flex">
    <Back class="mb-4" />
  </div>

  <TrForm
    id="create-truck-offer-form"
    :validators="[paymentValidator]"
    @validated="executeCreateTruckOffer"
  >
    <TrSection
      label-id="truck-offer-section-head"
      with-bottom
    >
      <template #head>
        <SectionHeading id="truck-offer-section-head">
          {{ $t('truckOffer.create.title') }}
        </SectionHeading>

        <p class="max-w-4xl text-sm text-gray-500">
          {{ $t('truckOffer.create.explain') }}
        </p>
      </template>

      <div class="flex flex-col space-y-6">
        <TruckOfferForm
          v-model:participants="participants"
          v-model:title="title"
          v-model:description="description"
          v-model:date-of-execution="dateOfExecution"
          v-model:location="location"
          v-model:location-option="locationOption"
          v-model:payment-per-hour="paymentPerHour"
          v-model:payment-per-hour-amount="paymentPerHourAmount"
          v-model:payment-per-ton="paymentPerTon"
          v-model:payment-per-ton-amount="paymentPerTonAmount"
          v-model:truck-type="truckType"
          v-model:truck-bed-type="truckBedType"
          v-model:truck-features="truckFeatures"
          v-model:perimeter="perimeter"
          v-model:time-from="timeFrom"
          v-model:time-to="timeTo"
          v-model:payment-invalid="paymentInvalid"
        />
      </div>

      <template #bottom>
        <div class="flex justify-end">
          <TrButton
            class="flex-grow lg:flex-none"
            :loading="isLoadingCreate"
            type="submit"
          >
            {{ $t('truckOffer.create.submit') }}
          </TrButton>
        </div>
      </template>
    </TrSection>
  </TrForm>
</template>

<script lang="ts" setup>
import TrButton from '@app/support/TrButton.vue';
import TrForm from '@app/forms/TrForm.vue';
import {useRouter} from 'vue-router';
import Back from '@app/routing/Back.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import UuidFactory from '@app/uuid/UuidFactory';
import TrSection from '@app/support/TrSection.vue';
import SectionHeading from '@app/support/SectionHeading.vue';
import TruckOfferForm from '@app/truckoffer/TruckOfferForm.vue';
import {useTruckOfferForm} from '@app/truckoffer/useTruckOfferForm';
import {truckOfferRoles} from '@app/truckoffer/TruckOfferRole';
import {createTruckOffer} from '@app/truckoffer/truckOfferService';
import {truckOfferDetailsRoute} from '@app/truckoffer/routes';

const router = useRouter();

const {
  prepareCreateTruckOfferPayload,
  participants,
  title,
  description,
  dateOfExecution,
  locationOption,
  location,
  paymentPerHour,
  paymentPerHourAmount,
  paymentPerTon,
  paymentPerTonAmount,
  truckType,
  truckBedType,
  truckFeatures,
  perimeter,
  timeFrom,
  timeTo,
  paymentInvalid,
  paymentValidator,
} = useTruckOfferForm();

participants.value = {
  [UuidFactory.v4().toString()]: {
    role: truckOfferRoles.DRIVER,
  },
};

const {isFetching: isLoadingCreate, execute: executeCreateTruckOffer} = useAsyncAction(async function () {
  const createTruckOfferPayload = prepareCreateTruckOfferPayload();

  const {isOk, value} = await createTruckOffer(createTruckOfferPayload);

  if (isOk) {
    await router.push({name: truckOfferDetailsRoute.name, params: {id: value.toString()}});
  } else {
    throw value;
  }
});
</script>
