<template>
  <RequiresAuth>
    <SystemNotifications />

    <Statistics class="mb-6" />

    <Activities />
  </RequiresAuth>
</template>

<script lang="ts" setup>
import {useRoute, useRouter} from 'vue-router';
import SystemNotifications from '@app/dashboard/SystemNotifications.vue';
import RequiresAuth from '@app/auth/RequiresAuth.vue';
import Activities from '@app/dashboard/Activities.vue';
import { ToastMode } from '@app/overlays/toast/ToastMode';
import {useI18n} from 'vue-i18n';
import toastManager from '@app/overlays/toast/ToastManager';
import Statistics from '@app/dashboard/Statistics/Statistics.vue';

const route = useRoute();
const router = useRouter();
const {t} = useI18n();

if (route.query?.mailVerified === '1') {
  toastManager.addToast({
    title: t('auth.register.verification.verified.title'),
    body: t('auth.register.verification.verified.body'),
    mode: ToastMode.SUCCESS,
  });
  const queryWithoutMailVerified = {...route.query};
  delete queryWithoutMailVerified.mailVerified;
  router.replace({query: queryWithoutMailVerified});
}
</script>
