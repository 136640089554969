export function naturalLanguageJoin(elements: string[], endJoinText: string) {
    if (elements.length === 0) {
        return '';
    }

    if (elements.length === 1) {
        return elements[0];
    }

    return `${elements.slice(0, -1).join(', ')} ${endJoinText} ${elements.slice(-1)}`;
}
