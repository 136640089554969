<template>
  <TransitionRoot
    as="template"
    :show="show"
  >
    <HDialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="$emit('hide')"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            :class="{
              'pointer-events-none': !clickToClose
            }"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div :class="`inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle ${widthClass}`">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-t-lg">
              <slot />
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg">
              <slot name="actions" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </HDialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { Dialog as HDialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
import {nextTick, toRefs, watchEffect} from 'vue';

interface Props {
    show: boolean,
    title: string,
    widthClass?: string,
    clickToClose: boolean
}

interface Events {
    (e: 'hide'): void,
    (e: 'show'): void,
}

const props = withDefaults(defineProps<Props>(), {
    widthClass: 'max-w-lg w-full',
});

const {
  show,
} = toRefs(props);

watchEffect(() => {
  if (show.value) {
    nextTick(() => emit('show'));
  }
});

const emit = defineEmits<Events>();
</script>
