<template>
  <TrSection
    label-id="tender-page-location"
    :with-padding="false"
  >
    <template #head>
      <h2
        id="tender-page-location"
        class="text-lg leading-6 font-medium text-gray-900"
      >
        Ort
      </h2>
    </template>

    <dl class="px-4 py-5">
      <div class="flow-root">
        <ul
          role="list"
          class="-mb-8"
        >
          <li v-if="hasCurrentLocation">
            <div class="relative pb-8">
              <span
                class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />

              <div class="relative flex space-x-3">
                <div>
                  <span
                    class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-400 text-gray-50"
                  >
                    A
                  </span>
                </div>
                <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p class="text-sm text-gray-500">
                      {{ $t('tender.page.info.currentLocation') }}: <span class="text-gray-900">{{
                        currentLocationName
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li v-if="hasCurrentLocation">
            <div class="relative pb-8">
              <span
                class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />

              <div class="relative flex space-x-3">
                <div>
                  <span
                    class="border-8 border-white h-8 w-8 rounded-full flex items-center justify-center ring-4 ring-white bg-gray-400 text-gray-50"
                  />
                </div>
                <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div v-if="aToBDistance">
                    <p class="text-sm text-gray-500">
                      {{ aToBDistance }} ({{ aToBTime }})
                    </p>
                  </div>

                  <div v-else>
                    <p class="text-sm text-gray-500">
                      {{ $t('tender.page.info.calculatingDistance') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div class="relative pb-8">
              <span
                class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />

              <div class="relative flex space-x-3">
                <div>
                  <span
                    class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-400 text-gray-50"
                  >
                    {{ hasCurrentLocation ? 'B' : 'A' }}
                  </span>
                </div>
                <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p class="text-sm text-gray-500">
                      {{ $t('tender.page.info.location') }}: <span class="text-gray-900">{{
                        tender.location.displayName
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div class="relative pb-8">
              <span
                class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />

              <div class="relative flex space-x-3">
                <div>
                  <span
                    class="border-8 border-white h-8 w-8 rounded-full flex items-center justify-center ring-4 ring-white bg-gray-400 text-gray-50"
                  />
                </div>
                <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div v-if="bToCDistance">
                    <p class="text-sm text-gray-500">
                      {{ bToCDistance }} ({{ bToCTime }})
                    </p>
                  </div>

                  <div v-else>
                    <p class="text-sm text-gray-500">
                      {{ $t('tender.page.info.calculatingDistance') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div class="relative pb-8">
              <div class="relative flex space-x-3">
                <div>
                  <span
                    class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-400 text-gray-50"
                  >
                    {{ hasCurrentLocation ? 'C' : 'B' }}
                  </span>
                </div>
                <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p class="text-sm text-gray-500">
                      {{ $t('tender.page.info.unloadLocation') }}: <span class="text-gray-900">{{ tender.unloadLocation.displayName }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500" />
        <dd class="mt-1 text-sm text-gray-900" />
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-500" />
        <dd class="mt-1 text-sm text-gray-900" />
      </div>
    </dl>

    <div
      ref="mapElement"
      class="h-96 sm:rounded-b-lg overflow-hidden"
    />
  </TrSection>
</template>
<script lang="ts" setup>
import TrSection from '@app/support/TrSection.vue';
import {Tender} from '@app/tender/Tender';
import {useMap} from '@app/location/useMap';
import {computed, ref, watchEffect} from 'vue';

const mapElement = ref(null);

const currentLocationName = ref('');
const aToBDistance = ref('');
const aToBTime = ref('');
const bToCDistance = ref('');
const bToCTime = ref('');

const hasCurrentLocation = computed(() => currentLocationLat && currentLocationLng);

function initMap() {
  const directionsService = new google.maps.DirectionsService();
  const directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: false});

  if (!mapElement.value) {
    throw 'Location map element not found!';
  }

  const map = new google.maps.Map(mapElement.value, {
    mapId: '4f8da506969575b5',
    disableDefaultUI: true,
  });

  directionsDisplay.setMap(map);

  const start = new google.maps.LatLng(tender.location.latitude, tender.location.longitude);
  const end = new google.maps.LatLng(tender.unloadLocation.latitude, tender.unloadLocation.longitude);

  let request;
  if (hasCurrentLocation.value) {
    const currentLocation = new google.maps.LatLng(currentLocationLat as number, currentLocationLng as number);

    request = {
      origin: currentLocation,
      waypoints: [{location: start}],
      destination: end,
      travelMode: 'DRIVING',
    } as google.maps.DirectionsRequest;
  } else {
    request = {
      origin: start,
      destination: end,
      travelMode: 'DRIVING',
    } as google.maps.DirectionsRequest;
  }

  directionsService.route(request, function (response) {
    if (response?.routes) {
      const route = response?.routes[0];
      let bToCLeg;
      let aToBLeg;

      if (route?.legs) {
        if (route.legs.length === 1) {
          bToCLeg = route.legs[0];
        } else if (route.legs.length === 2) {
          aToBLeg = route.legs[0];
          bToCLeg = route.legs[1];
        }
      }

      if (aToBLeg) {
        aToBTime.value = aToBLeg.duration?.text ?? '';
        aToBDistance.value = aToBLeg.distance?.text ?? '';
        currentLocationName.value = aToBLeg.start_address;
      }
      if (bToCLeg) {
        bToCTime.value = bToCLeg.duration?.text ?? '';
        bToCDistance.value = bToCLeg.distance?.text ?? '';
      }
    }

    directionsDisplay.setDirections(response);
  });
}

const {scriptLoading} = useMap();
watchEffect(() => {
  if (!scriptLoading.value) {
    initMap();
  }
});

const {tender, currentLocationLat, currentLocationLng} = defineProps<{
  tender: Tender,
  currentLocationLat?: number,
  currentLocationLng?: number,
}>();

</script>
