import httpClient from '../http/HttpClient';
import {ResponseContract} from '@app/http/ResponseContract';
import {Uuid} from '@app/uuid/Uuid';
import {UuidString} from '@app/uuid/UuidString';

interface RegisterRequest {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    companyName: string,
    phoneNumber: string
}

export interface RegisterResponse {
    userId: string,
    authToken: string,
    refreshToken: string,
}

interface LoginRequest {
    username: string,
    password: string,
}

interface LoginResponse {
    authToken: string,
    refreshToken: string,
}

interface EmailVerificationRequest {
    verificationToken: string,
}

export interface EmailVerificationResponse {
    authToken: string,
    refreshToken: string,
}

interface ChangePasswordRequestPayload {
    newPassword: string,
}

export interface UserDetailsResponsePayload {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    companyName: string
}

export interface UserCompanyResponse {
    id: UuidString;
    name: string;
    logo?: string;
}

export interface RequestPasswordRequestPayload {
    email: string
}

export interface ResetPasswordRequestPayload {
    resetToken: string,
    newPassword: string
}

export interface ResetPasswordResponsePayload {
    authToken: string,
    refreshToken: string
}

class AuthService {
    register(request: RegisterRequest): Promise<ResponseContract<RegisterResponse>> {
        return httpClient.post('/public/registrations', request);
    }

    login(request: LoginRequest): Promise<ResponseContract<LoginResponse>> {
        return httpClient.post('/public/authenticate', request);
    }

    verifyEmail(request: EmailVerificationRequest): Promise<ResponseContract<EmailVerificationResponse>> {
        return httpClient.post('/public/email-verifications/', request);
    }

    changePassword(userId: Uuid, request: ChangePasswordRequestPayload): Promise<ResponseContract<Record<string, never>>> {
        return httpClient.post(`/secure/users/${userId.toString()}/change-password`, request);
    }

    userDetails(userId: Uuid): Promise<ResponseContract<UserDetailsResponsePayload>> {
        return httpClient.get(`/secure/users/${userId.toString()}`);
    }
    async requestPasswordReset(email: string) {
        const request: RequestPasswordRequestPayload = {email};
        return await httpClient.post('/public/request-password-reset', request);
    }

    async resetPassword(resetToken: string, newPassword: string): Promise<ResponseContract<ResetPasswordResponsePayload>> {
        const payload: ResetPasswordRequestPayload = {resetToken, newPassword};
        return await httpClient.post('/public/reset-password', payload);
    }
}

export const authService = new AuthService();
