import {RouteRecordRaw} from 'vue-router';
import Imprint from '@app/legal/Imprint.vue';
import DataPrivacy from '@app/legal/DataPrivacy.vue';

export const imprintRoute: RouteRecordRaw = {
    path: '/imprint',
    component: Imprint,
    name: 'Imprint',
    meta: {
        allowAnonymous: true,
    },
};

export const dataPrivacyRoute: RouteRecordRaw = {
    path: '/data-privacy',
    component: DataPrivacy,
    name: 'DataPrivacy',
    meta: {
        allowAnonymous: true,
    },
};


export const legalRoutes = [
    imprintRoute,
    dataPrivacyRoute,
];
