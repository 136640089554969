<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-md mb-8">
    <Link
      :to="{ name: tendersRoute.name }"
      :color-scheme="LinkColorScheme.BLANK"
      class="mb-4"
    >
      <TrLogo class="text-primary-600 mx-auto h-16 w-auto" />
    </Link>
  </div>

  <TrBaseGuestWrapper>
    <form
      class="space-y-6"
      @submit.prevent="login"
    >
      <TrLabeledInput
        v-model="form.username"
        v-model:errors="usernameErrors"
        name="email"
        autocomplete="email"
        required=""
        :label="$t('auth.register.email')"
        type="email"
      />

      <TrLabeledInput
        v-model="form.password"
        name="password"
        autocomplete="current-password"
        required=""
        :label="$t('auth.login.password')"
        type="password"
        minlength="8"
      />

      <tr-button
        type="submit"
        :loading="loading"
        class="w-full"
      >
        {{ $t('auth.login.submit') }}
      </tr-button>

      <Divider />

      <TrButton
        :color-scheme="ButtonColorScheme.SECONDARY"
        class="w-full"
        is-link
        :to="{name: registerRoute.name, query: from ? { from } : {}}"
      >
        {{ $t('auth.login.toRegister') }}
      </TrButton>

      <TrButton
        :color-scheme="ButtonColorScheme.TERTIARY"
        class="w-full"
        :loading="resetLoading"
        @click="resetPassword"
      >
        {{ $t('auth.login.forgotPassword') }}
      </TrButton>

      <Divider />

      <p class="text-gray-800">
        Noch Fragen?
      </p>

      <TrButton
        class="w-full"
        is-link
        to="mailto:support@transport-x.de"
        :color-scheme="ButtonColorScheme.TERTIARY"
      >
        Schreiben Sie uns!
      </TrButton>
    </form>
  </TrBaseGuestWrapper>
</template>

<script setup lang="ts">
import auth from './Auth';
import TrLabeledInput from '@app/forms/LabeledInput.vue';
import TrButton from '../support/TrButton.vue';
import {nextTick, reactive, ref} from 'vue';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import {authService} from '@app/auth/AuthService';
import TrBaseGuestWrapper from '@app/shell/BaseGuestWrapper.vue';
import {useRouter} from 'vue-router';
import toastManager, {makeToast} from '@app/overlays/toast/ToastManager';
import {useI18n} from 'vue-i18n';
import {HttpErrorHandler} from '@app/http/HttpErrorHandler';
import {ValidationErrorCode} from '@app/http/ValidationErrorCode';
import TrLogo from '@app/support/Logo.vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import Divider from '@app/support/Divider.vue';
import {registerRoute} from '@app/auth/routes';
import {LinkColorScheme} from '@app/navigation/LinkColorScheme';
import Link from '@app/navigation/Link.vue';
import {tendersRoute} from '@app/tender/routes';
import {urlParamRef} from '@app/routing/urlParamRef';


const loading = ref(false);
const {t} = useI18n();

const form = reactive({
    username: '',
    password: '',
});

const from = urlParamRef('from');
const router = useRouter();

if (from.value) {
  makeToast({
    title: 'Bitte loggen Sie sich ein',
    mode: ToastMode.INFO,
    body: 'Loggen Sie sich ein um die Seite zu besuchen.',
  });
}

const badCredentialsConsumer = () => {
    toastManager.addToast({
        title: t('generic.error'),
        body: t('auth.login.badCredentials'),
        mode: ToastMode.ERROR,
        testingIdentifier: 'login-bad-credentials',
    });
};


async function login() {
    loading.value = true;

    try {
        const {data} = await authService.login(form);
        auth.setTokenPair(data.authToken, data.refreshToken);
        await nextTick(() => {
          router.push(from.value ?? '/');
        });
    } catch (error) {
        HttpErrorHandler.for(error)
            .onValidationError(ValidationErrorCode.AUTHENTICATION_BAD_CREDENTIALS, badCredentialsConsumer)
            .execute();
    } finally {
      loading.value = false;
    }
}

const usernameErrors = ref();
const resetLoading = ref(false);
async function resetPassword() {
    if (form.username === '') {
        usernameErrors.value = ['Bitte geben Sie ihre E-Mail an'];
        return;
    }

    resetLoading.value = true;

    try {
        await authService.requestPasswordReset(form.username);

        makeToast({
            title: 'E-Mail versendet',
            body: `Ein Link zum Zurücksetzen des Passworts wurde per E-Mail an ${form.username} geschickt.`,
            mode: ToastMode.SUCCESS,
        });
    } catch (e) {
        HttpErrorHandler.for(e)
            .onValidationError(ValidationErrorCode.USER_NOT_FOUND, () => {
                usernameErrors.value = ['Bitte geben Sie ihre E-Mail an'];
                makeToast({
                    title: 'E-Mail nicht gefunden',
                    body: 'Zu dieser E-Mail konnten wir keinen Benutzer finden.',
                    mode: ToastMode.ERROR,
                });
            })
            .execute();
    }

    resetLoading.value = false;
}
</script>
