<template>
  <div v-if="!fetchingInitial && companyUsers && company">
    <div class="lg:flex lg:items-center lg:justify-between lg:space-x-5">
      <h1 class="truncate text-2xl font-bold text-gray-900">
        {{ company.name }}
      </h1>
      <div class="mt-6 lg:mt-0">
        <TrButton
          :color-scheme="ButtonColorScheme.TERTIARY"
          is-link
          :to="{ name: updateCompanyRoute.name, params: {companyId: company.id.toString()} }"
        >
          Bearbeiten
        </TrButton>
      </div>
    </div>

    <TrSection
      label-id="company-addresses-headline"
    >
      <template #head>
        <SectionHeading id="company-addresses-headline">
          Rechnungsadresse
        </SectionHeading>
      </template>

      <NoData v-if="!company.billingAddress">
        Noch keine Adresse vorhanden
      </NoData>

      <template v-else>
        <p class="text-gray-900">
          {{ company.billingAddress.name }}
        </p>
        <p
          v-if="company.billingAddress.additionalInfo"
          class="text-gray-600"
        >
          {{ company.billingAddress.additionalInfo }}
        </p>
        <p class="text-gray-600">
          {{ company.billingAddress.zipCode }}
          {{ company.billingAddress.city }}
        </p>
        <p class="text-gray-600">
          {{ company.billingAddress.street }}
          {{ company.billingAddress.houseNumber }}
        </p>
      </template>
    </TrSection>

    <TrSection
      label-id="company-users-headline"
      class="relative"
    >
      <template #top>
        <div class="absolute w-full rounded-t-md overflow-hidden">
          <BarLoader v-if="loadingUsers" />
        </div>
      </template>

      <template #head>
        <div class="flex justify-between space-y-6 lg:space-y-0 lg:items-center flex-col lg:flex-row">
          <div>
            <p
              id="company-users-headline"
              class="text-base font-semibold leading-6 text-gray-900"
            >
              {{ $t('company.users.head') }}
            </p>
            <p class="max-w-4xl text-sm text-gray-500">
              {{ $t('company.users.sub') }} <i>{{ company.name }}</i>
            </p>
          </div>

          <div class="flex lg:space-x-6 space-y-4 lg:space-y-0 flex-col lg:flex-row">
            <TrSwitch
              v-model="showInactive"
              @update:model-value="loadCompanyUsers()"
            >
              {{ $t('company.users.showInactive') }}
            </TrSwitch>

            <RequiresPermissions :permissions="requiredInvitePermissions">
              <TrButton
                data-cy="invite-user-button"
                @click="showInviteModal = true"
              >
                <template #prefix>
                  <UserPlusIcon
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </template>
                {{ $t('company.inviteUser') }}
              </TrButton>
            </RequiresPermissions>
          </div>
        </div>
      </template>

      <TrListCompanyUsers
        :users="companyUsers"
      />
    </TrSection>

    <TrInviteUserModal
      :show="showInviteModal"
      @hide="showInviteModal = false"
      @invite-sent="loadCompanyUsers"
    />
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import auth from '../auth/Auth';
import companyService from './CompanyService';
import TrListCompanyUsers from '@app/company/ListCompanyUsers.vue';
import TrButton from '@app/support/TrButton.vue';
import {UserPlusIcon} from '@heroicons/vue/20/solid';
import TrInviteUserModal from '@app/company/InviteUserModal.vue';
import RequiresPermissions from '@app/auth/RequiresPermissions.vue';
import {permissions} from '@app/auth/permissions/permissions';
import TrSection from '@app/support/TrSection.vue';
import TrSwitch from '@app/support/TrSwitch.vue';
import BarLoader from '@app/loader/BarLoader.vue';
import {useLocalStorage} from '@vueuse/core';
import {useAsyncAction} from '@app/http/useAsyncAction';
import SectionHeading from '@app/support/SectionHeading.vue';
import NoData from '@app/support/NoData.vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import {updateCompanyRoute} from '@app/company/routes';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';

const showInviteModal = ref(false);

const showInactive = useLocalStorage<boolean>('show-inactive-users', false);

const {
    execute: loadCompanyUsers,
    data: companyUsers,
    isPending: fetchingUsersInitial,
    isFetching: loadingUsers,
} = useAsyncAction(async () => {
    const {data} = await companyService.getAllCompanyUsers(auth.getCompanyId(), undefined, showInactive.value);
    return data;
});

const {execute: loadCompany, isPending: fetchingCompanyInitial, data: company} = useAsyncAction(async () => {
    return await companyService.fetchCompany(auth.getCompanyId());
});

const fetchingInitial = computed(() => fetchingCompanyInitial.value || fetchingUsersInitial.value);
useAppShellBarLoader(fetchingInitial);

const requiredInvitePermissions = new Set([permissions.INVITE_USER]);

loadCompany();
loadCompanyUsers();
</script>
