import {computed, ref, Ref} from 'vue';

interface UseOffsetPaginationOptions {
    pageSize: Ref<number>
    total: Ref<number>
    currentPage?: Ref<number>
}

export interface OffsetPaginationData {
    currentPageSize: Ref<number>
    pageCount: Ref<number>
    previous: Ref<number|undefined>
    next: Ref<number|undefined>
    currentPage: Ref<number>
    pageStartItemNumber: Ref<number>
    pageEndItemNumber: Ref<number>
    total: Ref<number>
}

export function useOffsetPagination({pageSize, total, currentPage: currentPageProp}: UseOffsetPaginationOptions): OffsetPaginationData {
    const currentPage = currentPageProp ?? ref(1);

    const pageCount = computed(() => Math.max(1, Math.ceil(total.value / pageSize.value)));
    const currentPageSize = computed(() => Math.min(pageSize.value, total.value - ( currentPage.value-1 ) * pageSize.value));

    const previous = computed(() => currentPage.value === 1 ? undefined : currentPage.value - 1);
    const next = computed(() => currentPage.value === pageCount.value ? undefined : currentPage.value + 1);

    const pageStartItemNumber = computed(() => {
        if (total.value === 0) return 0;
        return (currentPage.value - 1) * pageSize.value + 1;
    });
    const pageEndItemNumber = computed(() => {
        if (total.value === 0) return 0;
        return pageStartItemNumber.value + currentPageSize.value - 1;
    });

    return {
        pageCount,
        currentPageSize,
        previous,
        next,
        currentPage,
        pageStartItemNumber,
        pageEndItemNumber,
        total,
    };
}
