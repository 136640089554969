<template>
  <TrLink
    v-if="!isMailTo"
    :to="to"
    :class="[routeMatches ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 my-1 text-base font-medium rounded-md']"
    :color-scheme="LinkColorScheme.BLANK"
  >
    <component
      :is="icon"
      :class="[routeMatches ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"
      aria-hidden="true"
    />
    {{ name }}
  </TrLink>

  <a
    v-else
    :class="[routeMatches ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 my-1 text-base font-medium rounded-md']"
    :href="to as string"
  >
    <component
      :is="icon"
      :class="[routeMatches ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"
      aria-hidden="true"
    />
    {{ name }}
  </a>
</template>

<script lang="ts" setup>
import TrLink from '@app/navigation/Link.vue';
import {useRoute} from 'vue-router';
import {LinkColorScheme} from '@app/navigation/LinkColorScheme';
import {computed, FunctionalComponent, HTMLAttributes, toRefs, VNodeProps} from 'vue';

interface Props {
  icon: FunctionalComponent<HTMLAttributes & VNodeProps>,
  name: string,
  to: {path: string} | string
}

const props = defineProps<Props>();

const {to} = toRefs(props);

const route = useRoute();

const firstRoutePathPart = computed(() => route.path.split('/')[1]);
const firstToPathPart = computed(() => {
  if (typeof to.value === 'string') {
    return to.value.split('/')[1];
  }

  return to.value.path.split('/')[1];
});
const routeMatches = computed(() => firstToPathPart.value === firstRoutePathPart.value);

const isMailTo = computed(() => {
  if (!props.to) {
    return false;
  }

  if (typeof props.to !== 'string') {
    return false;
  }

  return props.to.startsWith('mailto');
});
</script>
