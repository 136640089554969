<template>
  <DefineButtonContent>
    <span class="mr-2">
      <TrSpinner v-if="loading" />
      <template v-else>
        <slot name="prefix" />
      </template>
    </span>

    <slot />

    <span class="ml-2">
      <slot name="postfix" />
    </span>
  </DefineButtonContent>

  <RouterLink
    v-if="isLink && !isMailTo && to"
    v-bind="attrs"
    :class="commonClasses"
    :title="title"
    :to="to"
    :disabled="disabled"
  >
    <ButtonContent />
  </RouterLink>

  <a
    v-else-if="isLink && isMailTo"
    v-bind="attrs"
    :class="commonClasses"
    :title="title"
    :href="to as string"
  >
    <ButtonContent />
  </a>

  <button
    v-else
    v-bind="attrs"
    :class="commonClasses"
    :title="title"
    :disabled="buttonDisabled"
    :type="type"
  >
    <ButtonContent />
  </button>
</template>

<script lang="ts" setup>
import TrSpinner from '@app/support/Spinner.vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import {RouteLocationRaw, RouterLink} from 'vue-router';
import {computed, toRefs, useAttrs} from 'vue';
import {createReusableTemplate} from '@vueuse/core';
import {useButton} from '@app/support/useButton';

const [DefineButtonContent, ButtonContent] = createReusableTemplate();

defineOptions({
  inheritAttrs: false,
});

interface Props {
  colorScheme?: ButtonColorScheme;
  loading?: boolean;
  isLink?: boolean;
  to?: RouteLocationRaw;
  disabled?: boolean;
  disabledHint?: string;
  type?: 'button' | 'submit' | 'reset';
}

const props = withDefaults(defineProps<Props>(), {
  colorScheme: ButtonColorScheme.PRIMARY,
  to: undefined,
  isLink: false,
  loading: false,
  disabledHint: undefined,
  type: 'button',
});

const {
  disabled,
  loading,
  colorScheme,
} = toRefs(props);

const attrs = useAttrs();

const {commonClasses} = useButton(colorScheme, disabled);

const title = computed(() => {
  if (typeof attrs.title === 'string') {
    return attrs.title;
  }

  if (props.disabled) {
    return props.disabledHint;
  }

  return undefined;
});

const isMailTo = computed(() => {
  if (!props.to) {
    return false;
  }

  if (typeof props.to !== 'string') {
    return false;
  }

  return props.to.startsWith('mailto');
});

/**
 * A button should not be clickable when loading, might cause for example creating duplicate entries
 */
const buttonDisabled = computed(() => loading.value || disabled.value);
</script>
