import Big from 'big.js';
import {Currency} from '@app/money/Currency';

export class Money {
    private readonly value: Big;
    private readonly _currency: Currency;

    private constructor(value: Big, currency: Currency) {
        this._currency = currency;
        this.value = value;
    }

    static fromNumber(value: number, currency: Currency) {
        return new Money(Big(value), currency);
    }

    static fromBig(value: Big, currency: Currency) {
        return new Money(value, currency);
    }

    static fromString(value: string, currency: Currency) {
        return new Money(Big(value), currency);
    }

    toNumber() {
        return this.toBig().toNumber();
    }

    abs(): Money {
        return Money.fromBig(this.toBig().abs(), this.currency);
    }

    get currency(): Currency {
        return this._currency;
    }

    toBig(): Big {
        return this.value;
    }
}
