import cloneDeep from 'lodash/cloneDeep';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import {Uuid} from '@app/uuid/Uuid';
import uuidFactory from '@app/uuid/UuidFactory';

export interface SimpleToastDefinition {
    /** id unique per toast for rendering purposes */
    uuid: Uuid,
    /** if set, other toasts with same id will be hidden */
    id?: string,
    title: string,
    body: string,
    mode: ToastMode,
    duration?: number,
    /** data-ct attribute, used for testing */
    testingIdentifier?: string
}

export interface CTAToastDefinition extends SimpleToastDefinition {
    ctaText: string,
    ctaAction: () => void,
}

export type ToastDefinition = SimpleToastDefinition | CTAToastDefinition;

export type ToastCreateDefinition = Omit<SimpleToastDefinition, 'uuid'> | Omit<CTAToastDefinition, 'uuid'>;

type Subscriber = (allDefinitions: ToastDefinition[]) => void;

class ToastManager {
    private _definitions: ToastDefinition[] = [];
    private subscribers: Subscriber[] = [];

    get definitions() {
        return cloneDeep(this._definitions);
    }

    private set definitions(newDefinitions: ToastDefinition[]) {
        this._definitions = newDefinitions;
        this.notifySubscribers();
    }

    addToast(definition: ToastCreateDefinition) {
        if (definition.id) {
            // remove any existing toasts with same id
            this._definitions = this._definitions.filter((existingDefinition) => existingDefinition.id !== definition.id);
        }

        const completeDefinition = {
            ...definition,
            uuid: uuidFactory.v4(),
        };
        this.definitions = [
            ...this._definitions,
            completeDefinition,
        ];

        return completeDefinition;
    }

    private notifySubscribers() {
        this.subscribers.forEach(this.notifySubscriber.bind(this));
    }

    private notifySubscriber(subscriber: Subscriber) {
        subscriber(this._definitions);
    }

    subscribe(subscriber: Subscriber) {
        this.subscribers.push(subscriber);
        this.notifySubscriber(subscriber);
    }

    remove(deleteUuid: Uuid) {
        this.definitions = this._definitions.filter((definition) => definition.uuid.toString() !== deleteUuid.toString());
    }
}

const toastManagerInstance = new ToastManager;

export const makeToast = toastManagerInstance.addToast.bind(toastManagerInstance);

export const removeToast = toastManagerInstance.remove.bind(toastManagerInstance);

export default toastManagerInstance;
