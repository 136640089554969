<template>
  <Back class="mb-4" />

  <template v-if="!isLoading && currentTruckOffer">
    <TrForm
      id="update-truck-offer-form"
      :validators="[paymentValidator]"
      @validated="executeUpdate"
    >
      <TrSection
        label-id="truckoffer-section-head"
        with-bottom
      >
        <template #head>
          <SectionHeading id="truckoffer-section-head">
            {{ $t('truckOffer.update.title') }}
          </SectionHeading>

          <p class="max-w-4xl text-sm text-gray-500">
            {{ $t('truckOffer.update.explain', {title: currentTruckOffer.title}) }}
          </p>
        </template>

        <div class="flex flex-col space-y-6">
          <TruckOfferForm
            v-model:participants="participants"
            v-model:title="title"
            v-model:description="description"
            v-model:date-of-execution="dateOfExecution"
            v-model:location="location"
            v-model:location-option="locationOption"
            v-model:payment-per-hour="paymentPerHour"
            v-model:payment-per-hour-amount="paymentPerHourAmount"
            v-model:payment-per-ton="paymentPerTon"
            v-model:payment-per-ton-amount="paymentPerTonAmount"
            v-model:truck-type="truckType"
            v-model:truck-bed-type="truckBedType"
            v-model:truck-features="truckFeatures"
            v-model:perimeter="perimeter"
            v-model:time-from="timeFrom"
            v-model:time-to="timeTo"
            v-model:payment-invalid="paymentInvalid"
          />
        </div>

        <template #bottom>
          <div class="flex justify-end">
            <TrButton
              class="flex-grow lg:flex-none"
              :loading="isLoadingUpdate"
              type="submit"
            >
              {{ $t('truckOffer.update.submit') }}
            </TrButton>
          </div>
        </template>
      </TrSection>
    </TrForm>
  </template>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue';
import {Uuid} from '@app/uuid/Uuid';
import {useRoute, useRouter} from 'vue-router';
import {useAsyncAction} from '@app/http/useAsyncAction';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import {fetchTruckOffer, updateTruckOffer} from '@app/truckoffer/truckOfferService';
import {TruckOffer} from '@app/truckoffer/TruckOffer';
import Back from '@app/routing/Back.vue';
import TrButton from '@app/support/TrButton.vue';
import SectionHeading from '@app/support/SectionHeading.vue';
import TrForm from '@app/forms/TrForm.vue';
import TrSection from '@app/support/TrSection.vue';
import {useTruckOfferForm} from '@app/truckoffer/useTruckOfferForm';
import {truckOfferDetailsRoute} from '@app/truckoffer/routes';
import TruckOfferForm from '@app/truckoffer/TruckOfferForm.vue';

const route = useRoute();
const router = useRouter();

const currentTruckOffer = ref<TruckOffer>();

const truckOfferId = computed(() => Uuid.fromString(route.params.id as string));

const {isFetching: isLoading, execute: executeFetch} = useAsyncAction(async function () {
  currentTruckOffer.value = await fetchTruckOffer(truckOfferId.value);
});
executeFetch();

useAppShellBarLoader(computed(() => isLoading.value || !currentTruckOffer.value));

const {
  prepareCreateTruckOfferPayload,
  assignRefsFromTruckOffer,
  participants,
  title,
  description,
  dateOfExecution,
  locationOption,
  location,
  paymentPerHour,
  paymentPerHourAmount,
  paymentPerTon,
  paymentPerTonAmount,
  truckType,
  truckBedType,
  truckFeatures,
  perimeter,
  timeFrom,
  timeTo,
  paymentInvalid,
  paymentValidator,
} = useTruckOfferForm();

watchEffect(async () => {
  await assignRefsFromTruckOffer(currentTruckOffer);
});

const {isFetching: isLoadingUpdate, execute: executeUpdate} = useAsyncAction(async function () {
  const createTruckOfferPayload = prepareCreateTruckOfferPayload();

  await updateTruckOffer(
      truckOfferId.value,
      createTruckOfferPayload,
  );

  await router.push({name: truckOfferDetailsRoute.name, params: {id: truckOfferId.value.toString()}});
});
</script>
