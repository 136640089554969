import Rule from '@app/forms/rules/Rule';
import {Ok} from '@app/result/Result';
import {Ref} from 'vue';
import {required} from '@app/forms/rules/required';

export function requiredIf(condition: Ref<boolean>): Rule<unknown> {
    return (val: unknown) => {
        if (!condition.value) {
            return Ok(undefined);
        }

        return required()(val);
    };
}
