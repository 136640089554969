<template>
  <TrBaseGuestWrapper>
    <div class="flex justify-center">
      <div
        v-if="loading"
        class="flex flex-col items-center"
      >
        <TrCircleLoader />
        <p class="text-gray-600 mt-4">
          {{ $t('auth.register.verification.verifying') }}
        </p>
      </div>

      <div
        v-else-if="error"
        class="flex flex-col items-center"
      >
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
          <ExclamationTriangleIcon
            class="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <p
          class="mt-6 text-lg font-medium"
          v-text="$t('auth.verify.tokenError')"
        />
      </div>
    </div>
  </TrBaseGuestWrapper>
</template>

<script lang="ts">
import TrBaseGuestWrapper from '@app/shell/BaseGuestWrapper.vue';
import TrCircleLoader from '@app/loader/CircleLoader.vue';
import {defineComponent, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {authService} from '@app/auth/AuthService';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import auth from '@app/auth/Auth';

export default defineComponent({
  components: {
    TrBaseGuestWrapper,
    TrCircleLoader,
    ExclamationTriangleIcon,
  },

  setup() {
    const loading = ref(false);
    const error = ref(false);

    const route = useRoute();
    const router = useRouter();


    const verify = async () => {
      loading.value = true;
      error.value = false;

      // fake the verification process taking some time to not disturb the user
      const fakeWaitPromise = new Promise((resolve) => {
        setTimeout(resolve, 1500);
      });

      const verifyPromise = authService.verifyEmail({
        verificationToken: route.query?.t as string,
      })
          .catch(() => {
            error.value = true;
          });

      const [, verifyRes] = await Promise.all([
          fakeWaitPromise,
          verifyPromise,
      ]);

      loading.value = false;

      if (verifyRes?.data) {
        auth.setTokenPair(verifyRes.data.authToken, verifyRes.data.refreshToken);
        await router.replace({ path: '/', query: {mailVerified: '1'}});
      }
    };

    verify();

    return {
      loading,
      error,
      verify,
    };
  },
});
</script>
