<template>
  <div class="py-5 flex flex-col justify-between">
    <dt
      class="text-gray-900"
      v-text="label"
    />
    <dd
      class="text-3xl font-semibold text-primary-600 mt-1"
      v-text="value"
    />
  </div>
</template>

<script lang="ts" setup>
import {toRefs} from 'vue';

interface Props {
  label: string,
  value: number,
}

const props = defineProps<Props>();

const {label, value} = toRefs(props);

</script>
