import httpClient from '../http/HttpClient';
import {userId} from '../auth/Auth';
import {ResponseContract} from '@app/http/ResponseContract';

export interface SystemNotificationResponse {
    notifications: SystemNotification[]
}

export interface SystemNotification {
    id: string,
    title: string,
    body: string,
    approvalRequired: boolean,
}

class SystemNotificationService {
    /**
     * @throws {MissingTokenError}
     */
    fetchAll(): Promise<ResponseContract<SystemNotificationResponse>> {
        if (!userId.value) {
            throw new Error('User id needed for fetching Notifications!');
        }

        return httpClient.get(`/secure/users/${userId.value}/system-notifications`);
    }
}

export default new SystemNotificationService;
