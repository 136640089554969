import {PaymentScheme} from '@app/tender/PaymentScheme';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';

export function usePaymentSchemeOptions() {
    const {t} = useI18n();

    return {
        paymentSchemeOptions: computed(() => Object.values(PaymentScheme)
            .reduce((map, paymentScheme) => {
                map.set(paymentScheme, t(`tender.paymentScheme.${paymentScheme.toLowerCase()}`));
                return map;
            }, new Map as Map<PaymentScheme, string>)),
    };
}
