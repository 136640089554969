<template>
  <div class="w-full relative flex h-[5px] bg-primary-600 overflow-hidden">
    <span class="absolute top-0 w-[40%] h-full" />
  </div>
</template>

<style scoped>
span{
  background-image: linear-gradient(to right, transparent, theme('colors.primary.400'), transparent);
  animation: 1s moving_03 ease-in-out infinite;
}

@keyframes moving_03 {
  0% { left: -30%; }
  100% { left: 100%; }
}
</style>
