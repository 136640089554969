<template>
  <Link
    :to="detailsRoute"
    class="flex break-all items-stretch bg-white rounded-lg py-4 my-2 hover:bg-gray-100"
  >
    <span class="inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-100 flex-none ml-1">
      <svg
        class="h-full w-full text-gray-300"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
    </span>

    <div class="flex items-center flex-1">
      <div class="flex-1 ml-4">
        <p class="text-gray-900 font-medium">
          {{ user.firstName }} {{ user.lastName }}
        </p>
        <p class="font-light text-gray-600 text-sm">
          {{ user.email }}
        </p>
      </div>
    </div>

    <div class="flex flex-none flex-col md:flex-row justify-center md:justify-start items-center space-y-2 md:space-y-0 md:space-x-2">
      <div
        v-if="!user.active"
        class="flex-none flex items-center"
      >
        <Badge :type="BadgeType.WARNING">
          {{ $t('company.users.inactive') }}
        </Badge>
      </div>

      <div
        v-if="user.id.equals(userId)"
        class="flex-none flex items-center"
      >
        <Badge :type="BadgeType.SUCCESS">
          {{ $t('company.users.you') }}
        </Badge>
      </div>

      <div
        v-if="user.companyCreator"
        class="flex-none flex items-center"
      >
        <Badge :type="BadgeType.NEUTRAL">
          {{ $t('company.users.companyCreator') }}
        </Badge>
      </div>
    </div>
  </Link>
</template>

<script lang="ts" setup>
import {MinimalCompanyUser} from './CompanyUser';
import {computed, toRefs} from 'vue';
import {userDetailsRoute} from '@app/company/routes';
import {RouteLocationRaw} from 'vue-router';
import Link from '@app/navigation/Link.vue';
import {BadgeType} from '@app/support/badge/BadgeType';
import Badge from '@app/support/badge/Badge.vue';
import {userId} from '@app/auth/Auth';

const props = defineProps<{
  user: MinimalCompanyUser
}>();

const {user} = toRefs(props);

const detailsRoute = computed(() => (
    {
      name: userDetailsRoute.name,
      params: {userId: user.value.id.toString()},
    } as RouteLocationRaw
));
</script>
