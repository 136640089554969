<template>
  <teleport to="#toast-region">
    <transition
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="show"
        role="log"
        v-bind="$attrs"
        class="w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
      >
        <div class="p-4">
          <slot />
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts" setup>
// this is the base toast component, for direct usage prefer for example SimpleToast
import {watchEffect} from 'vue';

defineOptions({
    inheritAttrs: false,
});

interface Props {
    show: boolean,
    duration?: number
}

const props = withDefaults(defineProps<Props>(), {
    duration: 3000,
});

interface Events {
    (e: 'hide'): void,
}

const emit = defineEmits<Events>();

watchEffect(() => {
    if (!props.show) {
        return;
    }

    if (props.duration === Infinity) {
        return;
    }

    setTimeout(() => {
        emit('hide');
    }, props.duration);
});
</script>
