import {Activity} from '@app/activities/types/Activity';
import {Uuid} from '@app/uuid/Uuid';
import {DateTime} from '@app/time/DateTime';
import {User} from '@app/auth/User';
import {ActivityType} from '@app/activities/ActivityType';
import {ActivityGenerator} from '@app/activities/ActivityGenerator';
import {ActivitiesResponseItem} from '@app/activities/ActivitiesResponsePayload';

export class OrderAcceptedActivity implements Activity {
    readonly type = ActivityType.ORDER_ACCEPTED;
    readonly id: Uuid;
    readonly timestamp: DateTime;
    readonly user: User;
    readonly orderId: Uuid;
    readonly tenderTitle: string;
    readonly tenderId: Uuid;

    constructor(id: Uuid, timestamp: DateTime, user: User, orderId: Uuid, tenderId: Uuid, tenderTitle: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.user = user;
        this.orderId = orderId;
        this.tenderId = tenderId;
        this.tenderTitle = tenderTitle;
    }
}

export class OrderAcceptedActivityGenerator implements ActivityGenerator<OrderAcceptedActivity> {
    generate(data: ActivitiesResponseItem): OrderAcceptedActivity {
        return new OrderAcceptedActivity(
            Uuid.fromString(data.id),
            DateTime.parseIsoUTC(data.activityTimestamp),
            {
                ...data.triggeredBy,
                id: Uuid.fromString(data.triggeredBy.id),
            },
            Uuid.fromString(data.activityPayload.orderId as string),
            Uuid.fromString(data.activityPayload.tenderId as string),
            data.activityPayload.tenderTitle as string,
        );
    }

    getType = (): ActivityType => ActivityType.ORDER_ACCEPTED;
}
