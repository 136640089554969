<template>
  <div
    v-if="loading"
    class="flex flex-col items-center"
  >
    <TrCircleLoader />
    <p class="text-gray-600 mt-4">
      {{ $t('auth.register.verification.verifying') }}
    </p>
  </div>

  <div
    v-else-if="error"
    class="flex flex-col items-center"
  >
    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
      <ExclamationTriangleIcon
        class="h-6 w-6 text-red-600"
        aria-hidden="true"
      />
    </div>
    <p
      class="mb-4 text-lg font-medium"
      v-text="$t('auth.verify.tokenError')"
    />
  </div>
</template>

<script lang="ts" setup>
import {ref} from 'vue';
import TrCircleLoader from '@app/loader/CircleLoader.vue';
import {ExclamationTriangleIcon} from '@heroicons/vue/24/outline';
import {authService} from '@app/auth/AuthService';
import {useRoute} from 'vue-router';
import auth from './Auth';

const emit = defineEmits<{
  (e: 'verified'): void
}>();

const loading = ref(false);
const error = ref(false);

const route = useRoute();

const verify = async () => {
  loading.value = true;
  error.value = false;

  // fake the verification process taking some time to not disturb the user
  const fakeWaitPromise = new Promise(resolvePromiseAfterTimeout);

  const verifyPromise = authService.verifyEmail({
    verificationToken: route.query?.t as string,
  })
      .catch(() => {
        error.value = true;
      });

  const [, verifyRes] = await Promise.all([
    fakeWaitPromise,
    verifyPromise,
  ]);

  if (verifyRes?.data) {
    auth.setTokenPair(verifyRes.data.authToken, verifyRes.data.refreshToken);
    emit('verified');
  }
};

verify();

function resolvePromiseAfterTimeout(resolve: CallableFunction) {
  return setTimeout(resolve, 1500);
}
</script>
