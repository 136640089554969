import {useI18n} from 'vue-i18n';
import {TruckType} from '@app/tender/trucks/TruckType';
import {TruckBedType} from '@app/tender/trucks/TruckBedType';
import {TruckFeature} from '@app/tender/trucks/TruckFeature';
import {naturalLanguageJoin} from '@app/i18n/naturalLanguageJoin';

export function useTruckTranslations() {
    const { t } = useI18n();

    const truckFeatureTranslation = (truckFeature: TruckFeature) => t(`tender.truckFeature.${truckFeature}`);

    return {
        truckTypeTranslation: (truckType: TruckType) => t(`tender.truckType.${truckType}`),
        truckBedTypeTranslation: (truckBedType: TruckBedType) => t(`tender.truckBedType.${truckBedType}`),
        truckFeatureTranslation,
        truckFeaturesAnd: (truckFeatures: TruckFeature[]) => {
            const truckFeatureTranslations = truckFeatures.map(truckFeatureTranslation);
            return naturalLanguageJoin(truckFeatureTranslations, t('and'));
        },
    };
}
