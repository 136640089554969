<template>
  <TrBaseGuestWrapper>
    <div class="flex justify-center">
      <TrVerifyInvitationEmailVerifyStep
        v-if="!isVerified"
        @verified="isVerified = true"
      />

      <form
        v-else
        class="space-y-6"
        @submit.prevent="changePassword"
      >
        <tr-labeled-input
          v-model="form.password"
          name="password"
          autocomplete="new-password"
          minlength="8"
          required=""
          :label="$t('auth.register.password')"
          type="password"
        />

        <tr-labeled-input
          v-model="form.passwordConfirm"
          name="password-verify"
          autocomplete="new-password"
          minlength="8"
          required=""
          :label="$t('auth.register.confirmPassword')"
          type="password"
          :errors="errors.passwordConfirm"
          @input="errors.passwordConfirm = []"
        />

        <TrButton
          type="submit"
          class="w-full"
        >
          {{ $t('auth.register.submit') }}
        </TrButton>
      </form>
    </div>
  </TrBaseGuestWrapper>
</template>

<script lang="ts" setup>
import {reactive, ref} from 'vue';
import TrBaseGuestWrapper from '@app/shell/BaseGuestWrapper.vue';
import {authService} from '@app/auth/AuthService';
import {userId} from './Auth';
import {useRouter} from 'vue-router';
import TrLabeledInput from '@app/forms/LabeledInput.vue';
import TrVerifyInvitationEmailVerifyStep from '@app/auth/VerifyInvitationEmailVerifyStep.vue';
import TrButton from '@app/support/TrButton.vue';
import {useI18n} from 'vue-i18n';

const loading = ref(false);
const error = ref(false);

const router = useRouter();

const {t} = useI18n();

/** the user has successfully called the verify-endpoint with the token from the route */
const isVerified = ref(false);

const form = reactive({
  password: '',
  passwordConfirm: '',
});

const errors = reactive({
  passwordConfirm: [] as string[],
});

const changePassword = async () => {
  isVerified.value = true;
  loading.value = true;
  error.value = false;

  if (form.password !== form.passwordConfirm) {
    errors.passwordConfirm = [t('auth.register.passwordMisMatch')];
    return;
  }

  if (!userId.value) {
    throw new Error('Cannot Verify E-Mail. User not logged in!');
  }

  authService.changePassword(userId.value, {
    newPassword: form.password,
  })
      .then(() => {
        loading.value = false;

        router.replace({path: '/', query: {mailVerified: '1'}});
      })
      .catch(() => {
        error.value = true;
      });
};
</script>
