<template>
  <SimpleModal
    click-to-close
    title="Filter konfigurieren"
    :show="show"
    width-class="max-w-lg w-full lg:max-w-6xl"
    @show="resetState"
    @hide="$emit('hide')"
  >
    <TrForm
      v-if="currentSearchParams"
      id="tender-filter-form"
      @validated="search"
    >
      <div class="grid gap-4 grid-cols-1 md:grid-cols-2 mt-6">
        <div class="flex items-center col-span-2">
          <div class="flex-1">
            <LocationCombobox
              v-model="locationOption"
              class="flex-1"
              :label="$t('tender.search.filter.location.label')"
              :location-details="currentSearchParams.locationDetails"
              @update:location-details="setSearchParam('locationDetails', $event)"
            />
          </div>
          <div class="flex-none ml-2">
            <TrLabel
              for="geo-location-button"
              class="invisible"
            >
              g
            </TrLabel>
            <GeoLocationButton
              id="geo-location-button"
              class="mt-1"
              @update:location-details="onGeoLocation"
            />
          </div>
        </div>

        <div>
          <TrLabel for="tender-search-from">
            {{ $t('tender.search.filter.from.label') }}
          </TrLabel>
          <DateInput
            id="tender-search-from"
            v-model="fromDate"
            :max-date="toDate"
          />
        </div>

        <div>
          <TrLabel for="tender-search-to">
            {{ $t('tender.search.filter.to.label') }}
          </TrLabel>
          <DateInput
            id="tender-search-to"
            v-model="toDate"
            :min-date="fromDate"
          />
        </div>

        <TruckTypeSelect
          multiple
          :model-value="currentSearchParams.truckTypes"
          :label="$t('tender.search.filter.truckTypes.label')"
          @update:model-value="setSearchParam('truckTypes', $event as typeof currentSearchParams.truckTypes)"
        />
      </div>
      <label
        v-if="hasAuthToken"
        for="activate-notification"
        class="inline-flex items-center mt-4 cursor-pointer group hover:bg-gray-100 py-2 px-1 rounded-md"
      >
        <component
          :is="currentSearchParams.notificationEnabled ? BellAlertIcon : BellSlashIcon"
          aria-hidden="true"
          :class="{
            'w-6 h-6 mr-1 group-[&:has(:focus-visible)]:ring-2 group-[&:has(:focus-visible)]:ring-offset-2 group-[&:has(:focus-visible)]:ring-primary-500 rounded-md': true,
            'text-primary-500': currentSearchParams.notificationEnabled,
          }"
        />

        <input
          id="activate-notification"
          :checked="currentSearchParams.notificationEnabled"
          class="sr-only"
          type="checkbox"
          @change="onNotificationChange"
        >
        <span v-if="currentSearchParams.notificationEnabled">Benachrichtigungen aktiv</span>
        <span v-else>Benachrichtigungen inaktiv</span>
      </label>
    </TrForm>
    <template #actions>
      <div class="mb-4 sm:mb-0">
        <TrButton
          class="w-full"
          type="submit"
          form="tender-filter-form"
          :loading="loading"
        >
          {{ $t('generic.send') }}
        </TrButton>
      </div>
      <div class="sm:mr-4">
        <TrButton
          class="w-full"
          :color-scheme="ButtonColorScheme.TERTIARY"
          @click="$emit('hide')"
        >
          {{ $t('generic.close') }}
        </TrButton>
      </div>
    </template>
  </SimpleModal>
</template>

<script setup lang="ts">
import SimpleModal from '@app/overlays/modals/SimpleModal.vue';
import TrButton from '@app/support/TrButton.vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import TrForm from '@app/forms/TrForm.vue';
import LocationCombobox from '@app/forms/LocationCombobox.vue';
import {computed, ref, toRefs} from 'vue';
import {locationProvider} from '@app/location/LocationProvider';
import {useAsyncAction} from '@app/http/useAsyncAction';
import DateInput from '@app/forms/DateInput.vue';
import TrLabel from '@app/forms/TrLabel.vue';
import TruckTypeSelect from '@app/tender/create/TruckTypeSelect.vue';
import GeoLocationButton from '@app/tender/search/GeoLocationButton.vue';
import {LocationDetails} from '@app/forms/Location';
import Calendar from '@app/time/Calendar';
import {DateTime} from '@app/time/DateTime';
import Time from '@app/time/Time';
import {hasAuthToken} from '@app/auth/Auth';
import {BellAlertIcon, BellSlashIcon} from '@heroicons/vue/24/outline';
import {NotifiableTruckOfferSearchParams} from '@app/truckoffer/search/TruckOfferSearchParams';


interface Props {
    show: boolean,
    searchParams: NotifiableTruckOfferSearchParams
    loading: boolean
}

const props = defineProps<Props>();

const {
    searchParams,
} = toRefs(props);

const emit = defineEmits<{
    (e: 'search', searchParams: Props['searchParams']): void
    (e: 'hide'): void
}>();

const currentSearchParams = ref<NotifiableTruckOfferSearchParams>();

function resetState() {
  currentSearchParams.value = searchParams.value;
}

const {execute: fetchLocationOption, data: locationOption} = useAsyncAction(async function() {
  return searchParams.value.locationDetails ? await locationProvider().fetchLocationOptionByDetails(searchParams.value.locationDetails) : undefined;
});

fetchLocationOption();

function search() {
  if (!currentSearchParams.value) {
    throw new Error('Should not happen, currentSearchParams empty, should be set after opening');
  }

  emit('search', currentSearchParams.value);
}

function setSearchParam<T extends keyof NotifiableTruckOfferSearchParams>(prop: T, value: NotifiableTruckOfferSearchParams[T]) {
  if (!currentSearchParams.value) {
    throw 'CurrentSearchParams nullish!';
  }

  currentSearchParams.value = {...currentSearchParams.value, [prop]: value};
}

function onGeoLocation(location: LocationDetails) {
  setSearchParam('locationDetails', location);
  locationOption.value = {
    place_id: location.externalId,
    description: location.displayName,
  };
}

const fromDate = computed({
  get: () => currentSearchParams.value?.fromDate ? Calendar.fromDateTime(currentSearchParams.value.fromDate) : undefined,
  set: newValue => setSearchParam('fromDate', newValue ? DateTime.fromDateAndTime(newValue, Time.parseIsoUTC('00:00')) : undefined),
});

const toDate = computed({
  get: () => currentSearchParams.value?.toDate ? Calendar.fromDateTime(currentSearchParams.value?.toDate) : undefined,
  set: newValue => setSearchParam('toDate', newValue ? DateTime.fromDateAndTime(newValue, Time.parseIsoUTC('23:59')) : undefined),
});

function onNotificationChange(event: Event) {
  const target = event.target as HTMLInputElement;

  setSearchParam('notificationEnabled', target.checked);
}
</script>
