import {DateTime} from '@app/time/DateTime';

export class YearMonth {
    private readonly dateTime: DateTime;

    get month(): number {
        return this.dateTime.month;
    }

    get year(): number {
        return this.dateTime.year;
    }

    private constructor(dateTime: DateTime) {
        this.dateTime = dateTime;
    }

    static parseIsoUTC(isoString: string) {
        const dateTime = DateTime.parseIsoUTC(isoString);
        return new YearMonth(dateTime);
    }

    public toHuman(): string {
        return this.dateTime.toHumanMonthYear();
    }
}
