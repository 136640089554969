<template>
  <button
    v-if="isSupported"
    type="button"
    class="p-2 rounded-md bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
    @click="determineLocation"
  >
    <Spinner
      v-if="loading"
      class="w-6 h-6 text-primary-600"
    />
    <MapPinIcon
      v-else
      class="w-6 h-6 flex-none text-primary-900"
    />
  </button>
</template>

<script lang="ts" setup>
import {MapPinIcon} from '@heroicons/vue/24/outline';
import {ref} from 'vue';
import {makeToast} from '@app/overlays/toast/ToastManager';
import {useI18n} from 'vue-i18n';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import Spinner from '@app/support/Spinner.vue';
import {LocationDetails} from '@app/forms/Location';
import {locationProvider} from '@app/location/LocationProvider';
import {useGenericErrorToast} from '@app/overlays/toast/useGenericErrorToast';

const { t } = useI18n();

const isSupported = navigator && 'geolocation' in navigator;
const loading = ref(false);

const emit = defineEmits<{
  (e: 'update:location-details', value: LocationDetails): void
}>();

const { showGenericErrorToast } = useGenericErrorToast();

const onLocationSuccess: PositionCallback = async (position: GeolocationPosition) => {
  loading.value = false;

  const locationResult = await locationProvider().positionToLocationOption(position.coords.latitude, position.coords.longitude);

  if (locationResult.isError) {
    showGenericErrorToast();
    return;
  }

  emit('update:location-details', locationResult.value);
};

const onLocationError: PositionErrorCallback = (error: GeolocationPositionError) => {
  loading.value = false;

  let body = error.message;
  if (error.code === error.PERMISSION_DENIED) {
    body = t('tender.search.filter.location.geoDenied');
  }

  makeToast({
    title: t('generic.error'),
    body: body,
    mode: ToastMode.ERROR,
  });
};

function determineLocation() {
  loading.value = true;
  navigator.geolocation.getCurrentPosition(onLocationSuccess, onLocationError, {
    maximumAge: 5_000,
  });
}
</script>
