import {RouteRecordRaw} from 'vue-router';
import RegisteredCompaniesReporting from '@app/reporting/RegisteredCompaniesReporting.vue';

export const registeredCompaniesRoute: RouteRecordRaw = {
    name: 'registeredCompanies',
    path: '/reporting/registered-companies',
    component: RegisteredCompaniesReporting,
};

export const reportingRoutes: RouteRecordRaw[] = [
    registeredCompaniesRoute,
];
