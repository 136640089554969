<template>
  <TrModal
    :title="title"
    :show="show"
    click-to-close
    @hide="$emit('hide')"
  >
    <div class="sm:flex sm:items-start">
      <div
        class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
        :class="{
          'bg-red-100': mode === ModalMode.ERROR,
          'bg-blue-100': mode === ModalMode.INFO,
          'bg-green-100': mode === ModalMode.SUCCESS,
          'bg-yellow-100': mode === ModalMode.WARNING
        }"
      >
        <ExclamationTriangleIcon
          v-if="mode === ModalMode.ERROR"
          class="h-6 w-6 text-red-600"
          aria-hidden="true"
        />
        <InformationCircleIcon
          v-else-if="mode === ModalMode.INFO"
          class="h-6 w-6 text-blue-600"
          aria-hidden="true"
        />
        <CheckIcon
          v-else-if="mode === ModalMode.SUCCESS"
          class="h-6 w-6 text-green-600"
          aria-hidden="true"
        />
        <ExclamationTriangleIcon
          v-if="mode === ModalMode.WARNING"
          class="h-6 w-6 text-yellow-600"
          aria-hidden="true"
        />
      </div>
      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <DialogTitle
          as="h3"
          class="text-lg leading-6 font-medium text-gray-900"
        >
          {{ title }}
        </DialogTitle>
        <div class="mt-2 text-sm text-gray-500">
          <slot />
        </div>
      </div>
    </div>

    <template #actions>
      <slot name="actions" />
    </template>
  </TrModal>
</template>

<script lang="ts">
import TrModal from '@app/overlays/modals/Modal.vue';
import {defineComponent, PropType} from 'vue';
import {CheckIcon, ExclamationTriangleIcon, InformationCircleIcon} from '@heroicons/vue/24/outline';
import {ModalMode} from './ModalMode';
import {DialogTitle} from '@headlessui/vue';

export default defineComponent({
  components: {
    TrModal,
    InformationCircleIcon,
    CheckIcon,
    ExclamationTriangleIcon,
    DialogTitle,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    mode: {
      type: Number as PropType<ModalMode>,
      default: () => ModalMode.INFO,
    },
  },

  emits: ['hide'],

  setup() {
    return {
      ModalMode,
    };
  },
});
</script>
