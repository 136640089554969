<template>
  <TrLabeledSelect
    :model-value="modelValue"
    :items="availableTruckOfferRoles"
    :rules="rules"
    :label="label"
    @update:model-value="$emit('update:model-value', $event as typeof modelValue)"
  />
</template>

<script lang="ts" setup>
import {mapValues, toPairs} from 'lodash';
import {useI18n} from 'vue-i18n';
import TrLabeledSelect from '@app/forms/LabeledSelect.vue';
import Rule from '@app/forms/rules/Rule';
import {TruckOfferRole, truckOfferRoles} from '@app/truckoffer/TruckOfferRole';

interface Props {
  modelValue: TruckOfferRole,
  rules?: Rule<unknown>[],
  label: string
}

defineProps<Props>();

interface Events {
  (e: 'update:model-value', value: Props['modelValue']): void
}

defineEmits<Events>();

const {t} = useI18n();

const availableTruckOfferRoles = new Map(
    toPairs(
        mapValues(truckOfferRoles, (role: TruckOfferRole) => t(`truckOffer.role.${role}`)),
    ),
);
</script>
