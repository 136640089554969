import {HttpErrorHandler} from '@app/http/HttpErrorHandler';
import {useAsyncAction} from '@app/http/useAsyncAction';

/**
 * Execute a Query, exposing fundamental data about the query like if it is fetching, fetching for the first time etc.
 * Executes the query on first call
 *
 * @param fn function to execute, most probably an HTTP Call
 * @param errorHandlerConfigurator configure HttpErrorHandler used for any error thrown inside of given fn, standard handler will already have genericErrorHandler
 *
 * @example
 * // simple
 * const {isPending, isFetching, isError, data} = useQuery(() => httpClient.get('ROUTE', payload))
 */
export const useQuery = <T>(fn: () => Promise<T>, errorHandlerConfigurator?: (errorHandler: HttpErrorHandler) => HttpErrorHandler) => {
    const useAsyncActionData = useAsyncAction(fn, errorHandlerConfigurator);

    useAsyncActionData.execute();

    return {
        data: useAsyncActionData.data,
        reFetch: useAsyncActionData.execute,
        isFetching: useAsyncActionData.isFetching,
        isPending: useAsyncActionData.isPending,
        isError: useAsyncActionData.isError,
    };
};
