<template>
  <TrCombobox
    :model-value="modelValue"
    :items="locationOptions"
    :resolve-label="locationLabelFromValue"
    :query="query"
    :label="label"
    :rules="rules"
    :name="name"
    @update:model-value="onModelValueUpdate($event)"
    @change="onInputChange($event.target)"
  />
</template>

<script lang="ts" setup>
import TrCombobox from '@app/forms/TrCombobox.vue';
import type {LocationDetails, LocationOption} from '@app/forms/Location';
import Rule from '@app/forms/rules/Rule';
import {locationProvider} from '@app/location/LocationProvider';
import {comboboxItem} from '@app/forms/comboboxItem';
import {useLocationCombobox} from '@app/location/useLocationCombobox';

defineProps<{
  modelValue?: LocationOption
  locationDetails: LocationDetails | undefined
  label?: string
  rules?: Rule<comboboxItem>[],
  name?: string,
}>();

const emit = defineEmits<{
  (e: 'update:model-value', value: LocationOption): void,
  (e: 'update:location-details', value: LocationDetails): void
}>();

async function onModelValueUpdate(value: unknown) {
  const locationOption = value as LocationOption;
  const details = await locationProvider().getDetailsOfLocationOption(locationOption);

  emit('update:location-details', details);
  emit('update:model-value', locationOption);
}

const {query, locationOptions, onInputChange, locationLabelFromValue} = useLocationCombobox();
</script>
