export type Result<T,E> = OkResult<T> | ErrorResult<E>;

interface ResultContract<T, E> {
    readonly isOk: boolean
    readonly isError: boolean
    readonly value: T | E

    orElse<T2>(alternative: T2): T | T2

    onError(handler: (error: E) => void): void
}

interface OkResult<T> extends ResultContract<T, never> {
    readonly isOk: true
    readonly isError: false
    readonly value: T
}

interface ErrorResult<E> extends ResultContract<never, E>{
    readonly isOk: false
    readonly isError: true
    readonly value: E
}

export function Ok<T>(value: T): OkResult<T> {
    return {
        isOk: true,
        isError: false,
        value: value,

        orElse(): T {
            return value;
        },

        onError() {
            return;
        },
    };
}
Ok.EMPTY = Ok(undefined);

export function Err<E>(error: E): ErrorResult<E> {
    return {
        isOk: false,
        isError: true,
        value: error,

        orElse<T2>(alternative: T2): T2 {
            return alternative;
        },

        onError(handler: (error: E) => void) {
            handler(error);
            return;
        },
    };
}
Err.EMPTY = Err(undefined);
