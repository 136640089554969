import Login from './Login.vue';
import Register from './Register.vue';
import VerifyEmail from './VerifyEmail.vue';
import VerifyInvitationEmail from './VerifyInvitationEmail.vue';
import {RouteLocationNormalized, RouteRecordRaw} from 'vue-router';
import ResetPassword from '@app/auth/ResetPassword.vue';
import {hasAuthToken} from '@app/auth/Auth';

const redirectIfAlreadySignedIn = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    // only allow navigation to route if user is not logged in
    if (!hasAuthToken.value) {
        return true;
    }

    if (from) {
        return from;
    }

    return '/';
};

export const loginRoute: RouteRecordRaw = {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
        blank: true,
        allowAnonymous: true,
    },
    beforeEnter: redirectIfAlreadySignedIn,
};

export const registerRoute: RouteRecordRaw = {
    name: 'register',
    path: '/register',
    component: Register,
    meta: {
        blank: true,
        allowAnonymous: true,
    },
    beforeEnter: redirectIfAlreadySignedIn,
};

export const verifyEmailRoute: RouteRecordRaw = {
    path: '/verify-email',
    component: VerifyEmail,
    meta: {
        blank: true,
        allowAnonymous: true,
    },
};

export const verifyInvitationEmailRoute: RouteRecordRaw = {
    path: '/verify-invitation-email',
    component: VerifyInvitationEmail,
    meta: {
        blank: true,
        allowAnonymous: true,
    },
};

export const resetPasswordRoute: RouteRecordRaw = {
    name: 'resetPassword',
    path: '/reset-password',
    component: ResetPassword,
    meta: {
        blank: true,
        allowAnonymous: true,
    },
};

export const authRoutes: RouteRecordRaw[] = [
    loginRoute,
    registerRoute,
    verifyEmailRoute,
    verifyInvitationEmailRoute,
    resetPasswordRoute,
];
