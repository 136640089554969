import {MapProviderContract} from '@app/location/MapProviderContract';

class GoogleMapsMapProvider implements MapProviderContract {

    getScriptUrl(): string {
        return 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBU_m1nCT17nqw36CpOmQ4AWCe-USl4agk&callback=googleMapsInitialized';
    }
}

export const googleMapsMapProvider = new GoogleMapsMapProvider as MapProviderContract;
