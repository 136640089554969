import httpClient from '@app/http/HttpClient';
import {Uuid} from '@app/uuid/Uuid';
import {UuidString} from '@app/uuid/UuidString';
import {DateString} from '@app/support/DateString';
import {TruckType} from '@app/tender/trucks/TruckType';
import {NotifiableTenderSearchParams} from '@app/tender/search/TenderSearchParams';
import {DateTime} from '@app/time/DateTime';

export interface ActiveTenderFilterResponsePayload {
    id: UuidString,
    filterCompanyId?: UuidString,
    filterDateOfExecutionGt?: DateString,
    filterDateOfExecutionLt?: DateString,
    filterLocationDisplayName?: string,
    filterLocationLatitude?: number,
    filterLocationLongitude?: number,
    filterLocationExternalId?: string,
    filterLocationRadius?: number,
    notificationEnabled: boolean,
    truckTypes: TruckType[]
}

type UpdateTenderFilterRequestPayload = Omit<ActiveTenderFilterResponsePayload, 'id'>;

let activeTenderFilterId: UuidString|undefined = undefined;

export async function fetchActiveTenderFilterFromBackend(userId: Uuid): Promise<NotifiableTenderSearchParams> {
    const {data: {
        filterDateOfExecutionGt,
        filterDateOfExecutionLt,
        filterLocationDisplayName,
        filterLocationExternalId,
        filterLocationLatitude,
        filterLocationLongitude,
        filterLocationRadius,
        id,
        truckTypes,
        notificationEnabled,
    }} = await httpClient.get<ActiveTenderFilterResponsePayload>(`/secure/users/${userId}/filter/active`);

    activeTenderFilterId = id;

    return {
        perimeter: filterLocationRadius,
        locationDetails: filterLocationDisplayName && filterLocationLatitude && filterLocationLongitude && filterLocationExternalId ? {
            displayName: filterLocationDisplayName,
            latitude: filterLocationLatitude,
            longitude: filterLocationLongitude,
            externalId: filterLocationExternalId,
        } : undefined,
        fromDate: filterDateOfExecutionGt ? DateTime.parseIsoUTC(filterDateOfExecutionGt) : undefined,
        toDate: filterDateOfExecutionLt ? DateTime.parseIsoUTC(filterDateOfExecutionLt) : undefined,
        truckTypes,
        notificationEnabled,
    };
}

export async function updateActiveTenderFilterInBackend(userId: Uuid, {fromDate, locationDetails, toDate, perimeter, truckTypes, notificationEnabled}: NotifiableTenderSearchParams) {
    if (!activeTenderFilterId) {
        // fetch active filter to know what the id of the active filter is, so we know which to update
        await fetchActiveTenderFilterFromBackend(userId);
    }

    const payload: UpdateTenderFilterRequestPayload = {
        filterLocationRadius: perimeter,
        notificationEnabled,
        filterDateOfExecutionGt: fromDate?.toISO(),
        filterDateOfExecutionLt: toDate?.toISO(),
        truckTypes: truckTypes,
        filterLocationDisplayName: locationDetails?.displayName,
        filterLocationLongitude: locationDetails?.longitude,
        filterLocationLatitude: locationDetails?.latitude,
        filterLocationExternalId: locationDetails?.externalId,

    };
    await httpClient.put(`/secure/users/${userId}/filter/${activeTenderFilterId}`, payload);
}
