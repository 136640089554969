<template>
  <div
    v-if="registeredCompanies"
    class="mt-8 flow-root"
  >
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Firma
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Anmeldezeitpunkt
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr
                v-for="company in registeredCompanies"
                :key="company.id.toString()"
              >
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {{ company.name }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ company.createdAt?.toSystem().toFormat('dd.LL.yyyy HH:mm:ss') ?? 'Unbekannt' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useAsyncAction} from '@app/http/useAsyncAction';
import {fetchRegisteredCompanies} from '@app/reporting/reportingService';

const {data: registeredCompanies, execute: fetchData} = useAsyncAction(async () => {
  return await fetchRegisteredCompanies();
});

fetchData();
</script>
