<template>
  <div>
    <TrSystemNotification
      v-for="systemNotification in systemNotifications"
      :key="systemNotification.id"
      :title="systemNotification.title"
      :body="systemNotification.body"
    />
  </div>
</template>

<script lang="ts" setup>
import TrSystemNotification from '@app/dashboard/SystemNotification.vue';
import {ref} from 'vue';
import systemNotificationService, {SystemNotification} from './SystemNotificationService';

const systemNotifications = ref<SystemNotification[]>([]);
const fetchNotifications = async () => {
  // TODO loading state?
  const {data} = await systemNotificationService.fetchAll();

  systemNotifications.value = data.notifications;
};
fetchNotifications();
</script>
