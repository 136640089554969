import {UuidString} from '@app/uuid/UuidString';
import {Uuid} from '@app/uuid/Uuid';

export interface MinimalCompany {
    id: Uuid,
    name: string,
    logo?: string,
}

export interface MinimalCompanyResponse {
    id: UuidString,
    name: string,
    logo?: string,
}

export function mapMinimalCompany(input: MinimalCompanyResponse) {
    return {
        ...input,
        id: Uuid.fromString(input.id),
    };
}
