<template>
  <div
    v-if="hasErrors"
    class="text-red-900"
  >
    <ul>
      <li
        v-for="err in errors"
        :key="err"
      >
        {{ err }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import {computed, toRefs} from 'vue';

interface Props {
  errors: string[]
}

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
});

const {errors} = toRefs(props);

const hasErrors = computed(() => errors.value.length);
</script>
