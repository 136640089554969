import {makeToast, ToastCreateDefinition} from '@app/overlays/toast/ToastManager';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import {useI18n} from 'vue-i18n';

export const useGenericErrorToast = () => {
    const {t} = useI18n();

    return {
        showGenericErrorToast: (testingIdentifier?: string) => {
            const errorToast: ToastCreateDefinition = {
                title: t('generic.error'),
                body: t('generic.errorToastBody'),
                mode: ToastMode.ERROR,
                testingIdentifier: testingIdentifier ?? 'generic-error-toast',
            };
            makeToast(errorToast);
        },
    };
};
