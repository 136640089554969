<template>
  <tr data-cy="bill-list-item">
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
      <time>{{ bill.period.toHuman() }}</time>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <Money :value="bill.value" />
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      {{ statusText }}
    </td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
      <Link
        :to="{name: billDetailsRoute.name, params: {id: bill.id.toString()}}"
        class="text-primary-600 hover:text-primary-900"
      >
        {{ $t('billing.bills.toDetails') }}<span class="sr-only">, {{ bill.period.toHuman() }}</span>
      </Link>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import {MinimalBill} from '@app/billing/MinimalBill';
import Link from '@app/navigation/Link.vue';
import Money from '@app/money/money/Money.vue';
import {computed} from 'vue';
import {evaluateStatusText} from '@app/billing/evaluateStatusText';
import {useI18n} from 'vue-i18n';
import {billDetailsRoute} from '@app/billing/routes';


interface Props {
  bill: MinimalBill
}

const props = defineProps<Props>();

const {t} = useI18n();

const statusText = computed(() => evaluateStatusText(t, props.bill.status));
</script>
