<template>
  <div
    v-if="tabs.length"
    class="mt-6 sm:mt-2 2xl:mt-5"
  >
    <div class="border-b border-gray-200">
      <div>
        <nav
          class="-mb-px flex space-x-8"
          aria-label="Tabs"
        >
          <button
            v-for="tab in tabs"
            :key="tab.id"
            :class="[
              isCurrentTab(tab) ? 'border-primary-500 text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none focus:ring-primary-500'
            ]"
            :aria-current="isCurrentTab(tab) ? 'page' : undefined"
            @click="activateTab(tab)"
          >
            {{ tab.name }}
          </button>
        </nav>
      </div>
    </div>
  </div>
  <slot :current-tab-id="currentTabId" />
</template>

<script lang="ts" setup>
import {Tab} from '@app/navigation/Tab';
import {toRefs} from 'vue';

interface Props {
  tabs: Tab[],
  currentTabId?: string
}

const props = defineProps<Props>();

const {currentTabId} = toRefs(props);

interface Events {
  (e: 'change', tab: Tab): void
}

const emit = defineEmits<Events>();

const isCurrentTab = (tab: Tab) => tab.id === currentTabId?.value;

const activateTab = (tab: Tab) => {
  emit('change', tab);
};

</script>
