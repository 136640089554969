export default class Kilometers {
    private readonly value: number;

    private constructor(value: number) {
        this.value = value;
    }

    static of(value: number) {
        return new Kilometers(value);
    }

    compareTo(other: Kilometers) {
        if(this.value > other.value) {
            return 1;
        }

        if (this.value === other.value) {
            return 0;
        }

        return -1;
    }

    asNumber() {
        return this.value;
    }
}
