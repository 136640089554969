import {sseClient} from '@app/sse/SseClient';
import {onUnmounted} from 'vue';
import {SseMessage} from '@app/sse/SseMessage';

export function useSse<E extends SseMessage>(url: string) {
    const connection = sseClient.subscribe<E>(url);

    onUnmounted(() => {
        connection.close();
    });

    return {
        connection,
    };
}
