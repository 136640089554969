<template>
  <div
    v-if="!loadingInitial && truckOfferList && truckOfferList.items && !truckOfferList.items.length"
    class="pt-6"
  >
    <NoData>
      {{ $t('truckOffer.list.page.empty') }}
    </NoData>
  </div>

  <div v-if="!loadingInitial && truckOfferList && truckOfferList.items && truckOfferList.items.length">
    <h1 class="truncate text-2xl font-bold text-gray-900 mb-6">
      {{ truckOfferList.name }}
    </h1>

    <TrTruckOfferList
      :truck-offers="truckOfferList.items"
    />
  </div>
</template>

<script lang="ts" setup>
import NoData from '@app/support/NoData.vue';
import {useRoute} from 'vue-router';
import {computed} from 'vue';
import {Uuid} from '@app/uuid/Uuid';
import {ValidationErrorCode} from '@app/http/ValidationErrorCode';
import {makeToast} from '@app/overlays/toast/ToastManager';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import {useQuery} from '@app/http/useQuery';
import {fetchTruckOfferList} from '@app/truckoffer/truckOfferService';
import TrTruckOfferList from '@app/truckoffer/TruckOfferList.vue';

const route = useRoute();

const truckOfferListId = computed(() => route.params.id as string);

function handleMissingPrivilegesToast() {
  makeToast({
    title: 'Fehlende Berechtigungen',
    body: 'Sie sind nicht berechtigt diese Frachträume zu sehen.',
    mode: ToastMode.ERROR,
  });
}

const {
  isPending: loadingInitial,
  isFetching: loading,
  data: truckOfferList,
} = useQuery(async () => {
  if (!truckOfferListId.value) {
    throw 'TruckOffer list ID not set.';
  }

  const truckOfferList = await fetchTruckOfferList(Uuid.fromString(truckOfferListId.value));

  return truckOfferList;
}, (errorHandler) => errorHandler.onValidationError(ValidationErrorCode.MISSING_PRIVILEGES, () => handleMissingPrivilegesToast()));

useAppShellBarLoader(loading);
</script>
