<template>
  <Back class="mb-4" />
  <template v-if="!isLoading && currentTender">
    <TrForm
      id="create-tender-form"
      @validated="updateTender"
    >
      <TrSection
        label-id="tender-section-head"
        with-bottom
      >
        <template #head>
          <SectionHeading id="tender-section-head">
            {{ $t('tender.update.title') }}
          </SectionHeading>

          <p class="max-w-4xl text-sm text-gray-500">
            {{ $t('tender.update.explain', {title: currentTender.title}) }}
          </p>
        </template>

        <div class="flex flex-col space-y-6">
          <TenderForm
            v-model:participants="participants"
            v-model:title="title"
            v-model:description="description"
            v-model:date-of-execution="dateOfExecution"
            v-model:payment-scheme="paymentScheme"
            v-model:payment-amount-suggestion="paymentAmountSuggestion"
            v-model:location="location"
            v-model:location-option="locationOption"
            v-model:unload-location="unloadLocation"
            v-model:unload-location-option="unloadLocationOption"
            v-model:slots="slots"
          />
        </div>

        <template #bottom>
          <div class="flex justify-end">
            <TrButton
              class="flex-grow lg:flex-none"
              :loading="isLoadingUpdate"
              type="submit"
            >
              {{ $t('tender.update.submit') }}
            </TrButton>
          </div>
        </template>
      </TrSection>
    </TrForm>
  </template>
</template>

<script lang="ts" setup>
import {computed, ref, watchEffect} from 'vue';
import TrButton from '@app/support/TrButton.vue';
import TrForm from '@app/forms/TrForm.vue';
import tenderService from '@app/tender/TenderService';
import {useRoute, useRouter} from 'vue-router';
import {tenderDetailsRoute} from '@app/tender/routes';
import {Tender} from '@app/tender/Tender';
import TenderForm from '@app/tender/create/TenderForm.vue';
import {Uuid} from '@app/uuid/Uuid';
import {useTenderForm} from '@app/tender/create/useTenderForm';
import Back from '@app/routing/Back.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import TrSection from '@app/support/TrSection.vue';
import SectionHeading from '@app/support/SectionHeading.vue';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';

const router = useRouter();
const route = useRoute();

const currentTender = ref<Tender>();

const tenderId = computed(() => Uuid.fromString(route.params.id as string));

const {
    prepareCreateTenderPayload,
    assignRefsFromTender,
    participants,
    title,
    description,
    dateOfExecution,
    paymentScheme,
    paymentAmountSuggestion,
    locationOption,
    unloadLocationOption,
    location,
    unloadLocation,
    slots,
} = useTenderForm();

const {isFetching: isLoadingUpdate, execute: updateTender} = useAsyncAction(async function () {
    const createTenderPayload = prepareCreateTenderPayload();

    await tenderService.update(
        tenderId.value,
        createTenderPayload,
    );

    await router.push({name: tenderDetailsRoute.name, params: {id: tenderId.value.toString()}});
});

const {isFetching: isLoading, execute: fetchTenderDetails} = useAsyncAction(async function () {
    const {data} = await tenderService.fetchTenderDetails(tenderId.value);
    currentTender.value = data;
});

useAppShellBarLoader(computed(() => isLoading.value || !currentTender.value));

watchEffect(async () => {
    await assignRefsFromTender(currentTender);
});

fetchTenderDetails();
</script>
