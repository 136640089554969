import {TenderParticipant, SlotForm} from '@app/tender/create/formTypes';
import {CreateTenderRequestPayload, SlotPayload} from '@app/tender/TenderService';
import Time from '@app/time/Time';
import {TenderSlot} from '@app/tender/TenderSlot';
import {UuidString} from '@app/uuid/UuidString';
import {Ref, ref} from 'vue';
import Calendar from '@app/time/Calendar';
import {PaymentScheme} from '@app/tender/PaymentScheme';
import {LocationDetails, LocationOption} from '@app/forms/Location';
import auth from '@app/auth/Auth';
import {map} from 'lodash';
import {locationProvider} from '@app/location/LocationProvider';
import keyBy from 'lodash/keyBy';
import UuidFactory from '@app/uuid/UuidFactory';
import {Tender} from '@app/tender/Tender';

export function useTenderForm() {
    const participants = ref<Record<UuidString, TenderParticipant>>();
    const title = ref<string>('');
    const description = ref<string>('');
    const dateOfExecution = ref<Calendar>();
    const paymentScheme = ref<PaymentScheme>(PaymentScheme.PER_TON);
    const paymentAmountSuggestion = ref<string>();
    const locationOption = ref<LocationOption>();
    const unloadLocationOption = ref<LocationOption>();
    const location = ref<LocationDetails>();
    const unloadLocation = ref<LocationDetails>();
    const slots = ref<Record<UuidString, SlotForm>>();

    function mapSlotFormToPayload(slot: SlotForm): SlotPayload {
        return {
            ...slot,
            timeFrom: Time.parseIsoSystem(slot.timeFrom).toUTC().toISO(),
            timeTo: Time.parseIsoSystem(slot.timeTo).toUTC().toISO(),
        };
    }

    function mapSlotToForm(slot: TenderSlot): SlotForm {
        return {
            ...slot,
            id: slot.id.toString(),
            timeFrom: slot.timeFrom.toSystem().toISO(),
            timeTo: slot.timeTo.toSystem().toISO(),
        };
    }

    async function assignRefsFromTender(tender: Ref<Tender|undefined>) {
        if (!tender.value) {
            return;
        }

        locationOption.value = tender.value.location ? await locationProvider().fetchLocationOptionByDetails(tender.value.location) : undefined;
        unloadLocationOption.value = tender.value.unloadLocation ? await locationProvider().fetchLocationOptionByDetails(tender.value.unloadLocation) : undefined;

        const mappedParticipants = tender.value.participants.map((participant): TenderParticipant => ({
            role: participant.role,
            user: participant,
        }));
        const mappedSlots = tender.value.slots.map(mapSlotToForm);
        participants.value = keyBy(mappedParticipants, () => UuidFactory.v4().toString());
        title.value = tender.value.title;
        description.value = tender.value.description;
        dateOfExecution.value = tender.value.dateOfExecution;
        paymentScheme.value = tender.value.paymentScheme;
        paymentAmountSuggestion.value = tender.value.paymentAmountSuggestion?.toBig().toString();
        location.value = tender.value.location;
        unloadLocation.value = tender.value.unloadLocation;
        slots.value = keyBy(mappedSlots, () => UuidFactory.v4().toString());
    }

    return {
        prepareCreateTenderPayload(): CreateTenderRequestPayload {
            if (!dateOfExecution.value || !location.value || !unloadLocation.value || !participants.value || !slots.value) {
                throw 'Missing data for tender creation';
            }

            return {
                companyId: auth.getCompanyId().toString(),
                dateOfExecution: dateOfExecution.value.toISO(),
                participants: map(participants.value, (participant) => ({
                    role: participant.role,
                    userId: participant.user!.id.toString(),
                })),
                title: title.value,
                description: description.value,
                location: location.value,
                unloadLocation: unloadLocation.value,
                slots: map(slots.value, mapSlotFormToPayload),
                paymentScheme: paymentScheme.value,
                paymentAmountSuggestion: Number(paymentAmountSuggestion.value),
            };
        },

        assignRefsFromTender,

        participants,
        title,
        description,
        dateOfExecution,
        paymentScheme,
        paymentAmountSuggestion,
        locationOption,
        unloadLocationOption,
        location,
        unloadLocation,
        slots,
    };
}
