import eventBus from '../eventsbus/EventBus';
import {MissingTokenEvent} from '../auth/MissingTokenEvent';
import {MissingAuthTokenError, SessionExpiredError} from '../auth/Auth';
import * as Sentry from '@sentry/vue';
import {UncaughtErrorEvent} from '@app/errors/UncaughtErrorEvent';
import {SessionExpiredErrorEvent} from '@app/auth/SessionExpiredErrorEvent';
import {NetworkErrorError} from '@app/http/NetworkErrorError';
import {NetworkErrorEvent} from '@app/http/NetworkErrorEvent';

export const errorHandler = (err: unknown) => {
    Sentry.captureException(err);

    if (err instanceof MissingAuthTokenError) {
        eventBus.emit(new MissingTokenEvent());
        return;
    }

    if (err instanceof SessionExpiredError) {
        eventBus.emit(new SessionExpiredErrorEvent());
        return;
    }

    if (err instanceof NetworkErrorError) {
        eventBus.emit(new NetworkErrorEvent());
        return;
    }

    eventBus.emit(new UncaughtErrorEvent());
};
