<template>
  <!-- Refactor: do not duplicate simple toast -->
  <TrToast
    :show="show"
    :duration="duration"
    @hide="$emit('hide')"
  >
    <div class="flex items-start">
      <div class="flex-shrink-0">
        <CheckCircleIcon
          v-if="mode === ToastMode.SUCCESS"
          class="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
        <InformationCircleIcon
          v-else-if="mode === ToastMode.INFO"
          class="h-6 w-6 text-blue-600"
          aria-hidden="true"
        />
        <ExclamationCircleIcon
          v-else-if="mode === ToastMode.ERROR"
          class="h-6 w-6 text-red-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 w-0 flex-1 pt-0.5">
        <p class="text-sm font-medium text-gray-900">
          {{ title }}
        </p>
        <LinkButton
          class="text-sm underline mt-2"
          @click="onCtaClick"
        >
          {{ ctaText }}
        </LinkButton>
      </div>
      <div class="ml-4 flex-shrink-0 flex">
        <button
          class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          @click="$emit('hide')"
        >
          <span class="sr-only">Close</span>
          <XMarkIcon
            class="h-5 w-5"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  </TrToast>
</template>


<script lang="ts" setup>
import {ToastMode} from './ToastMode';
import TrToast from './Toast.vue';
import {CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon} from '@heroicons/vue/24/outline';
import {XMarkIcon} from '@heroicons/vue/20/solid';
import LinkButton from '@app/navigation/LinkButton.vue';

interface Props {
    show: boolean,
    title: string,
    body: string,
    ctaText: string,
    mode: ToastMode,
    duration?: number
}

defineProps<Props>();

interface Events {
    (e: 'hide'): void,
    (e: 'cta-clicked'): void
}

const emit = defineEmits<Events>();

function onCtaClick() {
    emit('cta-clicked');
    emit('hide');
}
</script>
