import de from './de.json';
import { createI18n } from 'vue-i18n';

// https://vue-i18n.intlify.dev/guide/advanced/typescript.html#type-safe-resources-in-createi18n
type MessageSchema = typeof de;

const i18n = createI18n<[MessageSchema], 'de'>({
    locale: 'de',
    legacy: false,
    messages: {
        de,
    },
    numberFormats: {
        de: {
            currency: {
                style: 'currency',
                currency: 'EUR',
                notation: 'standard',
            },
        },
    },
});

export default i18n;
