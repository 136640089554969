<template>
  <template v-if="!isPending && currentCompany">
    <Back />

    <TrForm @validated="updateCompany">
      <TrSection
        label-id="update-company-title"
        with-bottom
      >
        <template #head>
          <SectionHeading id="update-company-title">
            Bearbeiten
          </SectionHeading>
        </template>

        <div class="flex flex-col space-y-6">
          <div class="flex-1">
            <LabeledInput
              v-model="name"
              :rules="[required()]"
              label="Firmenname"
            />
          </div>

          <Divider>
            <span class="bg-white px-3 text-base leading-6 text-gray-900">Rechnungsadresse</span>
          </Divider>

          <div class="flex-1">
            <LabeledInput
              v-model="billingName"
              :rules="[required()]"
              label="Name"
            />
          </div>
          <div class="flex-1">
            <LabeledInput
              v-model="billingAdditionalInfo"
              label="Zusätzliche Informationen"
            />
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <LabeledInput
                v-model="billingZip"
                :rules="[required(), positiveDecimal()]"
                label="PLZ"
              />
            </div>
            <div class="flex-1">
              <LabeledInput
                v-model="billingCity"
                :rules="[required()]"
                label="Stadt"
              />
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <LabeledInput
                v-model="billingStreet"
                :rules="[required()]"
                label="Straße"
              />
            </div>
            <div class="flex-1">
              <LabeledInput
                v-model="billingHouseNumber"
                :rules="[required(), positiveDecimal()]"
                label="Hausnummer"
              />
            </div>
          </div>
        </div>

        <template #bottom>
          <div class="flex justify-end">
            <TrButton
              :loading="loading"
              type="submit"
            >
              Speichern
            </TrButton>
          </div>
        </template>
      </TrSection>
    </TrForm>
  </template>
</template>
<script lang="ts" setup>
import {useAsyncAction} from '@app/http/useAsyncAction';
import companyService from '@app/company/CompanyService';
import auth from '@app/auth/Auth';
import {ref} from 'vue';
import TrForm from '@app/forms/TrForm.vue';
import LabeledInput from '@app/forms/LabeledInput.vue';
import TrSection from '@app/support/TrSection.vue';
import SectionHeading from '@app/support/SectionHeading.vue';
import TrButton from '@app/support/TrButton.vue';
import Back from '@app/routing/Back.vue';
import {required} from '@app/forms/rules/required';
import {RouteLocationRaw, useRouter} from 'vue-router';
import {companyRoute} from '@app/company/routes';
import {urlParamRef} from '@app/routing/urlParamRef';
import {tenderDetailsRoute} from '@app/tender/routes';
import {positiveDecimal} from '@app/forms/rules/positiveDecimal';
import Divider from '@app/support/Divider.vue';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import {truckOfferDetailsRoute} from '@app/truckoffer/routes';

const name = ref<string>();

const billingName = ref<string>();
const billingAdditionalInfo = ref<string>();
const billingZip = ref<string>();
const billingCity = ref<string>();
const billingStreet = ref<string>();
const billingHouseNumber = ref<string>();
const {execute: fetchCompany, data: currentCompany, isPending} = useAsyncAction(async () => {
  const currentCompany = await companyService.fetchCompany(auth.getCompanyId());

  name.value = currentCompany.name;
  billingName.value = currentCompany.billingAddress?.name ?? currentCompany.name;
  billingAdditionalInfo.value = currentCompany.billingAddress?.additionalInfo ?? '';
  billingZip.value = currentCompany.billingAddress?.zipCode ?? '';
  billingCity.value = currentCompany.billingAddress?.city ?? '';
  billingStreet.value = currentCompany.billingAddress?.street ?? '';
  billingHouseNumber.value = currentCompany.billingAddress?.houseNumber ?? '';

  return currentCompany;
});

useAppShellBarLoader(isPending);

fetchCompany();

const acceptPartialOrderId = urlParamRef('acceptPartialOrder');
const createPartialOrderSlotId = urlParamRef('createPartialOrder');
const createTruckOfferPartialOrder = urlParamRef('createTruckOfferPartialOrder');
const acceptTruckOfferPartialOrderId = urlParamRef('acceptTruckOfferPartialOrderId');
const tenderId = urlParamRef('tender');
const truckOfferId = urlParamRef('truckOffer');

const router = useRouter();
const {execute: updateCompany, isFetching: loading} = useAsyncAction(async () => {
  if (!name.value || !billingZip.value || !billingCity.value || !billingStreet.value || !billingHouseNumber.value || !currentCompany.value || !billingName.value || (billingAdditionalInfo.value) === undefined) {
    throw 'Undefined Value in UpdateCompany Form';
  }

  await companyService.updateCompany({
    id: currentCompany.value.id,
    name: name.value,
    billingAddress: {
      name: billingName.value,
      additionalInfo: billingAdditionalInfo.value,
      zipCode: billingZip.value,
      city: billingCity.value,
      street: billingStreet.value,
      houseNumber: billingHouseNumber.value,
    },
  });

  let to: RouteLocationRaw = {name: companyRoute.name, params: {companyId: currentCompany.value.id.toString()}};
  if (acceptPartialOrderId.value) {
    to = {name: tenderDetailsRoute.name, params: {id: tenderId.value}, query: {'acceptPartialOrder': acceptPartialOrderId.value}};
  } else if (createPartialOrderSlotId.value) {
    to = {name: tenderDetailsRoute.name, params: {id: tenderId.value}, query: {'createPartialOrder': createPartialOrderSlotId.value}};
  } else if (createTruckOfferPartialOrder.value) {
    to = {name: truckOfferDetailsRoute.name, params: {id: truckOfferId.value}, query: {'createPartialOrder': 1}};
  } else if (acceptTruckOfferPartialOrderId) {
    to = {name: truckOfferDetailsRoute.name, params: {id: truckOfferId.value}, query: {'acceptPartialOrder': acceptTruckOfferPartialOrderId.value}};
  }

  await router.push(to);
});
</script>
