<template>
  <MenuItem
    v-slot="{ active, disabled, close }"
  >
    <a
      :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'w-full group flex items-center px-4 py-2 text-sm']"
      v-bind="$attrs"
      :href="href"
      @click="handleClick($event, close)"
    >
      <slot :disabled="disabled" />
    </a>
  </MenuItem>
</template>

<script setup lang="ts">
import {MenuItem} from '@headlessui/vue';
import {RouteLocationRaw, useLink} from 'vue-router';

interface Props {
  to: RouteLocationRaw
}

const props = defineProps<Props>();

// using useLink instead of a RouterLink here because extending the RouterLink to also close
// the menu is not easy (need to use slot API). With the slot API, the MenuItem does not
// now it is active. So useLink seems cleaner.
const {href, navigate} = useLink({to: props.to});

function handleClick(clickEvent: MouseEvent, closeMenu: () => unknown) {
  closeMenu();
  navigate(clickEvent);
}
</script>
