import {FormContextValidator} from '@app/forms/FormContextContract';
import {Uuid} from '@app/uuid/Uuid';
import {map} from 'lodash';
import {Err, Ok, Result} from '@app/result/Result';

export default class FormContext {
    private validators: Record<string, FormContextValidator> = {};

    registerValidator(id: Uuid, validator: FormContextValidator) {
        this.validators[id.toString()] = validator;
    }

    removeValidator(id: Uuid) {
        delete this.validators[id.toString()];
    }

    async validate(): Promise<Result<unknown, unknown>> {
        const promises = map(this.validators, (validator) => validator());

        const validationResults = await Promise.all(promises);

        const valid = Array.from(validationResults).every((validationResult) => validationResult.isOk);

        if (!valid) {
            return Err.EMPTY;
        }

        return Ok.EMPTY;
    }
}
