import {ConfigurableWindow, useEventListener} from '@vueuse/core';
import {computed, Ref, ref} from 'vue';

export type UrlParams = Record<string, string[] | string>;

export interface UseUrlSearchParamsOptions<T> extends ConfigurableWindow {
    /**
     * @default {}
     */
    initialValue?: T
}

/**
 * Reactive URLSearchParams
 *
 * @param options
 */
export function useUrlSearchParams<T extends Record<string, any> = UrlParams>(
    options: UseUrlSearchParamsOptions<T> = {},
): Ref<T> {
    const {
        initialValue = {},
    } = options;
    const state: Record<string, any> = ref({});
    const urlSearchParams = computed<T>({
        get: () => state.value,
        set: (newValue) => {
            state.value = newValue;

            const params = new URLSearchParams('');
            Object.keys(state.value).forEach((key) => {
                const mapEntry = state.value[key];
                if (Array.isArray(mapEntry))
                    mapEntry.forEach(value => params.append(key, value));
                else
                    params.set(key, mapEntry);
            });
            writeHistory(params);
        },
    });

    function constructQuery(params: URLSearchParams) {
        const stringified = params.toString();

        return `${stringified ? `?${stringified}` : ''}${window.location.hash || ''}`;
    }

    function read() {
        return new URLSearchParams(window.location.search || '');
    }

    function writeState(params: URLSearchParams) {
        const unusedKeys = new Set(Object.keys(state.value));
        for (const key of params.keys()) {
            const paramsForKey = params.getAll(key);

            state.value = {
                ...state.value,
                [key]: paramsForKey.length > 1 ? paramsForKey : (params.get(key) || ''),
            };

            unusedKeys.delete(key);
        }

        const newState = {...state.value};

        Array.from(unusedKeys).forEach(key => delete newState[key]);

        state.value = newState;
    }

    function writeHistory(params: URLSearchParams) {
        window.history.pushState(
            window.history.state,
            window.document.title,
            window.location.pathname + constructQuery(params),
        );
    }


    function onChanged() {
        writeState(read());
    }

    useEventListener(window, 'popstate', onChanged, false);

    const initial = read();
    if (initial.keys().next().value)
        writeState(initial);
    else
        Object.assign(state.value, initialValue);

    return urlSearchParams;
}
