import Rule from '@app/forms/rules/Rule';
import {Err, Ok} from '@app/result/Result';
import Big from 'big.js';

export const positiveDecimal = (decimalPlaces = 2): Rule<unknown> =>
    (val: unknown) => {
        if (typeof val === 'undefined' || (typeof val === 'string' && val === '')) {
            return Ok.EMPTY;
        }

        if (typeof val !== 'string' && typeof val !== 'number') {
            return Err(`Bitte geben Sie eine Zahl mit ${decimalPlaces} Nachkommastellen über 0 an.`);
        }


        let big: Big;
        try {
            big = Big(val);
        } catch (e) {
            return Err('Bitte geben Sie eine Zahl an.');
        }

        if (big.toNumber() <= 0) {
            return Err('Bitte geben Sie eine Zahl über 0 an.');
        }

        const withoutDecimals = big.times(Math.pow(10, decimalPlaces));

        if (Number.isInteger(withoutDecimals.toNumber())) {
            return Ok.EMPTY;
        }

        return Err(`Bitte geben Sie eine Zahl mit ${decimalPlaces} Nachkommastellen an`);
    };
