import {Activity} from '@app/activities/types/Activity';
import {Uuid} from '@app/uuid/Uuid';
import {DateTime} from '@app/time/DateTime';
import {ActivityType} from '@app/activities/ActivityType';
import {User} from '@app/auth/User';
import {ActivityGenerator} from '@app/activities/ActivityGenerator';
import {ActivitiesResponseItem} from '@app/activities/ActivitiesResponsePayload';
import {UuidString} from '@app/uuid/UuidString';

export interface TruckOfferCreatedActivity extends Activity{
    readonly id: Uuid;
    readonly timestamp: DateTime;
    readonly type: ActivityType.TRUCK_OFFER_CREATED;
    readonly user: User;
    readonly truckOfferTitle: string;
    readonly truckOfferId: Uuid;
}

export class TruckOfferCreatedActivityGenerator implements ActivityGenerator<TruckOfferCreatedActivity> {
    generate(data: ActivitiesResponseItem): TruckOfferCreatedActivity {
        return {
            id: Uuid.fromString(data.id),
            timestamp: DateTime.parseIsoUTC(data.activityTimestamp),
            type: ActivityType.TRUCK_OFFER_CREATED,
            user: {
                ...data.triggeredBy,
                id: Uuid.fromString(data.triggeredBy.id),
            },
            truckOfferId: Uuid.fromString(data.activityPayload.truckOfferId as UuidString),
            truckOfferTitle: data.activityPayload.truckOfferTitle as string,
        };
    }

    getType(): ActivityType {
        return ActivityType.TRUCK_OFFER_CREATED;
    }

}
