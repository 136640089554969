import {readonly, Ref, ref, watchEffect} from 'vue';

const isShowLoader = ref(false);

export const useAppShellBarLoader = (newRef?: Ref<boolean>) => {
    if (newRef) {
        watchEffect(() => {
            isShowLoader.value = newRef.value;
        });
    }

    return {
        isShowLoader: readonly(isShowLoader),
    };
};
