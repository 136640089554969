import {HttpErrorWithCode} from '@app/http/HttpErrorWithCode';
import {HttpError} from '@app/http/HttpError';
import {ValidationErrorCode} from '@app/http/ValidationErrorCode';

export class HttpErrorHandler {
    private readonly error: unknown;

    private validationErrorConsumers: Map<ValidationErrorCode, HttpValidationErrorConsumer>;
    private httpErrorConsumer?: HttpErrorConsumer;
    private anyErrorConsumer?: AnyErrorConsumer;

    private constructor(error: unknown) {
        this.validationErrorConsumers = new Map;
        this.error = error;
    }

    static for(error: unknown) {
        return new HttpErrorHandler(error);
    }

    onValidationError(validationErrorCode: ValidationErrorCode, validationErrorConsumer: HttpValidationErrorConsumer) {
        this.validationErrorConsumers?.set(validationErrorCode, validationErrorConsumer);
        return this;
    }

    onHttpError(httpErrorConsumer: HttpErrorConsumer) {
        this.httpErrorConsumer = httpErrorConsumer;
        return this;
    }

    onAnyError(anyErrorConsumer: AnyErrorConsumer) {
        this.anyErrorConsumer = anyErrorConsumer;
        return this;
    }

    /**
     * Execute any matching error handlers
     *
     * @throws {unknown} the original error in case none of the handlers matched
     */
    execute(): void {
        if (import.meta.env.DEV) {
            console.error(this.error);
        }

        if (this.error instanceof HttpError) {
            if (this.error instanceof HttpErrorWithCode && this.validationErrorConsumers.get(this.error.validationErrorCode)) {
                this.validationErrorConsumers.get(this.error.validationErrorCode)!(this.error);
                return;
            }

            if (!(this.error instanceof HttpErrorWithCode) && this.httpErrorConsumer) {
                this.httpErrorConsumer(this.error);
                return;
            }
        }

        if (this.anyErrorConsumer) {
            this.anyErrorConsumer(this.error);
            return;
        }

        console.log(this.error);
        throw this.error;
    }
}

export interface AnyErrorConsumer {
    (e: unknown): void
}

export interface HttpErrorConsumer {
    (e: HttpError): void
}

export interface HttpValidationErrorConsumer {
    (e: HttpErrorWithCode): void
}
