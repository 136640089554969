import {userId} from '@app/auth/Auth';
import {
    fetchActiveTruckOfferFilterFromBackend,
    updateActiveTruckOfferFilterInBackend,
} from '@app/truckoffer/filter/TruckOfferFilterStorage';
import {NotifiableTruckOfferSearchParams} from '@app/truckoffer/search/TruckOfferSearchParams';

export async function fetchActiveTruckOfferFilter(): Promise<NotifiableTruckOfferSearchParams> {
    if (!userId.value) {
        throw 'Cannot fetch filter of unauthenticated user!';
    }

    return fetchActiveTruckOfferFilterFromBackend(userId.value);
}

export async function updateActiveTruckOfferFilter(searchParams: NotifiableTruckOfferSearchParams): Promise<void> {
    if (!userId.value) {
        throw 'Cannot update filter of unauthenticated user!';
    }

    return updateActiveTruckOfferFilterInBackend(userId.value, searchParams);
}
