import httpClient from '@app/http/HttpClient';
import {UuidString} from '@app/uuid/UuidString';
import {DateString} from '@app/support/DateString';
import {Uuid} from '@app/uuid/Uuid';
import {DateTime} from '@app/time/DateTime';

type GetCompaniesCompany = {
    companyId: UuidString,
    name: string,
    createdAt?: DateString
};

export interface GetCompaniesResponsePayload {
    list: GetCompaniesCompany[]
}

interface RegisteredCompany {
    id: Uuid,
    name: string,
    createdAt?: DateTime
}

export async function fetchRegisteredCompanies(): Promise<RegisteredCompany[]> {
    const companiesResponse = await httpClient.get<GetCompaniesResponsePayload>('/secure/reporting/registered-companies/');
    return companiesResponse.data.list.map((company) => ({
        ...company,
        id: Uuid.fromString(company.companyId),
        createdAt: company.createdAt ? DateTime.parseIsoUTC(company.createdAt) : undefined,
    }));
}
