import {Uuid} from '@app/uuid/Uuid';
import {MinimalCompany} from '@app/company/MinimalCompany';
import {DateTime} from '@app/time/DateTime';
import {Money} from '@app/money/Money';

export enum PartialOrderStatus {
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    REVOKED = 'REVOKED',
    ACCEPTED = 'ACCEPTED',
    OUTDATED = 'OUTDATED'
}

export interface PartialOrder {
    id: Uuid,
    message?: string,
    paymentAmount: Money,
    status: PartialOrderStatus,
    contractor: MinimalCompany
    createdAt: DateTime,
    createdBy: TenderPartialOrderCreator
}

export interface TenderPartialOrderCreator {
    id: Uuid,
    firstName: string,
    lastName: string,
    phoneNumber?: string,
    email?: string,
}
