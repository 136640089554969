<template>
  <Dropdown
    v-if="hasAction"
  >
    <template #button>
      <DropdownButton
        :sr-text="$t('tender.page.partialOrders.actions.title')"
        :color-scheme="ButtonColorScheme.TERTIARY"
      >
        {{ $t('tender.page.partialOrders.actions.title') }}
      </DropdownButton>
    </template>
    <div class="py-1">
      <DropdownMenuButton
        v-if="canAccept"
        v-slot="{ disabled }"
        :button-attributes="{'data-cy': 'partial-order-accept-action'}"
        @click="$emit('partial-order-accepted')"
      >
        <HandThumbUpIcon
          :class="['mr-3 h-5 w-5 text-gray-400', disabled ? '' : 'group-hover:text-gray-500']"
          aria-hidden="true"
        />
        {{ $t('tender.page.partialOrders.actions.accept') }}
      </DropdownMenuButton>
      <DropdownMenuButton
        v-if="canReject"
        v-slot="{ disabled }"
        @click="$emit('partial-order-rejected')"
      >
        <XMarkIcon
          :class="['mr-3 h-5 w-5 text-gray-400', disabled ? '' : 'group-hover:text-gray-500']"
          aria-hidden="true"
        />
        {{ $t('tender.page.partialOrders.actions.reject') }}
      </DropdownMenuButton>
      <DropdownMenuButton
        v-if="canConfirm"
        v-slot="{ disabled }"
        @click="$emit('partial-order-confirmed')"
      >
        <CheckIcon
          :class="['mr-3 h-5 w-5 text-gray-400', disabled ? '' : 'group-hover:text-gray-500']"
          aria-hidden="true"
        />
        {{ $t('tender.page.partialOrders.actions.confirm') }}
      </DropdownMenuButton>
      <DropdownMenuButton
        v-if="canRevoke"
        v-slot="{ disabled }"
        @click="$emit('partial-order-revoked')"
      >
        <ArrowLeftOnRectangleIcon
          :class="['mr-3 h-5 w-5 text-gray-400', disabled ? '' : 'group-hover:text-gray-500']"
          aria-hidden="true"
        />
        {{ $t('tender.page.partialOrders.actions.revoke') }}
      </DropdownMenuButton>
    </div>
  </Dropdown>
</template>

<script lang="ts" setup>
import {computed, toRefs} from 'vue';
import {ArrowLeftOnRectangleIcon, CheckIcon, HandThumbUpIcon, XMarkIcon} from '@heroicons/vue/20/solid';
import Dropdown from '@app/support/dropdown/Dropdown.vue';
import DropdownButton from '@app/support/dropdown/DropdownButton.vue';
import DropdownMenuButton from '@app/support/dropdown/DropdownMenuButton.vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';

interface Props {
  canAccept: boolean
  canReject: boolean
  canRevoke: boolean
  canConfirm: boolean
}

const props = defineProps<Props>();

defineEmits<{
  (e: 'partial-order-rejected'): void,
  (e: 'partial-order-accepted'): void,
  (e: 'partial-order-revoked'): void,
  (e: 'partial-order-confirmed'): void,
}>();

const {canAccept, canReject, canRevoke, canConfirm} = toRefs(props);

const hasAction = computed(() => canAccept.value || canReject.value || canRevoke.value || canConfirm.value);
</script>
