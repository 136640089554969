<template>
  <MenuButton
    :class="commonClasses"
  >
    <span class="flex items-center">
      <slot name="prefix" />

      <slot />
      <ChevronDownIcon
        class="h-5 w-5 -mr-1"
        aria-hidden="true"
      />
    </span>
    <span class="sr-only">{{ srText }}</span>
  </MenuButton>
</template>

<script setup lang="ts">
import {ChevronDownIcon} from '@heroicons/vue/20/solid';
import {MenuButton} from '@headlessui/vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import {useButton} from '@app/support/useButton';
import {ref, toRefs} from 'vue';

interface Props {
  srText: string,
  colorScheme?: ButtonColorScheme
}

const props = withDefaults(defineProps<Props>(), {
  colorScheme: ButtonColorScheme.PRIMARY,
});

const { colorScheme } = toRefs(props);

const {commonClasses} = useButton(colorScheme, ref(false));
</script>
