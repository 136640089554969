import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import {computed, Ref} from 'vue';

const colorSchemeClasses: Record<ButtonColorScheme, string> = {
    [ButtonColorScheme.PRIMARY]: 'border-transparent bg-primary-600 hover:bg-primary-700 focus:ring-primary-500',
    [ButtonColorScheme.SECONDARY]: 'border-transparent bg-primary-100 hover:bg-primary-200 focus:ring-primary-500',
    [ButtonColorScheme.TERTIARY]: 'border-gray-300 bg-white hover:bg-gray-50 focus:ring-primary-500',
    [ButtonColorScheme.DANGER]: 'border-transparent bg-red-100 hover:bg-red-200 focus:ring-red-500',
};

const colorSchemeTextColors: Record<ButtonColorScheme, string> = {
    [ButtonColorScheme.PRIMARY]: 'text-white',
    [ButtonColorScheme.SECONDARY]: 'text-primary-700',
    [ButtonColorScheme.TERTIARY]: 'text-black',
    [ButtonColorScheme.DANGER]: 'text-red-700',
};

export function useButton(colorScheme: Ref<ButtonColorScheme>, disabled: Ref<boolean>) {
    const textColor = computed(() => colorSchemeTextColors[colorScheme.value]);

    const commonClasses = computed(() => {
        const baseClasses = 'flex items-center justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2';

        if (disabled.value) {
            return `${baseClasses} border-gray-300 bg-gray-300 text-gray-700`;
        }

        return `${baseClasses} ${colorSchemeClasses[colorScheme.value]} ${textColor.value}`;
    });

    return {
        textColor,
        commonClasses,
    };
}
