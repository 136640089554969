import {ActivitiesData, ActivitiesServiceContract} from '@app/activities/ActivitiesServiceContract';
import {Activity} from '@app/activities/types/Activity';
import {ResponseContract} from '@app/http/ResponseContract';
import {ActivitiesResponseItem, ActivitiesResponsePayload} from '@app/activities/ActivitiesResponsePayload';
import httpClient from '@app/http/HttpClient';
import {ActivityGenerator} from '@app/activities/ActivityGenerator';
import {OrderAcceptedActivityGenerator} from '@app/activities/types/OrderAccepted/OrderAcceptedActivity';
import {OrderCreatedActivityGenerator} from '@app/activities/types/OrderCreated/OrderCreatedActivity';
import {TenderCreatedActivityGenerator} from '@app/activities/types/TenderCreated/TenderCreatedActivity';
import {TenderCanceledActivityGenerator} from '@app/activities/types/TenderCanceled/TenderCanceledActivity';
import {CompanyCreatedActivityGenerator} from '@app/activities/types/CompanyCreated/CompanyCreatedActivity';
import {OrderRejectedActivityGenerator} from '@app/activities/types/OrderRejected/OrderRejectedActivity';
import {UserInvitedActivityGenerator} from '@app/activities/types/UserInvited/UserInvitedActivity';
import {RequestConfigContract} from '@app/http/RequestConfigContract';
import {OrderRevokedActivityGenerator} from '@app/activities/types/OrderRevoked/OrderRevokedActivity';
import {OrderOutdatedActivityGenerator} from '@app/activities/types/OrderOutdated/OrderOutdatedActivity';
import {TruckOfferCanceledActivityGenerator} from '@app/activities/types/TruckOfferCanceled/TruckOfferCanceledActivity';
import {TruckOfferCreatedActivityGenerator} from '@app/activities/types/TruckOfferCreated/TruckOfferCreatedActivity';
import {TruckOfferOrderCreatedActivityGenerator} from '@app/activities/types/TruckOfferOrderCreated/TruckOfferOrderCreatedActivity';
import {TruckOfferOrderRevokedActivityGenerator} from '@app/activities/types/TruckOfferOrderRevoked/TruckOfferOrderRevokedActivity';
import {TruckOfferOrderAcceptedActivityGenerator} from '@app/activities/types/TruckOfferOrderAccepted/TruckOfferOrderAcceptedActivity';
import {TruckOfferOrderRejectedActivityGenerator} from '@app/activities/types/TruckOfferOrderRejected/TruckOfferOrderRejectedActivity';
import {
    TruckOfferOrderOutdatedActivityGenerator,
} from '@app/activities/types/TruckOfferOrderOutdated/TruckOfferOrderOutdatedActivity';

const generators: ActivityGenerator<Activity>[] = [
    new OrderAcceptedActivityGenerator,
    new OrderRejectedActivityGenerator,
    new OrderCreatedActivityGenerator,
    new OrderRevokedActivityGenerator,
    new OrderOutdatedActivityGenerator,
    new UserInvitedActivityGenerator,
    new TenderCreatedActivityGenerator,
    new TenderCanceledActivityGenerator,
    new CompanyCreatedActivityGenerator,
    new TruckOfferCanceledActivityGenerator,
    new TruckOfferCreatedActivityGenerator,
    new TruckOfferOrderCreatedActivityGenerator,
    new TruckOfferOrderRevokedActivityGenerator,
    new TruckOfferOrderAcceptedActivityGenerator,
    new TruckOfferOrderRejectedActivityGenerator,
    new TruckOfferOrderOutdatedActivityGenerator,
];

class ActivitiesService implements ActivitiesServiceContract {
    async getAll(page: number, pageSize = 20): Promise<ResponseContract<ActivitiesData>> {
        const requestConfig: RequestConfigContract = {
            params: {
                page: String(page),
                'page-size': String(pageSize),
            },
        };
        const rawResponse: ResponseContract<ActivitiesResponsePayload> = await httpClient.get<ActivitiesResponsePayload>('/secure/activities/', requestConfig);
        return {
            ...rawResponse,
            data: {
                items: this.mapActivities(rawResponse.data.items),
                totalItems: rawResponse.data.totalItems,
            },
        };
    }

    private mapActivities(items: ActivitiesResponseItem[]) {
        const mappedItems: Activity[] = [];
        for (const activity of items) {
            const generator = generators.find((generator) => generator.getType() === activity.activityType);

            if (!generator) {
                if (import.meta.env.DEV) {
                    throw `Unknown activity type ${activity.activityType}`;
                }
                continue;
            }

            mappedItems.push(generator.generate(activity));
        }
        return mappedItems;
    }
}

export const activitiesService = new ActivitiesService() as ActivitiesServiceContract;

