import {DateTime as LuxonDateTime, Zone} from 'luxon';
import Time from '@app/time/Time';
import Calendar from '@app/time/Calendar';
import {InvalidDateTimeError} from '@app/time/InvalidDateTimeError';
import {TimeZonesDoNotMatch} from '@app/time/TimeZonesDoNotMatch';

type TimeStampInSeconds = number;

export class DateTime {
    static readonly MINUTES_IN_HOUR: Minutes = 60;
    private internalDateTime: LuxonDateTime;

    public get year(): number {
        return this.internalDateTime.year;
    }

    public get month(): number {
        return this.internalDateTime.month;
    }

    public get day(): number {
        return this.internalDateTime.day;
    }

    public get hour(): number {
        return this.internalDateTime.hour;
    }

    public get minute(): number {
        return this.internalDateTime.minute;
    }

    public get zone(): Zone {
        return this.internalDateTime.zone;
    }

    /**
     * @throws InvalidDateTimeError
     */
    private constructor(dateTime: LuxonDateTime) {
        this.internalDateTime = dateTime;
        if (!dateTime.isValid) {
            throw new InvalidDateTimeError(`Not a valid DateTime (${dateTime.invalidReason}).`);
        }
    }

    public get timestamp(): TimeStampInSeconds {
        return this.internalDateTime.toSeconds();
    }

    /**
     * @see https://moment.github.io/luxon/#/formatting?id=toformat
     */
    public toFormat(format: string): string {
        return this.internalDateTime.toFormat(format);
    }

    /**
     * @see https://moment.github.io/luxon/#/formatting?id=tolocalestring-strings-for-humans
     */
    private toHuman(options?: Intl.DateTimeFormatOptions): string {
        const systemDateTime = this.toSystem().internalDateTime;
        return systemDateTime.toLocaleString(options);
    }

    /**
     * @see https://moment.github.io/luxon/#/formatting?id=tolocalestring-strings-for-humans
     */
    public toHumanDate(): string {
        return this.toHuman({
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });
    }

    public toHumanMonthYear() {
        return this.toHuman({
            year: 'numeric',
            month: 'long',
        });
    }

    public toRelative() {
        return this.internalDateTime.toRelative();
    }

    /**
     * @see https://moment.github.io/luxon/#/formatting?id=tolocalestring-strings-for-humans
     */
    public toHumanTime(): string {
        return this.toHuman({
            hour: 'numeric',
            minute: '2-digit',
        });
    }

    /**
     * @see https://moment.github.io/luxon/#/formatting?id=tolocalestring-strings-for-humans
     */
    public toHumanFull(): string {
        return this.toHuman({
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
    }

    /**
     * @throws InvalidDateTimeError
     */
    public static parseIsoUTC(isoDate: string): DateTime {
        const luxonDateTime = LuxonDateTime.fromISO(isoDate, { zone: 'UTC' });
        return new DateTime(luxonDateTime);
    }

    /**
     * @throws InvalidDateTimeError
     */
    public static parseIsoSystem(isoDate: string): DateTime {
        const luxonDateTime = LuxonDateTime.fromISO(isoDate);
        return new DateTime(luxonDateTime);
    }

    public static now(): DateTime {
        return new DateTime(LuxonDateTime.utc());
    }

    public static fromDateAndTime(date: Calendar, time: Time): DateTime {
        if (!date.zone.equals(time.zone)) {
            throw new TimeZonesDoNotMatch('Date and time zones do not match.');
        }

        const luxonDateTime = LuxonDateTime.fromISO(`${date.toISO()}T${time.toISO()}`, {zone: date.zone});
        return new DateTime(luxonDateTime);
    }

    toISODate() {
        return this.internalDateTime.toISODate() ?? undefined;
    }

    toISO() {
        const toISO = this.internalDateTime.toISO();
        if (!toISO) {
            // should not happen as we check for validity in constructor
            throw 'Invalid Datetime';
        }
        return toISO;
    }

    valueOf(): number {
        return this.internalDateTime.valueOf();
    }

    isBefore(after: DateTime) {
        return this < after;
    }

    addDays(daysToAdd: number) {
        return new DateTime(this.internalDateTime.plus({day: daysToAdd}));
    }

    subtractDays(days: number) {
        return new DateTime(this.internalDateTime.minus({day: days}));
    }

    withHour(hour: number): DateTime {
        const luxonDateTime = this.internalDateTime.set({hour});
        return new DateTime(luxonDateTime);
    }

    withMinute(minute: number): DateTime {
        const luxonDateTime = this.internalDateTime.set({minute});
        return new DateTime(luxonDateTime);
    }

    toJSDate() {
        return this.internalDateTime.toJSDate();
    }

    toUTC() {
        return new DateTime(this.internalDateTime.toUTC());
    }

    toSystem() {
        return new DateTime(this.internalDateTime.setZone('system'));
    }
}

export type Minutes = number;
