<template>
  <TrLabeledSelect
    :model-value="modelValue"
    :items="availableTruckFeatures"
    :label="label"
    :rules="rules"
    @update:model-value="$emit('update:model-value', $event as typeof modelValue)"
  />
</template>

<script lang="ts" setup>
import {mapValues, toPairs} from 'lodash';
import {useI18n} from 'vue-i18n';
import TrLabeledSelect from '@app/forms/LabeledSelect.vue';
import Rule from '@app/forms/rules/Rule';
import {TruckFeature, truckFeatures} from '@app/tender/trucks/TruckFeature';

interface Props {
  modelValue: TruckFeature[],
  label: string,
  rules?: Rule<unknown>[]
}

withDefaults(defineProps<Props>(), {
  rules: () => [],
});

interface Events {
  (e: 'update:model-value', value: TruckFeature[]): void
}

defineEmits<Events>();

const {t} = useI18n();

const availableTruckFeatures = new Map(
    toPairs(
        mapValues(truckFeatures, (type: TruckFeature) => t(`tender.truckFeature.${type}`)),
    ),
);
</script>
