import {BillStatus} from '@app/billing/BillStatus';
import {VueI18nTranslation} from 'vue-i18n';

export const evaluateStatusText = (t: VueI18nTranslation, status?: BillStatus) => {
    if (!status) {
        return null;
    }

    if (status === BillStatus.ACCOUNTED) {
        return t('billing.bills.statuses.accounted');
    }

    if (status === BillStatus.DRAFT) {
        return t('billing.bills.statuses.draft');
    }

    if (status === BillStatus.OPEN) {
        return t('billing.bills.statuses.open');
    }

    throw new Error(`Unknown BillStatus ${status}`);
};
