import {Ref} from 'vue';
import {LinkColorScheme} from '@app/navigation/LinkColorScheme';

export function useLink(colorScheme: Ref<LinkColorScheme>) {
    return {
        classObject: {
            'focus:outline-none focus:ring-2 focus:ring-primary-500': true,
            'text-primary-600 hover:text-primary-500': colorScheme.value === LinkColorScheme.PRIMARY,
        },
    };
}
