import {LocationProviderContract} from '@app/location/LocationProviderContract';
import keyBy from 'lodash/keyBy';
import {LocationDetails, LocationOption} from '@app/forms/Location';
import {Err, Ok, Result} from '@app/result/Result';

class GoogleMapsLocationProvider implements LocationProviderContract {
    private sessionToken?: google.maps.places.AutocompleteSessionToken;
    private autocompleteService?: google.maps.places.AutocompleteService;
    private placesService?: google.maps.places.PlacesService;
    private geoCoder?: google.maps.Geocoder;

    getScriptUrl(): string {
        return 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBU_m1nCT17nqw36CpOmQ4AWCe-USl4agk&callback=googleMapsInitialized';
    }

    init() {
        this.sessionToken = GoogleMapsLocationProvider.generateSessionToken();
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
        this.geoCoder = new google.maps.Geocoder();
    }

    async positionToLocationOption(latitude: number, longitude: number): Promise<Result<LocationDetails, unknown>> {
        if (!this.geoCoder) {
            return Err('GeoCoder not initialized.');
        }

        let geoCode;
        try {
            geoCode = await this.geoCoder?.geocode({
                language: 'de',
                location: {
                    lat: latitude,
                    lng: longitude,
                },
            });
        } catch (e) {
            return Err(e);
        }

        const firstResult = geoCode.results[0];
        return Ok({
            externalId: firstResult.place_id,
            displayName: firstResult.formatted_address,
            longitude: longitude,
            latitude: latitude,
        });
    }

    async fetchLocationOptionsByQuery(locationQuery: string) {
        if (!locationQuery) {
            return {};
        }

        const autocompleteResponse = await this.autocompleteService?.getPlacePredictions({
            componentRestrictions: {
                country: ['de'],
            },
            input: locationQuery,
            sessionToken: this.sessionToken,
        });

        return autocompleteResponse?.predictions ? keyBy(autocompleteResponse.predictions, (prediction) => prediction.place_id) : {};
    }

    async getDetailsOfLocationOption(locationOption: LocationOption): Promise<LocationDetails> {
        return new Promise((resolve) => {
            (this.placesService)!.getDetails({
                placeId: locationOption.place_id,
                fields: ['geometry.location'],
            }, (result) => {
                resolve({
                    externalId: locationOption.place_id,
                    latitude: result!.geometry!.location!.lat(),
                    longitude: result!.geometry!.location!.lng(),
                    displayName: locationOption.description,
                });

                this.sessionToken = GoogleMapsLocationProvider.generateSessionToken();
            });
        });
    }

    private static generateSessionToken() {
        return new google.maps.places.AutocompleteSessionToken();
    }

    async fetchLocationOptionByDetails(value: LocationDetails): Promise<LocationOption | undefined> {
        return {
            place_id: value.externalId,
            description: value.displayName,
        };
    }
}

export const googleMapsLocationProvider = new GoogleMapsLocationProvider as LocationProviderContract;
