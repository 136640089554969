import {RouteRecordRaw} from 'vue-router';
import Company from '@app/company/Company.vue';
import UserDetails from '@app/company/UserDetails.vue';
import UpdateUser from '@app/company/UpdateUser.vue';
import UpdateCompany from '@app/company/edit/UpdateCompany.vue';

export const companyRoute: RouteRecordRaw = {
    path: '/company',
    component: Company,
    name: 'Company',
};

export const updateCompanyRoute: RouteRecordRaw = {
    path: '/company/:companyId',
    name: 'UpdateCompany',
    component: UpdateCompany,
};

export const userDetailsRoute: RouteRecordRaw = {
    path: '/company/users/:userId/details/:tab?',
    name: 'UserDetails',
    component: UserDetails,
};

export const updateUserRoute: RouteRecordRaw = {
    path: '/company/users/:userId/update',
    name: 'UpdateUser',
    component: UpdateUser,
};

export const companyRoutes = [
    companyRoute,
    userDetailsRoute,
    updateUserRoute,
    updateCompanyRoute,
];
