<template>
  <TrLabeledSelect
    :model-value="modelValue"
    :items="availableTenderRoles"
    :rules="rules"
    :label="label"
    @update:model-value="$emit('update:model-value', $event as typeof modelValue)"
  />
</template>

<script lang="ts" setup>
import {mapValues, toPairs} from 'lodash';
import {TenderRole, tenderRoles} from '@app/tender/TenderRole';
import {useI18n} from 'vue-i18n';
import TrLabeledSelect from '@app/forms/LabeledSelect.vue';
import Rule from '@app/forms/rules/Rule';

interface Props {
  modelValue: TenderRole,
  rules?: Rule<unknown>[],
  label: string
}

defineProps<Props>();

interface Events {
  (e: 'update:model-value', value: TenderRole): void
}

defineEmits<Events>();

const {t} = useI18n();

const availableTenderRoles = new Map(
    toPairs(
        mapValues(tenderRoles, (role: TenderRole) => t(`tender.role.${role}`)),
    ),
);
</script>
