import {UuidString} from '@app/uuid/UuidString';
import {validate} from 'uuid';

export class Uuid {
    private readonly value: UuidString;

    private constructor(value: string) {
        this.value = value;
    }

    public static fromString(value: string) {
        if (!validate(value)) {
            throw new InvalidUuidError('Invalid UUID given');
        }
        return new Uuid(value);
    }

    public toString(): UuidString {
        return this.value;
    }

    public equals(other?: Uuid): boolean {
        if (!other) {
            return false;
        }
        return this.value === other.value;
    }
}

export class InvalidUuidError extends Error {}
