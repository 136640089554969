<template>
  <TrSection
    v-if="!isPendingCompany && companyTruckOffers"
    label-id="company-truck-offers"
    with-bottom
    class="relative overflow-hidden"
  >
    <template #top>
      <div class="absolute w-full rounded-t-md overflow-hidden">
        <BarLoader v-if="isFetchingCompany" />
      </div>
    </template>

    <template #head>
      <div class="flex justify-between space-y-6 lg:space-y-0 lg:items-center flex-col lg:flex-row">
        <div>
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            {{ $t('truckOffer.myCompany.head') }}
          </h3>
          <p class="max-w-4xl text-sm text-gray-500">
            {{ $t('truckOffer.myCompany.sub') }}
          </p>
        </div>

        <div class="flex lg:space-x-6 space-y-4 lg:space-y-0 flex-col lg:flex-row">
          <TrSwitch
            v-model="showFutureCompanyTruckOffers"
            @update:model-value="reFetchCompanyTruckOffers"
          >
            {{ $t('truckOffer.showFuture') }}
          </TrSwitch>
        </div>
      </div>
    </template>

    <NoData
      v-if="companyTruckOffers.length === 0"
      class="my-6"
    >
      <div class="flex flex-col items-center">
        <p>{{ $t('truckOffer.myCompany.noData') }}</p>
        <p
          v-if="showFutureCompanyTruckOffers"
          class="text-gray-500"
        >
          {{ $t('truckOffer.myCompany.showFutureFilter') }}
        </p>
      </div>
    </NoData>

    <TruckOfferList
      v-else
      :truck-offers="companyTruckOffers"
    />

    <template #bottom>
      <PaginationFooter
        :entity-label="$t('shell.navigation.myTruckOffers')"
        :offset-pagination-data="companyPaginationData"
        :loading="isFetchingCompany"
        @change-page="fetchCompanyPage"
      />
    </template>
  </TrSection>

  <TrSection
    v-if="!isPendingPartialOrder && partialOrderTruckOffers"
    class="relative overflow-hidden"
    label-id="company-partial-orders"
    with-bottom
  >
    <template #top>
      <div class="absolute w-full rounded-t-md overflow-hidden">
        <BarLoader v-if="isFetchingPartialOrder" />
      </div>
    </template>

    <template #head>
      <div class="flex justify-between space-y-6 lg:space-y-0 lg:items-center flex-col lg:flex-row">
        <div>
          <h3
            id="company-partial-orders"
            class="text-base font-semibold leading-6 text-gray-900"
          >
            {{ $t('truckOffer.companyPartialOrder.head') }}
          </h3>
          <p class="max-w-4xl text-sm text-gray-500">
            {{ $t('truckOffer.companyPartialOrder.sub') }}
          </p>
        </div>

        <div class="flex lg:space-x-6 space-y-4 lg:space-y-0 flex-col lg:flex-row">
          <TrSwitch
            v-model="showFuturePartialOrderTruckOffers"
            @update:model-value="reFetchPartialOrderTruckOffers"
          >
            {{ $t('truckOffer.showFuture') }}
          </TrSwitch>
        </div>
      </div>
    </template>

    <NoData
      v-if="partialOrderTruckOffers.length === 0"
      class="my-6"
    >
      <div class="flex flex-col items-center">
        <p>{{ $t('truckOffer.myCompany.noData') }}</p>
        <p
          v-if="showFutureCompanyTruckOffers"
          class="text-gray-500"
        >
          {{ $t('truckOffer.myCompany.showFutureFilter') }}
        </p>
      </div>
    </NoData>

    <TruckOfferList
      v-else
      :truck-offers="partialOrderTruckOffers"
    />

    <template #bottom>
      <PaginationFooter
        :entity-label="$t('shell.navigation.myTruckOffers')"
        :offset-pagination-data="partialOrderPaginationData"
        :loading="isFetchingPartialOrder"
        @change-page="fetchPartialOrderPage"
      />
    </template>
  </TrSection>
</template>

<script lang="ts" setup>
import auth from '@app/auth/Auth';
import {computed, ref} from 'vue';
import NoData from '@app/support/NoData.vue';
import TrSection from '@app/support/TrSection.vue';
import {useOffsetPagination} from '@app/pagination/useOffsetPagination';
import BarLoader from '@app/loader/BarLoader.vue';
import PaginationFooter from '@app/pagination/PaginationFooter.vue';
import TrSwitch from '@app/support/TrSwitch.vue';
import clock from '@app/time/Clock';
import {useLocalStorage} from '@vueuse/core';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import {searchTruckOffers} from '@app/truckoffer/truckOfferService';
import {useQuery} from '@app/http/useQuery';
import {TruckOfferFilters} from '@app/truckoffer/search/TruckOfferFilters';
import TruckOfferList from '@app/truckoffer/TruckOfferList.vue';

const showFutureCompanyTruckOffers = useLocalStorage('show-future-truck-offers-from-company', false);
const showFuturePartialOrderTruckOffers = useLocalStorage('show-future-truck-offers-with-partial-orders-from-company', false);

const pageSize = ref(5);

const companyTruckOffers = computed(() => companySearchResult.value?.items);
const companyTruckOffersCount = computed(() => companyTruckOffers.value?.length ?? 0);

const companyPaginationData = useOffsetPagination({
  total: companyTruckOffersCount,
  pageSize: pageSize,
});

const {
  reFetch: reFetchCompanyTruckOffers,
  isPending: isPendingCompany,
  isFetching: isFetchingCompany,
  data: companySearchResult,
} = useQuery(async () => {
  const filterParameter: TruckOfferFilters = {
    companyId: auth.getCompanyId().toString(),
  };

  if (showFutureCompanyTruckOffers.value) {
    filterParameter.fromDateTime = clock.now()
        .withHour(0)
        .withMinute(0);
  }

  return await searchTruckOffers(filterParameter, companyPaginationData.currentPage.value, pageSize.value);
});

function fetchCompanyPage(page: number) {
  companyPaginationData.currentPage.value = page;
  reFetchCompanyTruckOffers();
}

const partialOrderTruckOffers = computed(() => partialOrderSearchResult.value?.items);
const partialOrderTruckOffersCount = computed(() => partialOrderTruckOffers.value?.length ?? 0);

const partialOrderPaginationData = useOffsetPagination({
  total: partialOrderTruckOffersCount,
  pageSize: pageSize,
});

const {
  reFetch: reFetchPartialOrderTruckOffers,
  isPending: isPendingPartialOrder,
  isFetching: isFetchingPartialOrder,
  data: partialOrderSearchResult,
} = useQuery(async () => {
  const filterParameter: TruckOfferFilters = {hasPartialOrdersFromContractor: auth.getCompanyId().toString()};

  if (showFuturePartialOrderTruckOffers.value) {
    filterParameter.fromDateTime = filterParameter.fromDateTime = clock.now()
        .withHour(0)
        .withMinute(0);
  }

  return searchTruckOffers(filterParameter, partialOrderPaginationData.currentPage.value, pageSize.value);
});

function fetchPartialOrderPage(page: number) {
  partialOrderPaginationData.currentPage.value = page;
  reFetchPartialOrderTruckOffers();
}

const isPending = computed(() => isPendingPartialOrder.value || isPendingCompany.value);
useAppShellBarLoader(isPending);
</script>
