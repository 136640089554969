<template>
  <div class="flex flex-1 items-center justify-between">
    <div
      :class="{
        'invisible': true,
        'sm:visible': total > 0
      }"
      data-cy="pagination-footer-text"
    >
      <p
        class="text-sm text-gray-700"
      >
        {{ entityLabel }}
        <span class="font-medium">{{ pageStartItemNumber }}</span>
        {{ $t('pagination.to') }}
        <span class="font-medium">{{ pageEndItemNumber }}</span>
        {{ $t('pagination.of') }}
        <span class="font-medium">{{ total }}</span>
      </p>
    </div>
    <div>
      <nav
        class="isolate inline-flex -space-x-px rounded-md shadow-sm"
        aria-label="Pagination"
      >
        <PageButton
          narrow
          class="rounded-l-md"
          :inactive="previous === undefined || loading"
          data-cy="pagination-footer-button-back"
          @click.prevent="changePage(currentPage - 1)"
        >
          <span class="sr-only">Previous</span>
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clip-rule="evenodd"
            />
          </svg>
        </PageButton>
        <PageButton
          v-if="pageCount > 2"
          :current="currentPage === 1"
          :inactive="loading"
          data-cy="pagination-footer-button-1"
          @click.prevent="changePage(1)"
        >
          1
        </PageButton>
        <PageButton
          v-if="pageCount > 3"
          :current="currentPage === 2"
          :inactive="isInMiddle || loading"
          :data-cy="`pagination-footer-button-${isInMiddle ? 'ellipse' : 2}`"
          @click.prevent="changePage(2)"
        >
          {{ isInMiddle ? '...' : 2 }}
        </PageButton>
        <PageButton
          v-if="pageCount > 4"
          :inactive="!isInMiddle || loading"
          :current="isInMiddle"
          :data-cy="`pagination-footer-button-${isInMiddle ? currentPage : 'ellipse'}`"
        >
          {{ isInMiddle ? currentPage : '...' }}
        </PageButton>
        <PageButton
          v-if="pageCount > 1"
          :inactive="isInMiddle || loading"
          :current="currentPage === pageCount-1"
          :data-cy="`pagination-footer-button-${isInMiddle ? 'ellipse' : pageCount-1}`"
          @click.prevent="changePage(pageCount - 1)"
        >
          {{ isInMiddle ? '...' : pageCount - 1 }}
        </PageButton>
        <PageButton
          :current="currentPage === pageCount"
          :inactive="loading"
          :data-cy="`pagination-footer-button-${pageCount}`"
          @click.prevent="changePage(pageCount)"
        >
          {{ pageCount }}
        </PageButton>
        <PageButton
          narrow
          :inactive="next === undefined || loading"
          class="rounded-r-md"
          @click.prevent="changePage(currentPage + 1)"
        >
          <span class="sr-only">Next</span>
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            data-cy="pagination-footer-button-next"
          >
            <path
              fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd"
            />
          </svg>
        </PageButton>
      </nav>
    </div>
  </div>
</template>
<script setup lang="ts">
import PageButton from '@app/pagination/PageButton.vue';
import {OffsetPaginationData} from '@app/pagination/useOffsetPagination';
import {computed} from 'vue';

interface Props {
    offsetPaginationData: OffsetPaginationData
    loading: boolean
    entityLabel: string
}

interface Events {
    (e: 'change-page', v: number): void
}

const props = defineProps<Props>();

const emit = defineEmits<Events>();

const {
    pageStartItemNumber,
    pageEndItemNumber,
    previous,
    next,
    pageCount,
    currentPage,
    total,
} = props.offsetPaginationData;

// we show 2 page buttons on each size. if we have >4 pages we need to show ... buttons, so if the current page
// is "in the middle", where the three dots button is supposed to be, we need to rearrange the buttons
const isInMiddle = computed(() => currentPage.value > 2 && currentPage.value < (pageCount.value - 1));

function changePage(newPage: number) {
    if (currentPage.value === newPage) {
        return;
    }
    emit('change-page', newPage);
}
</script>
