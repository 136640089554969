import {UuidString} from '@app/uuid/UuidString';
import type {DateString} from '@app/support/DateString';
import {LocationDetails} from '@app/forms/Location';
import {TruckOfferParticipant} from '@app/truckoffer/TruckOfferParticipant';
import {TimeString} from '@app/support/TimeString';
import {TruckType} from '@app/tender/trucks/TruckType';
import {TruckBedType} from '@app/tender/trucks/TruckBedType';
import {TruckFeature} from '@app/tender/trucks/TruckFeature';
import {Err, Ok, Result} from '@app/result/Result';
import {Uuid} from '@app/uuid/Uuid';
import httpClient from '@app/http/HttpClient';
import {TruckOfferStatus} from '@app/truckoffer/TruckOfferStatus';
import {TruckOfferRole} from '@app/truckoffer/TruckOfferRole';
import {TruckOffer} from '@app/truckoffer/TruckOffer';
import Time from '@app/time/Time';
import Calendar from '@app/time/Calendar';
import {TruckOfferContactPerson} from '@app/truckoffer/TruckOfferContactPerson';
import {Currency} from '@app/money/Currency';
import {Money} from '@app/money/Money';
import {PaymentScheme} from '@app/tender/PaymentScheme';
import {
    TruckOfferPartialOrderStatus, truckOfferPartialOrderStatuses,
} from '@app/truckoffer/TruckOfferPartialOrderStatus';
import {DateTime} from '@app/time/DateTime';
import {VerifyCostActionInfo} from '@app/tender/VerifyCostActionInfo';
import {PartialOrderStatus} from '@app/tender/PartialOrder';
import {ResponseContract} from '@app/http/ResponseContract';
import {mapMinimalCompany, MinimalCompanyResponse} from '@app/company/MinimalCompany';
import {TruckOfferFilters} from '@app/truckoffer/search/TruckOfferFilters';
import {VerifyCostActionResponsePayload} from '@app/tender/TenderService';
import {MinimalTruckOffer} from '@app/truckoffer/MinimalTruckOffer';

export type CreateTruckOfferRequestPayload = {
    companyId: UuidString,
    participants: TruckOfferParticipant[]
    title: string,
    description: string,
    dateOfExecution: DateString,
    timeFrom: TimeString,
    timeTo: TimeString,
    payPerHour: boolean,
    payPerHourAmount?: number,
    payPerTon: boolean,
    payPerTonAmount?: number,
    location: LocationDetails,
    maxRadius: number,
    truckType: TruckType,
    truckBedType: TruckBedType,
    truckFeatures: TruckFeature[]
};

export type TruckOfferFetchResponsePayload = {
    id: UuidString,
    company: MinimalCompanyResponse,
    description: string,
    dateOfExecution: DateString,
    timeFrom: TimeString,
    timeTo: TimeString,
    payPerHour: boolean,
    payPerHourAmount: number,
    payPerTon: boolean,
    payPerTonAmount: number,
    participants: {
        user: {
            id: UuidString,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string
        },
        truckOfferRole: TruckOfferRole
    }[],
    partialOrders: {
        id: UuidString,
        message: string,
        status: TruckOfferPartialOrderStatus,
        createdAt: DateString,
        paymentAmount: number,
        paymentScheme: PaymentScheme,
        contractor?: MinimalCompanyResponse
    }[],
    title: string,
    status: TruckOfferStatus,
    location: {
        displayName: string,
        latitude: number,
        longitude: number,
        externalId: string
    },
    maxRadius: number,
    truckType: TruckType,
    truckBedType: TruckBedType,
    truckFeatures: TruckFeature[],
    createdBy: UuidString,
    canAddPartialOrder: boolean
};

interface CreateOrderRequestPayload {
    contractorId: UuidString,
    message?: string,
    paymentScheme: PaymentScheme,
    paymentAmount: number,
}

export interface TruckOfferSearchResponsePayload {
    items: TruckOfferSearchResponsePayloadItem[],
    totalItems: number
}


export interface TruckOfferSearchResponsePayloadItem {
    id: UuidString,
    title: string,
    status: TruckOfferStatus
    truckType: TruckType
    truckBedType: TruckBedType
    truckFeatures: TruckFeature[]
    dateOfExecution: DateString,
    locationDisplayName: string,
}

interface TruckOfferSearchResult {
    items: TruckOfferSearchResultItem[],
    totalItems: number
}

interface TruckOfferSearchRequestPayload {
    companyId?: UuidString,
    /** greater than */
    dateOfExecutionGt?: DateString,
    /** later than */
    dateOfExecutionLt?: DateString,
    page?: number,
    pageSize?: number,
    locationRadius?: number,
    location?: {
        latitude?: number,
        longitude?: number,
    },
    hasPartialOrdersFromContractor?: UuidString,
    truckTypes?: TruckType[]
}

interface TruckOfferLookupResponsePayload {
    mappings: Record<UuidString, UuidString>
    truckOffers: TenderLookupResponseMinimalTruckOffer[]
}

interface TruckOfferLookupRequestPayload {
    partialOrderIds: UuidString[]
}

interface TenderLookupResponseMinimalTruckOffer {
    id: UuidString,
    title: string,
}

export interface TruckOfferSearchResultItem extends Omit<TruckOfferSearchResponsePayloadItem, 'id' | 'dateOfExecution'> {
    id: Uuid,
    dateOfExecution: Calendar,
}

interface TruckOfferListResponsePayload {
    name: string
    items: TruckOfferSearchResponsePayloadItem[]
}

interface TruckOfferList {
    name: string,
    items: TruckOfferSearchResultItem[]
}

export async function createTruckOffer(payload: CreateTruckOfferRequestPayload): Promise<Result<Uuid, unknown>> {
    try {
        const res = await httpClient.post<UuidString>('/secure/truck-offers', payload);

        const uuid = Uuid.fromString(res.data);
        return Ok(uuid);
    } catch (e) {
        return Err(e);
    }
}

export async function fetchTruckOffer(id: Uuid): Promise<TruckOffer> {
    const {data} = await httpClient.get<TruckOfferFetchResponsePayload>(`/public/truck-offers/${id}`);

    return {
        ...data,
        id: Uuid.fromString(data.id),
        timeFrom: Time.parseIsoUTC(data.timeFrom),
        timeTo: Time.parseIsoUTC(data.timeTo),
        dateOfExecution: Calendar.parseIsoUTC(data.dateOfExecution),
        participants: mapParticipants(data),
        payPerTonAmount: data.payPerTonAmount ? Money.fromNumber(data.payPerTonAmount, Currency.EUR) : undefined,
        payPerHourAmount: data.payPerHourAmount ? Money.fromNumber(data.payPerHourAmount, Currency.EUR) : undefined,
        createdBy: Uuid.fromString(data.createdBy),
        partialOrders: data.partialOrders.map((partialOrder) => ({
            ...partialOrder,
            id: Uuid.fromString(partialOrder.id),
            createdAt: DateTime.parseIsoUTC(partialOrder.createdAt),
            paymentScheme: partialOrder.paymentScheme,
            paymentAmount: Money.fromNumber(partialOrder.paymentAmount, Currency.EUR),
            contractor: partialOrder.contractor ? mapMinimalCompany(partialOrder.contractor) : undefined,
        })),
        allowsApplication: data.canAddPartialOrder,
        company: mapMinimalCompany(data.company),
    };
}

export async function updateTruckOffer(id: Uuid, payload: CreateTruckOfferRequestPayload): Promise<void> {
    await httpClient.put(`/secure/truck-offers/${id.toString()}`, payload);
}

export async function cancelTruckOffer(id: Uuid): Promise<void> {
    await httpClient.delete(`/secure/truck-offers/${id.toString()}`);
}

export async function applyForTruckOffer(truckOfferId: Uuid, payload: CreateOrderRequestPayload): Promise<void> {
    await httpClient.post<CreateOrderRequestPayload>(`/secure/truck-offers/${truckOfferId.toString()}/orders`, payload);
}

export async function verifyApplyForTruckOffer(truckOfferId: Uuid, contractorId: Uuid): Promise<VerifyCostActionInfo> {
    const {data} = await httpClient.post<VerifyCostActionResponsePayload, ResponseContract<VerifyCostActionResponsePayload>, CreateOrderRequestPayload>(`/secure/truck-offers/${truckOfferId.toString()}/orders/verify`, {
        contractorId: contractorId.toString(),
        // FAKE DATA NOT USED BY BACKEND
        paymentScheme: PaymentScheme.PER_HOUR,
        paymentAmount: 1,
    });
    return mapCostInfo(data);
}

function mapTruckOfferSearchResponseItemToTruckOffer(truckOffer: TruckOfferSearchResponsePayloadItem) {
    return {
        ...truckOffer,
        id: Uuid.fromString(truckOffer.id),
        dateOfExecution: Calendar.parseIsoUTC(truckOffer.dateOfExecution),
    };
}

export async function searchTruckOffers(filterParameter: TruckOfferFilters, page = 1, pageSize = 10): Promise<TruckOfferSearchResult> {
    const res = await httpClient.post<TruckOfferSearchResponsePayload>('/public/truck-offers/search', mapFiltersToSearchPayload(filterParameter, page, pageSize));

    const truckOffers = res.data.items.map(mapTruckOfferSearchResponseItemToTruckOffer);

    return {
        items: truckOffers,
        totalItems: res.data.totalItems,
    };
}

export async function verifyAcceptTruckOfferPartialOrder(truckOfferId: Uuid, partialOrderId: Uuid): Promise<VerifyCostActionInfo> {
    const {data} = await httpClient.post<VerifyCostActionResponsePayload>(`/secure/truck-offers/${truckOfferId}/orders/${partialOrderId}/status/${PartialOrderStatus.ACCEPTED}/verify`);
    return mapCostInfo(data);
}

export async function acceptTruckOfferPartialOrder(truckOfferId: Uuid, partialOrderId: Uuid): Promise<void> {
    await httpClient.post<VerifyCostActionResponsePayload>(`/secure/truck-offers/${truckOfferId}/orders/${partialOrderId}/status/${truckOfferPartialOrderStatuses.ACCEPTED}`);
}

export async function rejectTruckOfferPartialOrder(truckOfferId: Uuid, partialOrderId: Uuid): Promise<void> {
    await httpClient.post<VerifyCostActionResponsePayload>(`/secure/truck-offers/${truckOfferId}/orders/${partialOrderId}/status/${truckOfferPartialOrderStatuses.REJECTED}`);
}

export async function revokeTruckOfferPartialOrder(truckOfferId: Uuid, partialOrderId: Uuid): Promise<void> {
    await httpClient.post<VerifyCostActionResponsePayload>(`/secure/truck-offers/${truckOfferId}/orders/${partialOrderId}/status/${truckOfferPartialOrderStatuses.REVOKED}`);
}

export async function confirmTruckOfferPartialOrder(truckOfferId: Uuid, partialOrderId: Uuid): Promise<void> {
    await httpClient.post<VerifyCostActionResponsePayload>(`/secure/truck-offers/${truckOfferId}/orders/${partialOrderId}/status/${truckOfferPartialOrderStatuses.PENDING}`);
}

export async function fetchTruckOfferList(id: Uuid): Promise<TruckOfferList> {
    const res = await httpClient.get<TruckOfferListResponsePayload>(`/secure/truck-offers/lists/${id}`);

    const mappedOffers = res.data.items.map(mapTruckOfferSearchResponseItemToTruckOffer);

    return {
        ...res.data,
        items: mappedOffers,
    };
}

export async function deactivateTruckOfferFilterNotification(userId: Uuid, filterId: Uuid): Promise<void> {
    await httpClient.post<void>(`/secure/users/${userId.toString()}/truck-offer-filter/${filterId.toString()}/disable-notification`);
}

export async function lookupTruckOffersByPartialOrderIds(partialOrderIds: Uuid[]): Promise<Map<UuidString, MinimalTruckOffer>> {
    const requestPayload: TruckOfferLookupRequestPayload = {
        partialOrderIds: partialOrderIds.map((partialOrderId) => partialOrderId.toString()),
    };
    const truckOfferLookupResponse: ResponseContract<TruckOfferLookupResponsePayload> = await httpClient.post('/secure/truck-offers/lookup/', requestPayload);

    const partialOrderToTruckOfferMap = new Map<UuidString, MinimalTruckOffer>();

    const idToTruckOffers = new Map<UuidString, MinimalTruckOffer>();
    truckOfferLookupResponse.data.truckOffers.forEach(truckOffer => {
        const minimalTruckOffer = {
            id: Uuid.fromString(truckOffer.id),
            title: truckOffer.title,
        };
        idToTruckOffers.set(truckOffer.id, minimalTruckOffer);
    });

    partialOrderIds.forEach((partialOrderId) => {
        const truckOfferIdForPartialOrder = truckOfferLookupResponse.data.mappings[partialOrderId.toString()] ?? undefined;
        if (!truckOfferIdForPartialOrder) {
            return;
        }

        const minimalTruckOffer = idToTruckOffers.get(truckOfferIdForPartialOrder);
        if (!minimalTruckOffer) {
            return;
        }

        partialOrderToTruckOfferMap.set(partialOrderId.toString(), minimalTruckOffer);
    });


    return partialOrderToTruckOfferMap;
}

function mapCostInfo(data: VerifyCostActionResponsePayload): VerifyCostActionInfo {
    return {
        estimatedCosts: Money.fromNumber(data.estimateCost, Currency.EUR),
        hasAccountingInfo: data.hasAccountingInformation,
    };
}

function mapParticipants(data: TruckOfferFetchResponsePayload): TruckOfferContactPerson[] {
    return data.participants.map(participant => ({
            ...participant.user,
            id: Uuid.fromString(participant.user.id),
            role: participant.truckOfferRole,
        }),
    );
}

function mapFiltersToSearchPayload(filterParameter: TruckOfferFilters, page: number, pageSize: number): TruckOfferSearchRequestPayload {
    return {
        location: filterParameter.latitude && filterParameter.longitude ? {
            latitude: filterParameter.latitude,
            longitude: filterParameter.longitude,
        } : undefined,
        dateOfExecutionGt: filterParameter.fromDateTime?.toISO(),
        dateOfExecutionLt: filterParameter.toDateTime?.toISO(),
        companyId: filterParameter.companyId,
        hasPartialOrdersFromContractor: filterParameter.hasPartialOrdersFromContractor,
        truckTypes: filterParameter.truckTypes,
        page,
        pageSize,
    };
}
