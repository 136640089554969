<template>
  <TrSection
    v-if="!fetchingInitial && companyTenders"
    label-id="company-partial-orders"
    with-bottom
    class="relative overflow-hidden"
  >
    <template #top>
      <div class="absolute w-full rounded-t-md overflow-hidden">
        <BarLoader v-if="fetchingCompany" />
      </div>
    </template>

    <template #head>
      <div class="flex justify-between space-y-6 lg:space-y-0 lg:items-center flex-col lg:flex-row">
        <div>
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            {{ $t('tender.myCompany.head') }}
          </h3>
          <p class="max-w-4xl text-sm text-gray-500">
            {{ $t('tender.myCompany.sub') }}
          </p>
        </div>

        <div class="flex lg:space-x-6 space-y-4 lg:space-y-0 flex-col lg:flex-row">
          <TrSwitch
            v-model="showFutureCompanyTenders"
            @update:model-value="fetchCompanyTenders"
          >
            {{ $t('tender.showFuture') }}
          </TrSwitch>
        </div>
      </div>
    </template>

    <NoData
      v-if="companyTenders.length === 0"
      class="my-6"
    >
      <div class="flex flex-col items-center">
        <p>{{ $t('tender.myCompany.noData') }}</p>
        <p
          v-if="showFutureCompanyTenders"
          class="text-gray-500"
        >
          {{ $t('tender.myCompany.showFutureFilter') }}
        </p>
      </div>
    </NoData>

    <TenderList
      v-else
      :tenders="companyTenders"
    />

    <template #bottom>
      <PaginationFooter
        :entity-label="$t('shell.navigation.mytenders')"
        :offset-pagination-data="companyPaginationData"
        :loading="fetchingCompany"
        @change-page="fetchCompanyPage"
      />
    </template>
  </TrSection>

  <TrSection
    v-if="!fetchingInitial && companyPartialOrderTenders"
    class="relative overflow-hidden"
    label-id="company-partial-orders"
    with-bottom
  >
    <template #top>
      <div class="absolute w-full rounded-t-md overflow-hidden">
        <BarLoader v-if="fetchingPartialOrder" />
      </div>
    </template>

    <template #head>
      <div class="flex justify-between space-y-6 lg:space-y-0 lg:items-center flex-col lg:flex-row">
        <div>
          <h3
            id="company-partial-orders"
            class="text-base font-semibold leading-6 text-gray-900"
          >
            {{ $t('tender.companyPartialOrder.head') }}
          </h3>
          <p class="max-w-4xl text-sm text-gray-500">
            {{ $t('tender.companyPartialOrder.sub') }}
          </p>
        </div>

        <div class="flex lg:space-x-6 space-y-4 lg:space-y-0 flex-col lg:flex-row">
          <TrSwitch
            v-model="showFuturePartialOrderTenders"
            @update:model-value="fetchPartialOrderTenders"
          >
            {{ $t('tender.showFuture') }}
          </TrSwitch>
        </div>
      </div>
    </template>

    <NoData
      v-if="companyPartialOrderTenders.length === 0"
      class="my-6"
    >
      <div class="flex flex-col items-center">
        <p>{{ $t('tender.myCompany.noData') }}</p>
        <p
          v-if="showFutureCompanyTenders"
          class="text-gray-500"
        >
          {{ $t('tender.myCompany.showFutureFilter') }}
        </p>
      </div>
    </NoData>

    <TenderList
      v-else
      :tenders="companyPartialOrderTenders"
    />

    <template #bottom>
      <PaginationFooter
        :entity-label="$t('shell.navigation.mytenders')"
        :offset-pagination-data="partialOrderPaginationData"
        :loading="fetchingPartialOrder"
        @change-page="fetchPartialOrderPage"
      />
    </template>
  </TrSection>
</template>

<script lang="ts" setup>
import tenderService from '@app/tender/TenderService';
import auth from '@app/auth/Auth';
import {computed, ref} from 'vue';
import {SimpleTender} from '@app/tender/SimpleTender';
import TenderList from '@app/tender/TenderList.vue';
import NoData from '@app/support/NoData.vue';
import TrSection from '@app/support/TrSection.vue';
import {useOffsetPagination} from '@app/pagination/useOffsetPagination';
import BarLoader from '@app/loader/BarLoader.vue';
import PaginationFooter from '@app/pagination/PaginationFooter.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import TrSwitch from '@app/support/TrSwitch.vue';
import clock from '@app/time/Clock';
import {TenderFiltersContract} from '@app/tender/search/TenderFiltersContract';
import {useLocalStorage} from '@vueuse/core';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';

const companyTenders = ref<SimpleTender[]>();
const companyPartialOrderTenders = ref<SimpleTender[]>();

const showFutureCompanyTenders = useLocalStorage('show-future-tenders-from-company', false);
const showFuturePartialOrderTenders = useLocalStorage('show-future-tenders-with-partial-orders-from-company', false);

const pageSize = ref(5);

const companyTendersCount = ref(0);
const {
    execute: fetchCompanyTenders,
    isPending: fetchingInitialCompany,
    isFetching: fetchingCompany,
} = useAsyncAction(async () => {
    const filterParameter: TenderFiltersContract = {companyId: auth.getCompanyId().toString()};
    if (showFutureCompanyTenders.value) {
        filterParameter.fromDateTime = clock.now()
            .withHour(0)
            .withMinute(0);
    }

    const {data: dataCompany} = await tenderService.search(filterParameter, companyPaginationData.currentPage.value, pageSize.value);
    companyTenders.value = dataCompany.items;
    companyTendersCount.value = dataCompany.totalItems;
});

function fetchCompanyPage(page: number) {
    companyPaginationData.currentPage.value = page;
    fetchCompanyTenders();
}

const partialOrderTendersCount = ref(0);
const {
    execute: fetchPartialOrderTenders,
    isPending: fetchingInitialPartialOrder,
    isFetching: fetchingPartialOrder,
} = useAsyncAction(async () => {
    const filterParameter: TenderFiltersContract = {hasPartialOrdersFromContractor: auth.getCompanyId().toString()};
    if (showFuturePartialOrderTenders.value) {
        filterParameter.fromDateTime = filterParameter.fromDateTime = clock.now()
            .withHour(0)
            .withMinute(0);
    }

    const {data: dataPartialOrder} = await tenderService.search(filterParameter, partialOrderPaginationData.currentPage.value, pageSize.value);
    companyPartialOrderTenders.value = dataPartialOrder.items;
    partialOrderTendersCount.value = dataPartialOrder.totalItems;
});

function fetchPartialOrderPage(page: number) {
    partialOrderPaginationData.currentPage.value = page;
    fetchPartialOrderTenders();
}

const fetchingInitial = computed(() => fetchingInitialPartialOrder.value || fetchingInitialCompany.value);
useAppShellBarLoader(fetchingInitial);

const companyPaginationData = useOffsetPagination({
    total: companyTendersCount,
    pageSize: pageSize,
});

const partialOrderPaginationData = useOffsetPagination({
    total: partialOrderTendersCount,
    pageSize: pageSize,
});

fetchCompanyTenders();
fetchPartialOrderTenders();
</script>
