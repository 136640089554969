import {HttpErrorHandler} from '@app/http/HttpErrorHandler';
import {useAsyncAction} from '@app/http/useAsyncAction';

/**
 * Execute a Query, exposing fundamental data about the mutation like if it is pending, an error occurred etc.
 *
 * @param fn function to execute, most probably an HTTP Call
 * @param errorHandlerConfigurator configure HttpErrorHandler used for any error thrown inside of given fn, standard handler will already have genericErrorHandler
 *
 * @example
 * // simple
 * const {isPending, isError} = useQuery(() => httpClient.post('ROUTE', payload))
 */
export const useMutation = <T, P extends Array<unknown>>(fn: (...args: P) => Promise<T>, errorHandlerConfigurator?: (errorHandler: HttpErrorHandler) => HttpErrorHandler) => {
    const useAsyncActionData = useAsyncAction<T, P>(fn, errorHandlerConfigurator);

    return {
        isPending: useAsyncActionData.isFetching,
        execute: useAsyncActionData.execute,
        isError: useAsyncActionData.isError,
    };
};
