<template>
  <TrSection
    v-if="isPending || activities"
    label-id="dashboard-activities"
    with-bottom
    class="relative overflow-hidden"
  >
    <template #top>
      <div class="absolute w-full rounded-t-md overflow-hidden">
        <BarLoader v-if="isReFetching" />
      </div>
    </template>

    <template #head>
      <SectionHeading id="dashboard-activities">
        {{ $t('dashboard.activities.head') }}
      </SectionHeading>
    </template>

    <Spinner v-if="isPending" />

    <ul v-if="!isPending && activities">
      <Component
        :is="componentMap[activity.type]"
        v-for="(activity, index) in activities"
        :key="activity.id.toString()"
        :is-last="index === activities?.length - 1"
        :activity="activity"
      />
    </ul>

    <template #bottom>
      <PaginationFooter
        :entity-label="$t('dashboard.activities.head')"
        :offset-pagination-data="paginationData"
        :loading="isFetching"
        @change-page="fetchPage"
      />
    </template>
  </TrSection>
</template>

<script lang="ts" setup>
import {activitiesService} from '@app/activities/ActivitiesService';
import {ref} from 'vue';
import {Activity} from '@app/activities/types/Activity';
import OrderAcceptedActivityComponent from '@app/activities/types/OrderAccepted/OrderAcceptedActivityComponent.vue';
import OrderRejectedActivityComponent from '@app/activities/types/OrderRejected/OrderRejectedActivityComponent.vue';
import OrderCreatedActivityComponent from '@app/activities/types/OrderCreated/OrderCreatedActivityComponent.vue';
import UserInvitedActivityComponent from '@app/activities/types/UserInvited/UserInvitedActivityComponent.vue';
import TenderCreatedActivityComponent from '@app/activities/types/TenderCreated/TenderCreatedActivityComponent.vue';
import TenderCanceledActivityComponent from '@app/activities/types/TenderCanceled/TenderCanceledActivityComponent.vue';
import CompanyCreatedActivityComponent from '@app/activities/types/CompanyCreated/CompanyCreatedActivityComponent.vue';
import {ActivityType} from '@app/activities/ActivityType';
import TrSection from '@app/support/TrSection.vue';
import Spinner from '@app/support/Spinner.vue';
import SectionHeading from '@app/support/SectionHeading.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import {useOffsetPagination} from '@app/pagination/useOffsetPagination';
import PaginationFooter from '@app/pagination/PaginationFooter.vue';
import BarLoader from '@app/loader/BarLoader.vue';
import TruckOfferCanceledActivityComponent
  from '@app/activities/types/TruckOfferCanceled/TruckOfferCanceledActivityComponent.vue';
import TruckOfferCreatedActivityComponent from '@app/activities/types/TruckOfferCreated/TruckOfferCreatedActivityComponent.vue';
import OrderRevokedActivityComponent from '@app/activities/types/OrderRevoked/OrderRevokedActivityComponent.vue';
import OrderOutdatedActivityComponent from '@app/activities/types/OrderOutdated/OrderOutdatedActivityComponent.vue';
import TruckOfferOrderCreatedActivityComponent
  from '@app/activities/types/TruckOfferOrderCreated/TruckOfferOrderCreatedActivityComponent.vue';
import TruckOfferOrderRevokedActivityComponent
  from '@app/activities/types/TruckOfferOrderRevoked/TruckOfferOrderRevokedActivityComponent.vue';
import TruckOfferOrderAcceptedActivityComponent
  from '@app/activities/types/TruckOfferOrderAccepted/TruckOfferOrderAcceptedActivityComponent.vue';
import TruckOfferOrderRejectedActivityComponent
  from '@app/activities/types/TruckOfferOrderRejected/TruckOfferOrderRejectedActivityComponent.vue';

const componentMap: Record<ActivityType, unknown> = {
  [ActivityType.ORDER_ACCEPTED]: OrderAcceptedActivityComponent,
  [ActivityType.ORDER_REJECTED]: OrderRejectedActivityComponent,
  [ActivityType.ORDER_REVOKED]: OrderRevokedActivityComponent,
  [ActivityType.ORDER_OUTDATED]: OrderOutdatedActivityComponent,
  [ActivityType.USER_INVITED]: UserInvitedActivityComponent,
  [ActivityType.ORDER_CREATED]: OrderCreatedActivityComponent,
  [ActivityType.TENDER_CREATED]: TenderCreatedActivityComponent,
  [ActivityType.TENDER_CANCELED]: TenderCanceledActivityComponent,
  [ActivityType.COMPANY_CREATED]: CompanyCreatedActivityComponent,
  [ActivityType.TRUCK_OFFER_CANCELED]: TruckOfferCanceledActivityComponent,
  [ActivityType.TRUCK_OFFER_CREATED]: TruckOfferCreatedActivityComponent,
  [ActivityType.TRUCK_OFFER_ORDER_CREATED]: TruckOfferOrderCreatedActivityComponent,
  [ActivityType.TRUCK_OFFER_ORDER_REVOKED]: TruckOfferOrderRevokedActivityComponent,
  [ActivityType.TRUCK_OFFER_ORDER_ACCEPTED]: TruckOfferOrderAcceptedActivityComponent,
  [ActivityType.TRUCK_OFFER_ORDER_REJECTED]: TruckOfferOrderRejectedActivityComponent,
  [ActivityType.TRUCK_OFFER_ORDER_OUTDATED]: TruckOfferOrderRejectedActivityComponent,
};

const activities = ref<Activity[]|null>(null);

const total = ref(0);
const pageSize = ref(20);
const paginationData = useOffsetPagination({
    pageSize,
    total,
});

const { execute: fetchActivities, isFetching, isPending, isReFetching } = useAsyncAction(async (page: number) => {
    const { data } = await activitiesService.getAll(page, pageSize.value);
    activities.value = data.items;
    total.value = data.totalItems;
});

function fetchPage(page: number) {
    paginationData.currentPage.value = page;
    fetchActivities(page);
}

fetchPage(1);
</script>
