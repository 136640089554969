import {googleMapsLocationProvider} from '@app/location/GoogleMapsLocationProvider';
import {staticLocationProvider} from '@app/location/StaticLocationProvider';

const locationProviderEnv: LocationProvider | undefined = import.meta.env.VITE_APP_LOCATION_PROVIDER;

export enum LocationProvider {
    GOOGLE = 'GOOGLE',
    STATIC = 'STATIC'
}

export const locationProvider = () => {
    if (locationProviderEnv === LocationProvider.GOOGLE) {
        return googleMapsLocationProvider;
    } else {
        return staticLocationProvider;
    }
};