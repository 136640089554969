<template>
  <Dropdown
    v-if="hasAnyCreatePermission"
    data-cy="header-create-dropdown"
  >
    <template #button>
      <DropdownButton :sr-text="$t('tender.page.partialOrders.actions.title')">
        <template #prefix>
          <DocumentPlusIcon class="mr-2 h-5 w-5" />
        </template>
        {{ $t('header.create.both') }}
      </DropdownButton>
    </template>
    <div class="py-1">
      <DropdownMenuLink
        v-if="canCreateTender"
        data-cy="tender-create-button"
        :to="{ name: createTenderRoute.name }"
      >
        Ausschreibung
      </DropdownMenuLink>
      <DropdownMenuLink
        v-if="canCreateTruckOffer"
        :to="{ name: createTruckOfferRoute.name }"
      >
        Frachtraum
      </DropdownMenuLink>
    </div>
  </Dropdown>
</template>

<script lang="ts" setup>
import {permissions} from '@app/auth/permissions/permissions';
import Dropdown from '@app/support/dropdown/Dropdown.vue';
import {createTruckOfferRoute} from '@app/truckoffer/routes';
import {createTenderRoute} from '@app/tender/routes';
import DropdownButton from '@app/support/dropdown/DropdownButton.vue';
import DropdownMenuLink from '@app/support/dropdown/DropdownMenuLink.vue';
import {userPermissions} from '@app/auth/Auth';
import {DocumentPlusIcon} from '@heroicons/vue/24/solid';

const canCreateTender = userPermissions.value.has(permissions.CREATE_TENDER);
const canCreateTruckOffer = userPermissions.value.has(permissions.CREATE_TRUCK_OFFER);
const hasAnyCreatePermission = canCreateTender || canCreateTruckOffer;
</script>
