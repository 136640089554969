import {ClockContract} from '@app/time/ClockContract';
import {DateTime} from '@app/time/DateTime';

class Clock implements ClockContract {
    now(): DateTime {
        return DateTime.now();
    }
}

export default new Clock as ClockContract;
