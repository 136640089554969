<template>
  <div class="flex-1">
    <TrRequiresAuth>
      <TrSidebarItem
        :to="{ path: '/' }"
        :name="$t('shell.navigation.dashboard')"
        :icon="HomeIcon"
        @click="$emit('close')"
      />
    </TrRequiresAuth>

    <TrRequiresAuth>
      <TrSidebarItem
        :to="{ path: '/company' }"
        :name="$t('shell.navigation.company')"
        :icon="UsersIcon"
        @click="$emit('close')"
      />
    </TrRequiresAuth>

    <TrSidebarItem
      :to="{ path: '/tenders' }"
      :name="$t('shell.navigation.tenders')"
      :icon="MagnifyingGlassIcon"
      @click="$emit('close')"
    />

    <TrRequiresAuth>
      <TrSidebarItem
        :to="{ path: '/mytenders' }"
        :name="$t('shell.navigation.mytenders')"
        :icon="ClipboardDocumentListIcon"
        @click="$emit('close')"
      />
    </TrRequiresAuth>

    <TrRequiresAuth>
      <TrSidebarItem
        :to="{ path: '/truckoffers' }"
        :name="$t('shell.navigation.truckOffers')"
        :icon="MagnifyingGlassIcon"
        @click="$emit('close')"
      />
    </TrRequiresAuth>

    <TrRequiresAuth>
      <TrSidebarItem
        :to="{ path: '/mytruckoffers' }"
        :name="$t('shell.navigation.myTruckOffers')"
        :icon="TruckIcon"
        @click="$emit('close')"
      />
    </TrRequiresAuth>

    <TrRequiresAuth>
      <TrSidebarItem
        :to="{ path: '/bills' }"
        :name="$t('shell.navigation.bills')"
        :icon="BanknotesIcon"
        @click="$emit('close')"
      />
    </TrRequiresAuth>

    <RequiresPermissions :permissions="systemPermissions">
      <div class="relative mt-2 mb-2">
        <div
          class="absolute inset-0 flex items-center"
          aria-hidden="true"
        >
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center">
          <span class="bg-white px-2 text-sm text-gray-500">Admin</span>
        </div>
      </div>

      <TrSidebarItem
        data-cy="registered-companies-menu-item"
        :to="{ path: '/reporting/registered-companies' }"
        name="Alle Unternehmen"
        :icon="BuildingOfficeIcon"
        @click="$emit('close')"
      />
    </RequiresPermissions>
  </div>
  <TrSidebarItem
    to="mailto:support@transport-x.de"
    :name="$t('shell.navigation.help')"
    :icon="QuestionMarkCircleIcon"
    @click="$emit('close')"
  />
</template>

<script lang="ts" setup>
import TrSidebarItem from '@app/shell/SidebarItem.vue';
import {
  ClipboardDocumentListIcon,
  HomeIcon,
  UsersIcon,
  MagnifyingGlassIcon,
  BanknotesIcon,
  QuestionMarkCircleIcon,
  TruckIcon,
  BuildingOfficeIcon,
} from '@heroicons/vue/24/outline';
import TrRequiresAuth from '@app/auth/RequiresAuth.vue';
import RequiresPermissions from '@app/auth/RequiresPermissions.vue';
import {permissions} from '@app/auth/permissions/permissions';

interface Events {
  (e: 'close'): void
}

defineEmits<Events>();

const systemPermissions = new Set([permissions.SYSTEM_READ_REGISTERED_COMPANIES]);
</script>
