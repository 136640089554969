export type TruckType = typeof truckTypes[keyof typeof truckTypes];

export const truckTypes = {
    AXLES_2: 'AXLES_2',
    AXLES_3: 'AXLES_3',
    AXLES_4: 'AXLES_4',
    SEMITRAILER: 'SEMITRAILER',
} as const;

export const truckTypeValues = Object.values(truckTypes);
