import {RouteRecordRaw} from 'vue-router';
import TruckOfferDetails from '@app/truckoffer/TruckOfferDetails.vue';
import TruckofferSearch from '@app/truckoffer/TruckOfferSearch.vue';
import CreateTruckOffer from '@app/truckoffer/CreateTruckOffer.vue';
import UpdateTruckOffer from '@app/truckoffer/UpdateTruckOffer.vue';
import MyTruckOffers from '@app/truckoffer/MyTruckOffers.vue';
import TruckOfferListPage from '@app/truckoffer/TruckOfferListPage.vue';
import DeactivateTruckOfferFilterNotification from '@app/truckoffer/DeactivateTruckOfferFilterNotification.vue';

export const truckOfferDetailsRoute: RouteRecordRaw = {
    path: '/truckoffer/:id',
    component: TruckOfferDetails,
    name: 'TruckOfferDetails',
};

export const truckOffersRoute: RouteRecordRaw = {
    path: '/truckoffers',
    component: TruckofferSearch,
    name: 'truckOffers',
};

export const createTruckOfferRoute: RouteRecordRaw = {
    path: '/truckoffer/create',
    component: CreateTruckOffer,
    name: 'CreateTruckOffer',
};

export const updateTruckOfferRoute: RouteRecordRaw = {
    path: '/truckOffer/:id/update',
    component: UpdateTruckOffer,
    name: 'UpdateTruckOffer',
};

export const myTruckOffersRoute: RouteRecordRaw = {
    path: '/mytruckoffers',
    component: MyTruckOffers,
    name: 'MyTruckOffers',
};

export const truckOfferListRoute: RouteRecordRaw = {
    path: '/truckofferlist/:id',
    component: TruckOfferListPage,
    name: 'TruckOfferListPage',
};

export const deactivateFilterNotification: RouteRecordRaw = {
    path: '/deactivate-truck-offer-notification/:id',
    component: DeactivateTruckOfferFilterNotification,
    meta: {
        blank: true,
    },
};

export const truckOffersRoutes = [
    truckOfferDetailsRoute,
    truckOffersRoute,
    createTruckOfferRoute,
    updateTruckOfferRoute,
    myTruckOffersRoute,
    truckOfferListRoute,
    deactivateFilterNotification,
];
