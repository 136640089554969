<template>
  <TrSimpleModal
    :show="show"
    :title="$t('company.inviteUserTitle')"
    data-cy="invite-user-modal"
    @hide="$emit('hide')"
  >
    <span class="text-gray-500">
      {{ $t('company.inviteUserExplain') }}
    </span>
    <form
      id="invite-user-form"
      class="flex flex-col space-y-6 mt-8"
      @submit.prevent="inviteUser"
    >
      <TrLabeledInput
        v-model="form.firstName"
        label="Vorname"
        name="firstname"
        required
      />
      <TrLabeledInput
        v-model="form.lastName"
        label="Nachname"
        name="lastname"
        required
      />
      <TrLabeledInput
        v-model="form.email"
        required
        name="mail"
        label="E-Mail"
        type="email"
      />
      <TrLabeledInput
        v-model="form.phoneNumber"
        required
        name="phone-number"
        :label="$t('auth.register.phoneNumber')"
      />
    </form>

    <template #actions>
      <div class="mb-4 sm:mb-0">
        <TrButton
          class="w-full"
          :loading="isLoading"
          type="submit"
          form="invite-user-form"
        >
          {{ $t('company.inviteUserTitle') }}
        </TrButton>
      </div>
      <div class="sm:mr-4">
        <TrButton
          :color-scheme="ButtonColorScheme.TERTIARY"
          class="w-full"
          form="invite-user-form"
          @click="$emit('hide')"
        >
          {{ $t('generic.close') }}
        </TrButton>
      </div>
    </template>
  </TrSimpleModal>
</template>

<script lang="ts" setup>
import {reactive, ref} from 'vue';
import TrSimpleModal from '@app/overlays/modals/SimpleModal.vue';
import TrLabeledInput from '@app/forms/LabeledInput.vue';
import TrButton from '@app/support/TrButton.vue';
import companyService from './CompanyService';
import auth from '../auth/Auth';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';


interface Props {
  show: boolean
}

interface Emits {
  (e: 'hide'): void,

  (e: 'invite-sent'): void,
}

defineProps<Props>();

const emit = defineEmits<Emits>();

const isLoading = ref(false);

const form = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
});

const inviteUser = async () => {
  isLoading.value = true;

  try {
    await companyService.inviteUser(auth.getCompanyId(), form);

    emit('hide');
    emit('invite-sent');
  } finally {
    isLoading.value = false;
  }
};
</script>
