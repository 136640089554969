<template>
  <div class="relative rounded-md shadow-sm">
    <input
      v-bind="$attrs"
      :value="modelValue"
      :class="{
        'appearance-none rounded-md block w-full focus:outline-none sm:text-sm rounded-m': true,
        'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500': !hasErrors,
        'border-red-300 pr-10 text-red-900 focus:ring-red-500 focus:border-red-500': hasErrors,
      }"
      :aria-invalid="hasErrors ? 'true' : 'false'"
      @input="onInput($event.target)"
    >

    <div
      v-if="hasErrors"
      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
    >
      <ExclamationCircleIcon
        class="h-5 w-5 text-red-500"
        aria-hidden="true"
      />
    </div>
  </div>

  <FieldErrors :errors="combinedErrors" />
</template>

<script lang="ts" setup>
import {toRefs} from 'vue';
import {ExclamationCircleIcon} from '@heroicons/vue/20/solid';
import Rule from '@app/forms/rules/Rule';
import {useErrorHandling} from '@app/forms/useErrorHandling';
import FieldErrors from '@app/forms/FieldErrors.vue';

defineOptions({
    inheritAttrs: false,
});

interface Props {
  errors?: string[];
  modelValue?: string | number;
  rules?: Rule<string | number | undefined>[];
}

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
  modelValue: undefined,
  rules: () => [],
});

const emit = defineEmits<{
  (e: 'update:modelValue', value?: string): void,
  (e: 'update:errors', value?: string[]): void,
}>();

const {
  modelValue,
  errors,
  rules,
} = toRefs(props);

const {combinedErrors, hasErrors, resetInternalErrors} = useErrorHandling(rules, modelValue, 'input', errors);

const onInput = (value: EventTarget | null) => {
  emit('update:modelValue', (value as HTMLInputElement).value);
  emit('update:errors', []);

  resetInternalErrors();
};

</script>
