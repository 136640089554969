export enum ValidationErrorCode {
    PARTIAL_ORDER_TOTAL_REQUIRED_TRUCKS_EXCEEDED = 'PARTIAL_ORDER_TOTAL_REQUIRED_TRUCKS_EXCEEDED',
    INVALID_TOKEN = 'INVALID_TOKEN',
    AUTHENTICATION_BAD_CREDENTIALS = 'AUTHENTICATION_BAD_CREDENTIALS',
    COMPANY_ALREADY_EXIST = 'COMPANY_ALREADY_EXIST',
    TENDER_NOT_FOUND = 'TENDER_NOT_FOUND',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    TOKEN_EXPIRED = 'TOKEN_EXPIRED',
    MISSING_PRIVILEGES = 'MISSING_PRIVILEGES',
    TOKEN_SIGNATURE_INVALID = 'TOKEN_SIGNATURE_INVALID',
    FILTER_NOT_FOUND = 'FILTER_NOT_FOUND',
}
