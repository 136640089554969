export enum ActivityType {
    ORDER_ACCEPTED = 'ORDER_ACCEPTED',
    ORDER_REJECTED = 'ORDER_REJECTED',
    ORDER_REVOKED = 'ORDER_REVOKED',
    ORDER_OUTDATED = 'ORDER_OUTDATED',
    USER_INVITED = 'USER_INVITED',
    ORDER_CREATED = 'ORDER_CREATED',
    TENDER_CREATED = 'TENDER_CREATED',
    TENDER_CANCELED = 'TENDER_CANCELED',
    COMPANY_CREATED = 'COMPANY_CREATED',
    TRUCK_OFFER_CANCELED = 'TRUCK_OFFER_CANCELED',
    TRUCK_OFFER_CREATED = 'TRUCK_OFFER_CREATED',
    TRUCK_OFFER_ORDER_CREATED = 'TRUCK_OFFER_ORDER_CREATED',
    TRUCK_OFFER_ORDER_REVOKED = 'TRUCK_OFFER_ORDER_REVOKED',
    TRUCK_OFFER_ORDER_ACCEPTED = 'TRUCK_OFFER_ORDER_ACCEPTED',
    TRUCK_OFFER_ORDER_OUTDATED = 'TRUCK_OFFER_ORDER_OUTDATED',
    TRUCK_OFFER_ORDER_REJECTED = 'TRUCK_OFFER_ORDER_REJECTED',
}
