<template>
  <div class="h-full">
    <MobileSidebar
      :sidebar-open="sidebarOpen"
      @close="sidebarOpen = false"
    />

    <DesktopSidebar />

    <!--header-->
    <div class="md:pl-64 h-full flex flex-col flex-1">
      <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          type="button"
          class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon
            class="h-6 w-6"
            aria-hidden="true"
          />
        </button>
        <div class="flex-1 px-4 flex justify-end">
          <div class="flex items-center">
            <HeaderCreateButton />
          </div>
          <div class="ml-4 flex items-center md:ml-6">
            <!-- Profile dropdown -->
            <Menu
              as="div"
              class="ml-3 relative"
            >
              <div>
                <MenuButton
                  class="max-w-xs bg-white flex items-center text-sm rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  <span class="sr-only">Open user menu</span>
                  <UserIcon
                    class="h-6 w-6"
                    aria-hidden="true"
                  />
                  <span
                    v-if="userFullName"
                    class="hidden ml-3 text-gray-700 text-sm font-medium lg:block"
                  >{{
                    userFullName
                  }}</span>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              />
              <AppShellMenuItems :company-name="companyName" />
            </Menu>
          </div>
        </div>
      </div>

      <!--content-->
      <div class="flex-1 flex flex-col h-full">
        <main class="flex-1">
          <TrBarLoader v-if="isShowLoader" />
          <div class="flex flex-col py-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <Breadcrumbs
              v-if="hasBreadcrumbs"
              :pages="breadcrumbsPath"
              class="mb-6"
            />
            <slot />
          </div>
        </main>
        <Footer class="flex-none" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import {Menu, MenuButton} from '@headlessui/vue';
import {Bars3BottomLeftIcon, UserIcon} from '@heroicons/vue/24/outline';
import AppShellMenuItems from '@app/shell/AppShellMenuItems.vue';
import {hasAuthToken, userId} from '@app/auth/Auth';
import {authService} from '@app/auth/AuthService';
import Breadcrumbs from '@app/shell/Breadcrumbs.vue';
import {useRoute} from 'vue-router';
import {BreadcrumbsPage} from '@app/shell/BreadbrumbsPage';
import Footer from '@app/shell/Footer.vue';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import TrBarLoader from '@app/loader/BarLoader.vue';
import MobileSidebar from '@app/shell/MobileSidebar.vue';
import DesktopSidebar from '@app/shell/DesktopSidebar.vue';
import HeaderCreateButton from '@app/tender/create/HeaderCreateButton.vue';


const sidebarOpen = ref(false);
const userFullName = ref('');
const companyName = ref('');

const route = useRoute();
const {isShowLoader} = useAppShellBarLoader();

async function fetchUserDetails() {
  if (!userId.value) {
    throw new Error('Cannot fetch UserDetails, user not logged in!');
  }

  const {data: userDetails} = await authService.userDetails(userId.value);

  userFullName.value = `${userDetails.firstName} ${userDetails.lastName} (${userDetails.companyName})`;
  companyName.value = userDetails.companyName;
}

if (hasAuthToken.value) {
  fetchUserDetails();
}

const breadcrumbsPath = computed(() => route.meta.breadcrumbsPath as BreadcrumbsPage[] ?? []);
const hasBreadcrumbs = computed(() => Array.isArray(breadcrumbsPath.value) && breadcrumbsPath.value.length > 0);
</script>
