import httpClient from '@app/http/HttpClient';
import {Uuid} from '@app/uuid/Uuid';
import {UuidString} from '@app/uuid/UuidString';
import {DateString} from '@app/support/DateString';
import {TruckType} from '@app/tender/trucks/TruckType';
import {DateTime} from '@app/time/DateTime';
import {NotifiableTruckOfferSearchParams} from '@app/truckoffer/search/TruckOfferSearchParams';

export interface ActiveTruckOfferFilterResponsePayload {
    id: UuidString,
    filterCompanyId?: UuidString,
    filterDateOfExecutionGt?: DateString,
    filterDateOfExecutionLt?: DateString,
    filterLocationDisplayName?: string,
    filterLocationLatitude?: number,
    filterLocationLongitude?: number,
    filterLocationExternalId?: string,
    notificationEnabled: boolean,
    truckTypes: TruckType[]
}

type UpdateTruckOfferFilterRequestPayload = Omit<ActiveTruckOfferFilterResponsePayload, 'id'>;

let activeTruckOfferFilterId: UuidString|undefined = undefined;

export async function fetchActiveTruckOfferFilterFromBackend(userId: Uuid): Promise<NotifiableTruckOfferSearchParams> {
    const {data: {
        filterDateOfExecutionGt,
        filterDateOfExecutionLt,
        filterLocationDisplayName,
        filterLocationExternalId,
        filterLocationLatitude,
        filterLocationLongitude,
        id,
        truckTypes,
        notificationEnabled,
    }} = await httpClient.get<ActiveTruckOfferFilterResponsePayload>(`/secure/users/${userId}/truck-offer-filter/active`);

    activeTruckOfferFilterId = id;

    return {
        locationDetails: filterLocationDisplayName && filterLocationLatitude && filterLocationLongitude && filterLocationExternalId ? {
            displayName: filterLocationDisplayName,
            latitude: filterLocationLatitude,
            longitude: filterLocationLongitude,
            externalId: filterLocationExternalId,
        } : undefined,
        fromDate: filterDateOfExecutionGt ? DateTime.parseIsoUTC(filterDateOfExecutionGt) : undefined,
        toDate: filterDateOfExecutionLt ? DateTime.parseIsoUTC(filterDateOfExecutionLt) : undefined,
        truckTypes,
        notificationEnabled,
    };
}

export async function updateActiveTruckOfferFilterInBackend(userId: Uuid, {fromDate, locationDetails, toDate, truckTypes, notificationEnabled}: NotifiableTruckOfferSearchParams) {
    if (!activeTruckOfferFilterId) {
        // fetch active filter to know what the id of the active filter is, so we know which to update
        await fetchActiveTruckOfferFilterFromBackend(userId);
    }

    const payload: UpdateTruckOfferFilterRequestPayload = {
        notificationEnabled,
        filterDateOfExecutionGt: fromDate?.toISO(),
        filterDateOfExecutionLt: toDate?.toISO(),
        truckTypes: truckTypes,
        filterLocationDisplayName: locationDetails?.displayName,
        filterLocationLongitude: locationDetails?.longitude,
        filterLocationLatitude: locationDetails?.latitude,
        filterLocationExternalId: locationDetails?.externalId,

    };
    await httpClient.put(`/secure/users/${userId}/truck-offer-filter/${activeTruckOfferFilterId}`, payload);
}
