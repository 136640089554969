import Rule from '@app/forms/rules/Rule';
import {Err, Ok} from '@app/result/Result';

export const minLength = (min: number): Rule<unknown> =>
    (val: unknown) => {
        if (typeof val !== 'string') {
            return Ok.EMPTY;
        }

        if (val.length >= min) {
            return Ok.EMPTY;
        }

        return Err(`Bitte mindestens ${min} Zeichen angeben.`);
    };
