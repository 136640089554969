<template>
  <template v-if="!isLoading && userToUpdate">
    <Back class="mb-4" />

    <TrForm
      id="create-tender-form"
      @validated="updateUser"
    >
      <TrSection
        label-id="update-user-headline"
        with-bottom
      >
        <template #head>
          <SectionHeading id="update-user-headline">
            {{ $t('company.updateUser.title') }}
          </SectionHeading>

          <p class="max-w-4xl text-sm text-gray-500">
            {{ $t('company.updateUser.explain', {fullName: `${userToUpdate.firstName} ${userToUpdate.lastName}`}) }}
          </p>
        </template>

        <div class="flex flex-col space-y-6">
          <div class="flex flex-col md:flex-row md:space-x-3 space-y-6 md:space-y-0">
            <div class="flex-1">
              <LabeledInput
                v-model="firstName"
                name="firstname"
                :label="$t('company.updateUser.firstName')"
                :rules="[required()]"
              />
            </div>
            <div class="flex-1">
              <LabeledInput
                v-model="lastName"
                name="lastname"
                :label="$t('company.updateUser.lastName')"
                :rules="[required()]"
              />
            </div>
          </div>
          <TrLabeledInput
            v-model="phoneNumber"
            required
            name="phone-number"
            :label="$t('company.updateUser.phoneNumber')"
          />

          <template v-if="hasGrantPermissionPermission">
            <Divider>
              <span class="bg-white px-3 text-base leading-6 text-gray-900">{{
                $t('company.userDetails.permissions')
              }}</span>
            </Divider>

            <div class="mt-8 flex flex-wrap">
              <div
                v-for="permission in hydratedPermissions"
                :key="permission.id"
                class="px-4 py-1 w-1/2"
              >
                <Checkbox
                  :id="`permissions-checkbox-${permission.id}`"
                  :disabled="isCurrentUser"
                  :checked="permission.isGranted"
                  data-cy="permissions-checkbox"
                  @change="togglePermission(permission.id)"
                >
                  {{ translatePermission(permission.id) }}
                </checkbox>
              </div>
            </div>
          </template>
        </div>
        <template #bottom>
          <div class="flex justify-end">
            <TrButton
              class="flex-grow lg:flex-none"
              :loading="loadingUpdate"
              type="submit"
              data-cy="user-update-submit"
            >
              {{ $t('company.updateUser.submit') }}
            </TrButton>
          </div>
        </template>
      </TrSection>
    </TrForm>
  </template>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue';
import {CompanyUser} from '@app/company/CompanyUser';
import companyService from '@app/company/CompanyService';
import {Uuid} from '@app/uuid/Uuid';
import {useRoute, useRouter} from 'vue-router';
import Back from '@app/routing/Back.vue';
import TrForm from '@app/forms/TrForm.vue';
import TrButton from '@app/support/TrButton.vue';
import LabeledInput from '@app/forms/LabeledInput.vue';
import TrLabeledInput from '@app/forms/LabeledInput.vue';
import {required} from '@app/forms/rules/required';
import Checkbox from '@app/forms/checkbox/Checkbox.vue';
import {permissions} from '@app/auth/permissions/permissions';
import {Permission} from '@app/auth/permissions/Permission';
import auth, {userId, userPermissions} from '@app/auth/Auth';
import {userDetailsRoute} from '@app/company/routes';
import Divider from '@app/support/Divider.vue';
import TrSection from '@app/support/TrSection.vue';
import SectionHeading from '@app/support/SectionHeading.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import {useUserForm} from '@app/company/useUserForm';
import {useI18n} from 'vue-i18n';

const {t} = useI18n();

const userToUpdate = ref<CompanyUser>();

const route = useRoute();
const userIdParam = computed(() => Uuid.fromString(route.params.userId as string));

const firstName = ref<string>();
const lastName = ref<string>();
const phoneNumber = ref<string>();

const {
  grantedPermissions,
  hydratedPermissions,
} = useUserForm();

const {execute: fetchUserDetails, isFetching: isLoading} = useAsyncAction(async function () {
  const {data} = await companyService.getUserDetails(userIdParam.value);
  userToUpdate.value = data;
  firstName.value = data.firstName;
  lastName.value = data.lastName;
  phoneNumber.value = data.phoneNumber;

  grantedPermissions.value = [...data.permissions];
});

useAppShellBarLoader(computed(() => isLoading.value || !userToUpdate.value));

const router = useRouter();
const {isFetching: loadingUpdate, execute: updateUser} = useAsyncAction(async function () {
  if (!grantedPermissions.value || !firstName.value || !lastName.value || !phoneNumber.value) {
    return;
  }

  if (!userToUpdate.value) {
    return;
  }

  try {
    const updates = [
      companyService.updateUserDetails(userToUpdate.value?.id, {
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber: phoneNumber.value,
      }),
    ];

    if (!isCurrentUser.value) {
      const updatePermissionsPromise = companyService.updatePermissions(userToUpdate.value.id, auth.getCompanyId(), grantedPermissions.value);
      updates.push(updatePermissionsPromise);
    }

    await Promise.all(updates);

    router.push({name: userDetailsRoute.name, params: {userId: userToUpdate.value?.id.toString()}});
  } catch (e) {
    resetGrantedPermissions();
    throw e;
  }
});

const hasGrantPermissionPermission = computed(() => userPermissions.value.has(permissions.GRANT_PERMISSION));

const resetGrantedPermissions = () => {
  grantedPermissions.value = userToUpdate.value ? [...userToUpdate.value.permissions] : undefined;
};
const togglePermission = (permission: Permission) => {
  if (grantedPermissions.value?.includes(permission)) {
    grantedPermissions.value = grantedPermissions.value?.filter(grantedPermission => grantedPermission !== permission);
  } else {
    grantedPermissions.value?.push(permission);
  }
};

fetchUserDetails();

const isCurrentUser = computed(() => userToUpdate.value?.id.equals(userId.value));

function translatePermission(permission: Permission) {
  return {
    [permissions.INVITE_USER]: t('auth.permission.INVITE_USER'),
    [permissions.CREATE_TENDER]: t('auth.permission.CREATE_TENDER'),
    [permissions.GRANT_PERMISSION]: t('auth.permission.GRANT_PERMISSION'),
    [permissions.MODIFY_TENDER]: t('auth.permission.MODIFY_TENDER'),
    [permissions.APPLY_FOR_TENDER]: t('auth.permission.APPLY_FOR_TENDER'),
    [permissions.UPDATE_USER]: t('auth.permission.UPDATE_USER'),
    [permissions.CREATE_TRUCK_OFFER]: t('auth.permission.CREATE_TRUCK_OFFER'),
    [permissions.MODIFY_TRUCK_OFFER]: t('auth.permission.MODIFY_TRUCK_OFFER'),
    [permissions.APPLY_FOR_TRUCK_OFFER]: t('auth.permission.APPLY_FOR_TRUCK_OFFER'),
    [permissions.SYSTEM_READ_REGISTERED_COMPANIES]: '-',
  }[permission];
}
</script>
