<template>
  <form
    novalidate
    @submit.prevent="internalSubmit"
  >
    <slot />
  </form>
</template>

<script lang="ts" setup>
import {computed, provide, toRefs, watch} from 'vue';
import FormContext from '@app/forms/FormContext';
import {FormContextValidator} from '@app/forms/FormContextContract';
import keyBy from 'lodash/keyBy';
import UuidFactory from '@app/uuid/UuidFactory';
import {Uuid} from '@app/uuid/Uuid';

interface Props {
  validators?: FormContextValidator[]
}

interface Events {
  (e: 'validated'): void;
}

const props = withDefaults(defineProps<Props>(), {
  validators: () => [],
});

const emit = defineEmits<Events>();

const {
  validators,
} = toRefs(props);

const vali = computed(() => keyBy(validators.value, () => UuidFactory.v4()));

const formContext = new FormContext();

provide('formContext', formContext);

watch(vali, async (newValidators, oldValidators) => {
  for (const oldValidatorId in oldValidators) {
    formContext.removeValidator(Uuid.fromString(oldValidatorId));
  }

  for (const newValidatorId in newValidators) {
    formContext.registerValidator(Uuid.fromString(newValidatorId), newValidators[newValidatorId]);
  }
}, {immediate: true});

const internalSubmit = async function () {
  const {isOk} = await formContext.validate();
  if (isOk) {
    emit('validated');
  }
};

</script>
