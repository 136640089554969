import {createRouter, createWebHistory, RouteLocationNormalized} from 'vue-router';
import { routes } from './routes';
import {hasAuthToken} from '../auth/Auth';
import {loginRoute} from '@app/auth/routes';

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const guardAuthRoutes = (to: RouteLocationNormalized) => {
    // protect routes that require auth, we should generally not let navigations like this happen by hiding the links
    // to auth pages for unauthenticated users
    if (hasAuthToken.value || to.meta.allowAnonymous) {
        return true;
    }

    // redirect to login route with from query param to allow navigating back
    return {
        name: loginRoute.name,
        query: { from: to.fullPath },
    };
};

router.beforeEach(guardAuthRoutes);

export default router;
