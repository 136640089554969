<template>
  <TrLabeledSelect
    :model-value="modelValue"
    :items="availableTruckTypes"
    :label="label"
    :rules="rules"
    :multiple="multiple"
    @update:model-value="$emit('update:model-value', $event as typeof modelValue)"
  />
</template>

<script lang="ts" setup>
import TrLabeledSelect from '@app/forms/LabeledSelect.vue';
import {TruckType} from '@app/tender/trucks/TruckType';
import Rule from '@app/forms/rules/Rule';
import {useTruckTypeSelect} from '@app/tender/useTruckTypeSelect';

interface Props {
  modelValue: TruckType[] | TruckType,
  label: string,
  rules?: Rule<unknown>[],
  multiple?: boolean
}

withDefaults(defineProps<Props>(), {
  rules: () => [],
  multiple: false,
});

interface Events {
  (e: 'update:model-value', value: Props['modelValue']): void
}

defineEmits<Events>();

const {availableTruckTypes} = useTruckTypeSelect();
</script>
