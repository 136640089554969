export const permissions = {
    INVITE_USER: 'INVITE_USER',
    CREATE_TENDER: 'CREATE_TENDER',
    CREATE_TRUCK_OFFER: 'CREATE_TRUCK_OFFER',
    GRANT_PERMISSION: 'GRANT_PERMISSION',
    MODIFY_TENDER: 'MODIFY_TENDER',
    MODIFY_TRUCK_OFFER: 'MODIFY_TRUCK_OFFER',
    APPLY_FOR_TENDER: 'APPLY_FOR_TENDER',
    APPLY_FOR_TRUCK_OFFER: 'APPLY_FOR_TRUCK_OFFER',
    UPDATE_USER: 'UPDATE_USER',
    SYSTEM_READ_REGISTERED_COMPANIES: 'SYSTEM_READ_REGISTERED_COMPANIES',
} as const;
