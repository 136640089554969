<template>
  <input
    ref="root"
    type="text"
  >
</template>

<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref, toRefs, watchEffect} from 'vue';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import {German} from 'flatpickr/dist/l10n/de';
import omit from 'lodash/omit';
import Calendar from '@app/time/Calendar';
import {useMutationObserver} from '@vueuse/core';

const props = defineProps<{
  modelValue?: Calendar
  minDate?: Calendar,
  maxDate?: Calendar,
}>();

const {
  modelValue,
  minDate,
  maxDate,
} = toRefs(props);

const emit = defineEmits<{
  (e: 'update:modelValue', value: Calendar|undefined): void
}>();

const root = ref<HTMLInputElement|null>(null);

const flatPickerInstance = ref<flatpickr.Instance|null>(null);

const emitDateAsUpdateEvent: flatpickr.Options.Hook = (selectedDates, dateStr) => {
  let calendar: Calendar|undefined;
  try {
    calendar = Calendar.parseIsoUTC(dateStr);
  } catch (unused) {
  }

  emit('update:modelValue', calendar);
};

const options = computed(() => ({
  locale: German,
  altInput: true,
  altFormat: 'j. F Y',
  defaultDate: modelValue?.value?.toISO(),
  minDate: minDate?.value?.toISO(),
  maxDate: maxDate?.value?.toISO(),
  onChange: emitDateAsUpdateEvent,
  onClose: emitDateAsUpdateEvent,
  onReady: (_: unknown, __: unknown, data: {altInput?: HTMLElement, input: HTMLElement}) => {
    if (!data.altInput) {
      return;
    }

    // altInput should have the id of our root input, because the root input is hidden and we want altInput to be
    // focused when clicking on a label for the DatePicker
    const id = data.input.id;
    data.input.id = '';
    data.altInput.id = id;
  },
}));

function initFlatPicker() {
  flatPickerInstance.value = flatpickr(root.value!, options.value);
}

function destroyFlatPicker() {
  if (!flatPickerInstance.value) {
    throw new Error('FlatPickr destroy called without an instance.');
  }

  flatPickerInstance.value.destroy();
}

watchEffect(() => {
  if (flatPickerInstance.value === null) {
    return;
  }

  const hooks = [
    'onChange',
    'onClose',
    'onDestroy',
    'onMonthChange',
    'onOpen',
    'onYearChange',
  ];

  // passing the hooks to flatPicker again does not work, so we need to remove them from the config object
  const optionsWithoutHooks = omit(options.value, hooks);

  flatPickerInstance.value.set(optionsWithoutHooks);
  if (optionsWithoutHooks.defaultDate) {
    flatPickerInstance.value.setDate(optionsWithoutHooks.defaultDate);
  }
});

useMutationObserver(root, (mutations) => {
  // altInput is the input which flatpickr renders. root is the input we render, which flatpickr hides.
  // We change the inputs classes when error occurs, if that happens, we also want the altInput to have those styles.
  // So, using this MutationObserver, we react to any change on the element and "transfer" the classes from the root
  // altInput
  if (mutations[0] && flatPickerInstance.value?.altInput && root.value?.className) {
    flatPickerInstance.value.altInput.className = root.value?.className;
  }
}, {
  attributes: true,
});

onMounted(initFlatPicker);

onUnmounted(destroyFlatPicker);
</script>

<style>
.flatpickr-day.selected {
  @apply bg-primary-600;
}
</style>
