import {onMounted, ref} from 'vue';
import {mapProvider} from '@app/location/MapProvider';

export const useMap = () => {
    const scriptLoading = ref(true);

    function onLoaded() {
        scriptLoading.value = false;
    }

    function attachMapProviderScript() {
        const scriptUrl = mapProvider().getScriptUrl();

        if (scriptUrl === null) {
            scriptLoading.value = false;
            return;
        }

        const scriptId = 'map-provider-script';
        const scriptTagAlreadyAttached = !!document.getElementById(scriptId);

        if (scriptTagAlreadyAttached) {
            scriptLoading.value = false;
            return;
        }

        (window as any).googleMapsInitialized = onLoaded;

        const scriptTag = document.createElement('script');
        scriptTag.id = scriptId;
        scriptTag.src = scriptUrl;
        document.body.appendChild(scriptTag);
    }

    onMounted(attachMapProviderScript);

    return {
        scriptLoading,
    };
};
