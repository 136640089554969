<template>
  <div>
    <TrLabel
      :for="uid"
    >
      {{ label }}
    </TrLabel>

    <div class="mt-1">
      <TrInput
        :model-value="modelValue"
        :rules="rules"
        :errors="errors"
        v-bind="inputAttr"
        @update:model-value="onModelValueUpdate($event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, toRefs, useAttrs} from 'vue';
import TrInput from '@app/forms/TrInput.vue';
import Rule from '@app/forms/rules/Rule';
import TrLabel from '@app/forms/TrLabel.vue';
import uuidFactory from '@app/uuid/UuidFactory';

defineOptions({
    inheritAttrs: false,
});

interface Props {
  label: string;
  type?: string;
  id?: string;
  modelValue?: string | number;
  errors?: string[];
  rules?: Rule<string | number | undefined>[];
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  id: undefined,
  modelValue: undefined,
  errors: () => [],
  rules: () => [],
});

const {
  id,
  type,
  modelValue,
} = toRefs(props);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string|undefined): void,
  (e: 'update:errors', errors?: string[]): void,
}>();

const uid = computed(() => id?.value ?? `input-${uuidFactory.v4()}`);
const attrs = useAttrs();

const inputAttr = computed(() => ({
  ...attrs,
  type: type.value,
  id: uid.value,
}));

const onModelValueUpdate = (value: string|undefined) => {
  emit('update:modelValue', value);
  emit('update:errors', []);
};
</script>
