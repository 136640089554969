import {RouteRecordRaw} from 'vue-router';
import TenderDetails from '@app/tender/details/TenderDetails.vue';
import Tender from '@app/tender/Tenders.vue';
import CreateTender from '@app/tender/create/CreateTender.vue';
import UpdateTender from '@app/tender/create/UpdateTender.vue';
import MyTenders from '@app/tender/mytenders/MyTenders.vue';
import DuplicateTender from '@app/tender/duplicate/DuplicateTender.vue';
import TenderListPage from '@app/tender/TenderListPage.vue';
import DeactivateFilterNotification from '@app/tender/filter/DeactivateTenderFilterNotification.vue';

export const tenderDetailsRoute: RouteRecordRaw = {
    path: '/tenders/:id',
    component: TenderDetails,
    name: 'TenderDetails',
};

export const tenderListRoute: RouteRecordRaw = {
    path: '/tenderlist/:id',
    component: TenderListPage,
    name: 'TenderListPage',
};

export const tendersRoute: RouteRecordRaw = {
    name: 'tenders',
    path: '/tenders',
    component: Tender,
};

export const createTenderRoute: RouteRecordRaw = {
    path: '/tenders/create',
    component: CreateTender,
    name: 'CreateTender',
};

export const updateTenderRoute: RouteRecordRaw = {
    path: '/tenders/:id/update',
    component: UpdateTender,
    name: 'UpdateTender',
};

export const duplicateTenderRoute: RouteRecordRaw = {
    path: '/tenders/:id/duplicate',
    component: DuplicateTender,
    name: 'DuplicateTender',
};

export const myTendersRoute: RouteRecordRaw = {
    path: '/mytenders',
    component: MyTenders,
    name: 'MyTenders',
};

export const deactivateFilterNotification: RouteRecordRaw = {
    path: '/deactivate-filter-notification/:id',
    component: DeactivateFilterNotification,
    meta: {
        blank: true,
    },
};

export const tenderRoutes = [
    tenderDetailsRoute,
    tenderListRoute,
    tendersRoute,
    createTenderRoute,
    updateTenderRoute,
    duplicateTenderRoute,
    myTendersRoute,
    deactivateFilterNotification,
];
