import {ValidationErrorCode} from '@app/http/ValidationErrorCode';
import {HttpError} from '@app/http/HttpError';

/**
 * In expected error cases (not backend/infra problems), we attach an errorCode to responses.
 * This is the error for those responses.
 */
export class HttpErrorWithCode extends HttpError{
    public readonly validationErrorCode: ValidationErrorCode;

    constructor(message: string, validationErrorCode: ValidationErrorCode) {
        super(message);
        this.validationErrorCode = validationErrorCode;
    }
}
