<template>
  <div class="flex justify-center items-center">
    <div
      class="spinner-border animate-spin inline-block w-5 h-5 border-4 rounded-full"
      role="status"
    >
      <span class="sr-only">{{ $t('generic.loading') }}</span>
    </div>
  </div>
</template>

<style scoped>
.spinner-border {
  vertical-align: -.125em;
  border: .25em solid;
  border-right-color: transparent;
}
</style>
