<template>
  <div class="mb-6 flex justify-end">
    <TrButton
      :loading="fetchingActiveFilter"
      :disabled="fetchingActiveFilter"
      :color-scheme="ButtonColorScheme.SECONDARY"
      @click="showFilterModal = true"
    >
      <template
        v-if="filterCount > 0"
        #prefix
      >
        <span class="flex text-xs items-center justify-center bg-primary-50 rounded-full h-5 w-5">{{
          filterCount
        }}</span>
      </template>
      Filter
      <template #postfix>
        <AdjustmentsHorizontalIcon class="w-5 h-5" />
      </template>
    </TrButton>

    <FilterModal
      v-if="activeFilter"
      :loading="loadingUpdateActiveFilter"
      :search-params="activeFilter"
      :show="showFilterModal"
      @search="onSearch"
      @hide="showFilterModal = false"
    />
  </div>

  <div
    v-if="!loadingInitial && tenders && !tenders.length"
    class="pt-6"
  >
    <NoData>
      <div class="text-center">
        {{ $t('tender.search.empty') }}
      </div>
      <div class="mt-8">
        <Alert
          :mode="AlertMode.SUCCESS"
          title="Transportix ist brandneu!"
        >
          <template #sub>
            <p>
              Täglich registrieren sich neue Speditionen und Unternehmen, die auf der Suche nach freiem Frachtraum
              sind.
            </p>
            <p class="mb-4">
              Wir arbeiten stetig daran, Ihnen auf dieser Seite künftig eine große Auswahl an Aufträgen bieten zu
              können.
            </p>
            <b>Tip:</b> Aktivieren Sie die Benachrichtigungen indem Sie oben auf <i>Filter</i> klicken. So verpassen
            Sie keine neuen Ausschreibungen!
          </template>
        </Alert>
      </div>
    </NoData>
  </div>

  <template v-if="!loadingInitial && tenders && tenders.length">
    <TenderList
      :tenders="tenders"
    />
    <PaginationFooter
      class="mt-6"
      :entity-label="$t('shell.navigation.tenders')"
      :offset-pagination-data="paginationData"
      :loading="loading"
      @change-page="paginationData.currentPage.value = $event"
    />
  </template>
</template>

<script lang="ts" setup>
import TenderList from '@app/tender/TenderList.vue';
import {computed, onMounted, ref, watch} from 'vue';
import tenderService from '@app/tender/TenderService';
import NoData from '@app/support/NoData.vue';
import clock from '@app/time/Clock';
import {truckTypeValues as availableTruckTypeValues} from '@app/tender/trucks/TruckType';
import FilterModal from '@app/tender/search/TenderFilterModal.vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import {AdjustmentsHorizontalIcon} from '@heroicons/vue/24/outline';
import TrButton from '@app/support/TrButton.vue';
import {NotifiableTenderSearchParams} from '@app/tender/search/TenderSearchParams';
import {isEqual, sortBy} from 'lodash';
import {useAsyncAction} from '@app/http/useAsyncAction';
import {fetchActiveTenderFilter, updateActiveTenderFilter as doUpdateActiveFilter} from '@app/tender/filter/TenderFilterService';
import {useOffsetPagination} from '@app/pagination/useOffsetPagination';
import PaginationFooter from '@app/pagination/PaginationFooter.vue';
import {urlParamRef} from '@app/routing/urlParamRef';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import Alert from '@app/feedback/Alert.vue';
import {AlertMode} from '@app/feedback/AlertMode';
import {boolSum} from '@app/support/boolSum';

const {
  execute: getActiveFilter,
  data: activeFilter,
  isFetching: fetchingActiveFilter,
} = useAsyncAction(fetchActiveTenderFilter);

const filterCount = computed(() => boolSum(
    activeFilter.value?.locationDetails,
    activeFilter.value?.perimeter,
    activeFilter.value?.fromDate,
    activeFilter.value?.toDate,
    // no truck types means all truck types and all truck types means no filter
    activeFilter.value?.truckTypes?.length !== 0 && !isEqual(sortBy(activeFilter.value?.truckTypes), sortBy(availableTruckTypeValues)),
));

const {execute: updateActiveFilter, isFetching: loadingUpdateActiveFilter} = useAsyncAction(doUpdateActiveFilter);

async function onSearch(searchParams: NotifiableTenderSearchParams) {
  await updateActiveFilter(searchParams);
  showFilterModal.value = false;
  await getActiveFilter();
  await loadTenders();
}

const showFilterModal = ref(false);
const {
  execute: loadTenders,
  isPending: loadingInitial,
  isFetching: loading,
  data: tenders,
} = useAsyncAction(async () => {
  if (!activeFilter.value) {
    throw 'Active Filter undefined.';
  }

  const todayMidnight = clock.now()
      .withHour(0)
      .withMinute(0);
  const {data} = await tenderService.search({
    latitude: activeFilter.value?.locationDetails?.latitude,
    longitude: activeFilter.value?.locationDetails?.longitude,
    locationRadius: activeFilter.value?.perimeter,
    fromDateTime: activeFilter.value?.fromDate ?? todayMidnight,
    toDateTime: activeFilter.value?.toDate,
    truckTypes: activeFilter.value?.truckTypes,
  }, paginationData.currentPage.value, pageSize.value);

  tenderCount.value = data.totalItems;
  return data.items;
});

useAppShellBarLoader(loading);

const pageSize = ref(25);
const tenderCount = ref(0);
const currentPageParam = urlParamRef('page');
const currentPage = computed({
  get: () => {
    const number = Number(currentPageParam.value);
    if (isNaN(number)) {
      return 1;
    }

    return number;
  },
  set: (newValue: number) => {
    currentPageParam.value = newValue === 1 ? undefined : String(newValue);
  },
});
const paginationData = useOffsetPagination({
  total: tenderCount,
  pageSize,
  currentPage,
});

watch(paginationData.currentPage, () => loadTenders());

onMounted(async () => {
  await getActiveFilter();
  await loadTenders();
});
</script>
