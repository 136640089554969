import {DateTime} from '@app/time/DateTime';
import {Zone} from 'luxon';

class Time {
    private readonly dateTime: DateTime;

    get hour(): number {
        return this.dateTime.hour;
    }

    get minute(): number {
        return this.dateTime.minute;
    }

    get totalMinutes(): number {
        return this.dateTime.hour * DateTime.MINUTES_IN_HOUR + this.dateTime.minute;
    }

    public get zone(): Zone {
        return this.dateTime.zone;
    }

    private constructor(dateTime: DateTime) {
        this.dateTime = dateTime;
    }

    /**
     * @throws InvalidDateTimeError
     */
    static parseIsoUTC(isoString: string) {
        return new Time(DateTime.parseIsoUTC(isoString));
    }

    static parseIsoSystem(isoString: string) {
        return new Time(DateTime.parseIsoSystem(isoString));
    }

    toUTC() {
        return new Time(this.dateTime.toUTC());
    }

    toSystem() {
        return new Time(this.dateTime.toSystem());
    }

    toISO() {
        return this.dateTime.toFormat('HH:mm');
    }

    public toHuman(): string {
        return this.dateTime.toHumanTime();
    }
}

export default Time;
