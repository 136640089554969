import {UuidString} from '@app/uuid/UuidString';
import {Ref, ref, watch} from 'vue';
import Calendar from '@app/time/Calendar';
import {LocationDetails, LocationOption} from '@app/forms/Location';
import auth from '@app/auth/Auth';
import {map} from 'lodash';
import {TruckType, truckTypes} from '@app/tender/trucks/TruckType';
import {TruckBedType, truckBedTypes} from '@app/tender/trucks/TruckBedType';
import {TruckFeature} from '@app/tender/trucks/TruckFeature';
import {CreateTruckOfferRequestPayload} from '@app/truckoffer/truckOfferService';
import {TruckOfferParticipant} from '@app/truckoffer/formTypes';
import Time from '@app/time/Time';
import {TimeString} from '@app/support/TimeString';
import {locationProvider} from '@app/location/LocationProvider';
import keyBy from 'lodash/keyBy';
import UuidFactory from '@app/uuid/UuidFactory';
import {TruckOffer} from '@app/truckoffer/TruckOffer';
import {Err, Ok} from '@app/result/Result';
import {FormContextValidator} from '@app/forms/FormContextContract';

export function useTruckOfferForm() {
    const participants = ref<Record<UuidString, TruckOfferParticipant>>();
    const title = ref<string>('');
    const description = ref<string>('');
    const dateOfExecution = ref<Calendar>();
    const timeFrom = ref<TimeString>();
    const timeTo = ref<TimeString>();
    const locationOption = ref<LocationOption>();
    const location = ref<LocationDetails>();
    const truckType = ref<TruckType>(truckTypes.AXLES_3);
    const truckBedType = ref<TruckBedType>(truckBedTypes.TROUGH);
    const truckFeatures = ref<TruckFeature[]>([]);
    const perimeter = ref<string>();
    const paymentPerTon = ref<boolean>(false);
    const paymentPerTonAmount = ref<string>();
    const paymentPerHour = ref<boolean>(false);
    const paymentPerHourAmount = ref<string>();
    const paymentInvalid = ref(false);

    async function assignRefsFromTruckOffer(truckOffer: Ref<TruckOffer|undefined>) {
        if (!truckOffer.value) {
            return;
        }

        locationOption.value = truckOffer.value.location ? await locationProvider().fetchLocationOptionByDetails(truckOffer.value.location) : undefined;

        const mappedParticipants = truckOffer.value.participants.map((participant): TruckOfferParticipant => ({
            role: participant.role,
            user: participant,
        }));
        participants.value = keyBy(mappedParticipants, () => UuidFactory.v4().toString());
        title.value = truckOffer.value.title;
        description.value = truckOffer.value.description;
        dateOfExecution.value = truckOffer.value.dateOfExecution;
        location.value = truckOffer.value.location;
        timeFrom.value = truckOffer.value.timeFrom.toSystem().toISO();
        timeTo.value = truckOffer.value.timeTo.toSystem().toISO();
        perimeter.value = truckOffer.value.maxRadius.toString();
        truckType.value = truckOffer.value.truckType;
        truckBedType.value = truckOffer.value.truckBedType;
        truckFeatures.value = truckOffer.value.truckFeatures;
        paymentPerTon.value = truckOffer.value.payPerTon;
        paymentPerTonAmount.value = truckOffer.value.payPerTonAmount?.toBig()?.toString();
        paymentPerHour.value = truckOffer.value.payPerHour;
        paymentPerHourAmount.value = truckOffer.value.payPerHourAmount?.toBig()?.toString();
    }

    function prepareCreateTruckOfferPayload(): CreateTruckOfferRequestPayload{
        if (!dateOfExecution.value || !location.value || !participants.value || !perimeter.value || !timeFrom.value || !timeTo.value) {
            throw 'Missing data for truckOffer creation';
        }

        return {
            companyId: auth.getCompanyId().toString(),
            dateOfExecution: dateOfExecution.value.toISO(),
            participants: map(participants.value, (participant) => ({
                role: participant.role,
                userId: participant.user!.id.toString(),
            })),
            title: title.value,
            description: description.value,
            location: location.value,
            payPerHour: paymentPerHour.value,
            // TODO: validate with ZOD?
            payPerHourAmount: Number(paymentPerHourAmount.value),
            payPerTon: paymentPerTon.value,
            payPerTonAmount: Number(paymentPerTonAmount.value),
            maxRadius: Number(perimeter.value),
            truckType: truckType.value,
            truckBedType: truckBedType.value,
            truckFeatures: truckFeatures.value,
            timeFrom: Time.parseIsoSystem(timeFrom.value).toUTC().toISO(),
            timeTo: Time.parseIsoSystem(timeTo.value).toUTC().toISO(),
        };
    }

    const paymentValidator: FormContextValidator = async () => {
        if (!paymentPerHour.value && !paymentPerTon.value) {
            paymentInvalid.value = true;
            return Err.EMPTY;
        }

        return Ok.EMPTY;
    };

    watch(paymentPerTon, () => paymentInvalid.value = false);
    watch(paymentPerHour, () => paymentInvalid.value = false);

    return {
        prepareCreateTruckOfferPayload,
        assignRefsFromTruckOffer,
        paymentValidator,

        participants,
        title,
        description,
        dateOfExecution,
        locationOption,
        location,
        truckType,
        truckBedType,
        truckFeatures,
        perimeter,
        paymentPerTon,
        paymentPerTonAmount,
        paymentPerHour,
        paymentPerHourAmount,
        timeFrom,
        timeTo,
        paymentInvalid,
    };
}
