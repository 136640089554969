// eslint-disable-next-line no-restricted-imports
import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import auth, {hasAuthToken, SessionExpiredError} from '../auth/Auth';
import {HttpClientContract} from './HttpClientContract';
import {RequestConfigContract} from '@app/http/RequestConfigContract';
import {ResponseContract} from '@app/http/ResponseContract';
import merge from 'lodash/merge';
import {HttpError} from '@app/http/HttpError';
import {HttpErrorWithCode} from '@app/http/HttpErrorWithCode';
import {ValidationErrorCode} from '@app/http/ValidationErrorCode';
import {NetworkErrorError} from '@app/http/NetworkErrorError';

class HttpClient implements HttpClientContract {
    private static getConfiguration(): AxiosRequestConfig {
        const config: AxiosRequestConfig = {
            // @ts-ignore
            baseURL: `${import.meta.env.VITE_APP_BACKEND_URL}/api/`,
            headers: {},
        };

        if (config.headers && hasAuthToken.value && !auth.isAuthTokenExpired()) {
            config.headers.Authorization = `Bearer ${auth.getAuthToken()}`;
        }

        return config;
    }

    async get<T = any, R = ResponseContract<T>, D = any>(url: string, config?: RequestConfigContract<D>): Promise<R> {
        await this.keepAuthTokenFresh(url);

        return axios.get<T, R, D>(url, merge(HttpClient.getConfiguration(), config))
            .catch(this.handleError.bind(this));
    }

    async patch<T = any, R = ResponseContract<T>, D = any>(url: string, data?: D, config?: RequestConfigContract<D>): Promise<R> {
        await this.keepAuthTokenFresh(url);

        return axios.patch<T, R, D>(url, data, merge(HttpClient.getConfiguration(), config))
            .catch(this.handleError.bind(this));
    }

    async post<T = any, R = ResponseContract<T>, D = any>(url: string, data?: D, config?: RequestConfigContract<D>): Promise<R> {
        await this.keepAuthTokenFresh(url);

        return axios.post<T, R, D>(url, data, merge(HttpClient.getConfiguration(), config))
            .catch(this.handleError.bind(this));
    }

    async put<T = any, R = ResponseContract<T>, D = any>(url: string, data?: D, config?: RequestConfigContract<D>): Promise<R> {
        await this.keepAuthTokenFresh(url);

        return axios.put<T, R, D>(url, data, merge(HttpClient.getConfiguration(), config))
            .catch(this.handleError.bind(this));
    }

    async delete<T = any, R = ResponseContract<T>, D = any>(url: string, config?: RequestConfigContract<D>): Promise<R> {
        await this.keepAuthTokenFresh(url);

        return axios.delete<T, R, D>(url, merge(HttpClient.getConfiguration(), config))
            .catch(this.handleError.bind(this));
    }

    private handleError(e: unknown): never {
        if (!e || typeof e !== 'object') {
            throw e;
        }

        if (!('isAxiosError' in e) || !e.isAxiosError) {
            this.handleNonAxiosError(e);
        } else {
            this.handleAxiosError(e);
        }
    }

    private handleAxiosError(e: unknown): never {
        const httpError = HttpClient.convertAxiosError(e as AxiosError);

        if (httpError instanceof HttpErrorWithCode) {
            if (httpError.validationErrorCode === ValidationErrorCode.TOKEN_SIGNATURE_INVALID) {
                // Invalid signature, server might have changed secret, logout to try and get a new token
                // Throw SessionExpiredError as it causes a toast about expired session which users recognize
                auth.logout();
                throw new SessionExpiredError;
            }
            throw httpError;
        } else {
            throw httpError;
        }
    }

    private handleNonAxiosError(e: object): never {
        throw e;
    }

    private async keepAuthTokenFresh(url: string) {
        const tokenLessRoutes = [
            'public/tokens',
            'public/login',
            'public/register',
        ];

        const normalizedUrl = url.replace(/^\/+|\/+$/g, '');

        if (tokenLessRoutes.includes(normalizedUrl)) {
            return;
        }

        await auth.ensureValidToken();
    }

    private static convertAxiosError(axiosError: AxiosError): HttpError {
        if (axiosError.code === 'ERR_NETWORK') {
            return new NetworkErrorError(axiosError.message);
        }

        if (axiosError.response && typeof axiosError.response.data === 'object' && axiosError.response.data !== null && 'errorCode' in axiosError.response.data) {
            // @ts-ignore
            return new HttpErrorWithCode(axiosError.message, axiosError.response.data.errorCode);
        }
        return new HttpError(axiosError.message);
    }
}

export default new HttpClient();
