<template>
  <router-link
    :to="to"
    :class="classes"
  >
    <slot />
  </router-link>
</template>

<script lang="ts" setup>
import {RouteLocationRaw} from 'vue-router';
import {LinkColorScheme} from '@app/navigation/LinkColorScheme';
import {toRefs} from 'vue';
import {useLink} from '@app/navigation/useLink';

interface Props {
  to: RouteLocationRaw
  colorScheme?: LinkColorScheme
}

const props = withDefaults(defineProps<Props>(), {
  colorScheme: LinkColorScheme.PRIMARY,
});

const { colorScheme } = toRefs(props);

const { classObject: classes } = useLink(colorScheme);
</script>
