<template>
  <TrBaseGuestWrapper>
    <TrForm
      class="space-y-6"
      @validated="resetPassword"
    >
      <TrLabeledInput
        v-model="password"
        name="password"
        autocomplete="new-password"
        :rules="[required(), minLength(8)]"
        :label="$t('auth.register.password')"
        type="password"
      />

      <TrLabeledInput
        v-model="passwordConfirm"
        v-model:errors="passwordConfirmErrors"
        name="password-verify"
        autocomplete="new-password"
        :rules="[required(), minLength(8)]"
        :label="$t('auth.register.confirmPassword')"
        type="password"
      />

      <TrButton
        type="submit"
        class="w-full"
        :loading="loading"
      >
        {{ $t('auth.resetPassword.submit') }}
      </TrButton>
    </TrForm>
  </TrBaseGuestWrapper>
</template>

<script lang="ts" setup>
import TrButton from '@app/support/TrButton.vue';
import TrLabeledInput from '@app/forms/LabeledInput.vue';
import TrBaseGuestWrapper from '@app/shell/BaseGuestWrapper.vue';
import {ref} from 'vue';
import {HttpErrorHandler} from '@app/http/HttpErrorHandler';
import {authService} from '@app/auth/AuthService';
import {useRoute, useRouter} from 'vue-router';
import {ValidationErrorCode} from '@app/http/ValidationErrorCode';
import {makeToast} from '@app/overlays/toast/ToastManager';
import {ToastMode} from '@app/overlays/toast/ToastMode';
import TrForm from '@app/forms/TrForm.vue';
import {required} from '@app/forms/rules/required';
import {minLength} from '@app/forms/rules/minLength';
import {dashboardRoute} from '@app/dashboard/routes';
import auth from '@app/auth/Auth';
import {useI18n} from 'vue-i18n';

const loading = ref(false);

const route = useRoute();
const resetToken = route.query.t;

const password = ref();
const passwordConfirm = ref();

const passwordConfirmErrors = ref();

const router = useRouter();

const {t} = useI18n();
async function resetPassword() {
    if (typeof resetToken !== 'string') {
        return;
    }

    if (password.value !== passwordConfirm.value) {
        passwordConfirmErrors.value = [t('auth.register.passwordMisMatch')];
        return;
    }

    loading.value = true;

    try {
        const {data} = await authService.resetPassword(resetToken, password.value);
        auth.setTokenPair(data.authToken, data.refreshToken);
        await router.push({name: dashboardRoute.name});
    } catch (e) {
        HttpErrorHandler.for(e)
            .onValidationError(ValidationErrorCode.TOKEN_EXPIRED, () => {
                makeToast({
                    title: t('auth.tokenExpired.title'),
                    body: t('auth.tokenExpired.body'),
                    mode: ToastMode.ERROR,
                });
            })
            .execute();
    }

    loading.value = false;
}
</script>
