import {Activity} from '@app/activities/types/Activity';
import {Uuid} from '@app/uuid/Uuid';
import {DateTime} from '@app/time/DateTime';
import {ActivityType} from '@app/activities/ActivityType';
import {User} from '@app/auth/User';
import {ActivityGenerator} from '@app/activities/ActivityGenerator';
import {ActivitiesResponseItem} from '@app/activities/ActivitiesResponsePayload';
import {UuidString} from '@app/uuid/UuidString';

export class CompanyCreatedActivity implements Activity{
    readonly id: Uuid;
    readonly timestamp: DateTime;
    readonly type: ActivityType;
    readonly user: User;
    readonly companyName: string;
    readonly companyId: Uuid;

    constructor(id: Uuid, timestamp: DateTime, user: User, companyId: Uuid, companyName: string) {
        this.id = id;
        this.timestamp = timestamp;
        this.user = user;
        this.type = ActivityType.COMPANY_CREATED;
        this.companyId = companyId;
        this.companyName = companyName;
    }
}

export class CompanyCreatedActivityGenerator implements ActivityGenerator<CompanyCreatedActivity> {
    generate(data: ActivitiesResponseItem): CompanyCreatedActivity {
        return new CompanyCreatedActivity(
            Uuid.fromString(data.id),
            DateTime.parseIsoUTC(data.activityTimestamp),
            {
                ...data.triggeredBy,
                id: Uuid.fromString(data.triggeredBy.id),
            },
            Uuid.fromString(data.activityPayload.companyId as UuidString),
            data.activityPayload.companyName as string,
        );
    }

    getType(): ActivityType {
        return ActivityType.COMPANY_CREATED;
    }

}
