import toastManager, {ToastCreateDefinition, ToastDefinition} from '@app/overlays/toast/ToastManager';
import {ref} from 'vue';
import {onBeforeRouteLeave, onBeforeRouteUpdate} from 'vue-router';

export function useToast(definition: ToastCreateDefinition, hideOnRouteLeave = false, hideOnRouteUpdate = false) {
    const shownDefinition = ref<ToastDefinition>();

    toastManager.subscribe((allDefinitions) => {
        const definitionStillExists = allDefinitions.some((definition) => definition.uuid.equals(shownDefinition.value?.uuid));
        if (!definitionStillExists) {
            shownDefinition.value = undefined;
        }
    });

    function show() {
        shownDefinition.value = toastManager.addToast(definition);
    }

    function hide() {
        if (!shownDefinition.value) {
            return;
        }

        toastManager.remove(shownDefinition.value.uuid);
    }

    onBeforeRouteLeave(() => {
        if (hideOnRouteLeave) {
            hide();
        }
    });

    onBeforeRouteUpdate(() => {
        if (hideOnRouteUpdate) {
            hide();
        }
    });

    return {
        show,
        hide,
    };
}
