<template>
  <template v-if="!isLoading">
    <Back class="mb-4" />

    <TrForm
      id="create-tender-form"
      @validated="duplicateTender"
    >
      <TrSection
        label-id="duplicate-tender-section-head"
        with-bottom
      >
        <template #head>
          <SectionHeading id="duplicate-tender-section-head">
            {{ $t('tender.create.title') }}
          </SectionHeading>

          <p class="max-w-4xl text-sm text-gray-500">
            {{ $t('tender.create.explain') }}
          </p>
        </template>

        <div class="flex flex-col space-y-6">
          <TenderForm
            v-model:participants="participants"
            v-model:title="title"
            v-model:description="description"
            v-model:date-of-execution="dateOfExecution"
            v-model:payment-scheme="paymentScheme"
            v-model:payment-amount-suggestion="paymentAmountSuggestion"
            v-model:location="location"
            v-model:location-option="locationOption"
            v-model:unload-location="unloadLocation"
            v-model:unload-location-option="unloadLocationOption"
            v-model:slots="slots"
            allow-editing-taken-slots
          />
        </div>

        <template #bottom>
          <div class="flex justify-end">
            <TrButton
              class="flex-grow lg:flex-none"
              :loading="isLoadingCreate"
              type="submit"
            >
              {{ $t('tender.create.submit') }}
            </TrButton>
          </div>
        </template>
      </TrSection>
    </TrForm>
  </template>
</template>

<script lang="ts" setup>
import {computed, ref, watchEffect} from 'vue';
import TrButton from '@app/support/TrButton.vue';
import TrForm from '@app/forms/TrForm.vue';
import tenderService from '@app/tender/TenderService';
import {useRoute, useRouter} from 'vue-router';
import {tenderDetailsRoute} from '@app/tender/routes';
import TenderForm from '@app/tender/create/TenderForm.vue';
import {Tender} from '@app/tender/Tender';
import {Uuid} from '@app/uuid/Uuid';
import {useTenderForm} from '@app/tender/create/useTenderForm';
import Back from '@app/routing/Back.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import TrSection from '@app/support/TrSection.vue';
import SectionHeading from '@app/support/SectionHeading.vue';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';

const router = useRouter();
const route = useRoute();

const {
    prepareCreateTenderPayload,
    assignRefsFromTender,
    participants,
    title,
    description,
    dateOfExecution,
    paymentScheme,
    paymentAmountSuggestion,
    locationOption,
    unloadLocationOption,
    location,
    unloadLocation,
    slots,
} = useTenderForm();

const {isFetching: isLoadingCreate, execute: duplicateTender} = useAsyncAction(async function () {
    const createTenderPayload = prepareCreateTenderPayload();

    const {data} = await tenderService.create(createTenderPayload);

    await router.push({name: tenderDetailsRoute.name, params: {id: data.toString()}});
});

const sourceTenderId = computed(() => {
    const idParam = route.params.id as string || undefined;
    return idParam ? Uuid.fromString(idParam) : undefined;
});

const sourceTender = ref<Tender>();

const {isFetching: isLoading, execute: fetchTenderDetails} = useAsyncAction(async function () {
    if (!sourceTenderId.value) {
        return;
    }

    const {data} = await tenderService.fetchTenderDetails(sourceTenderId.value);
    sourceTender.value = data;
});

useAppShellBarLoader(isLoading);

if (sourceTenderId.value) {
    fetchTenderDetails();
}

watchEffect(async () => {
    await assignRefsFromTender(sourceTender);
});

</script>
