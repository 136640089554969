import {createApp} from 'vue';
import App from './App.vue';
import './index.css';
import router from '@app/routing/router';
import i18n from '@app/i18n/i18n';
import {errorHandler} from '@app/errors/errorHandler';
import * as Sentry from '@sentry/vue';

const app = createApp(App);

if (import.meta.env.VITE_APP_SENTRY_URL) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_APP_SENTRY_URL,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: [],
            }),
        ],
        environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
    });
}

app.use(router)
    .use(i18n);

window.onerror = (message, source, lineno, colno, error) => errorHandler(error);
window.addEventListener('unhandledrejection', (event) => errorHandler(event.reason));
app.config.errorHandler = (err) => {
    console.error(err);
    errorHandler(err);
};

app.mount('#app');
