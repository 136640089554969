<template>
  <div :class="$attrs.class">
    <Listbox
      v-bind="listboxAttrs"
      :model-value="modelValue"
      as="div"
      :disabled="disabled"
      :multiple="multiple"
      @update:model-value="updateModelValue"
    >
      <ListboxLabel class="block text-sm font-medium text-gray-700">
        {{ label }}
      </ListboxLabel>
      <div class="mt-1 relative">
        <ListboxButton
          :title="title"
          :class="{
            'bg-white relative w-full border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm': true,
            'border-gray-300 ': !disabled && !hasErrors,
            'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500': hasErrors,
            'text-gray-700 placeholder-gray-300': disabled
          }"
        >
          <span class="block truncate">{{ buttonText }}&nbsp;</span>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              :class="{
                'h-5 w-5': true,
                'text-gray-400': !hasErrors,
                'text-red-400': hasErrors
              }"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="[value, itemLabel] in items"
            :key="value"
            v-slot="{ active, selected }"
            as="template"
            :value="value"
          >
            <li :class="[active ? 'text-white bg-primary-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ itemLabel }}
              </span>

              <span
                v-if="selected"
                :class="[active ? 'text-white' : 'text-primary-600', 'absolute inset-y-0 right-0 flex items-center pr-4']"
              >
                <CheckIcon
                  class="h-5 w-5"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </div>
    </Listbox>

    <FieldErrors :errors="combinedErrors" />
  </div>
</template>

<script lang="ts" setup>
import {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions} from '@headlessui/vue';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid';
import {computed, toRefs, useAttrs} from 'vue';
import Rule from '@app/forms/rules/Rule';
import {useErrorHandling} from '@app/forms/useErrorHandling';
import FieldErrors from '@app/forms/FieldErrors.vue';

defineOptions({
    inheritAttrs: false,
});

interface Props {
  modelValue: string | number | string[]
  label: string
  items: Map<string | number, string>
  disabledHint?: string,
  disabled?: boolean,
  rules?: Rule<unknown>[],
  errors?: string[]
  multiple?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabledHint: undefined,
  rules: () => [],
  errors: () => [],
  multiple: false,
});

const {disabledHint, disabled, items, modelValue, rules, errors} = toRefs(props);

const {hasErrors, combinedErrors, resetInternalErrors} = useErrorHandling(rules, modelValue, 'labeledSelect', errors);

const title = computed(() => disabled.value && disabledHint.value ? disabledHint.value : undefined);

interface Events {
  (e: 'update:model-value', value: Props['modelValue']): void;
}
const emit = defineEmits<Events>();

const buttonText = computed(() => {
  if (!Array.isArray(modelValue.value)) {
    return items.value.get(modelValue.value);
  }

  if (modelValue.value.length === 0) {
    return undefined;
  }

  return modelValue.value.map((value) => items.value.get(value)).join(', ');
});

function updateModelValue(event: Props['modelValue']) {
  resetInternalErrors();
  emit('update:model-value', event);
}

const attrs = useAttrs();
const listboxAttrs = computed(() => {
  const attrsCopy = {...attrs};
  delete attrsCopy.class;
  return attrsCopy;
});
</script>
