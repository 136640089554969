<template>
  <template v-if="userHasRequiredPermissions">
    <slot />
  </template>
</template>

<script lang="ts" setup>
import {computed, toRefs} from 'vue';
import {Permission} from '@app/auth/permissions/Permission';
import {userPermissions} from '@app/auth/Auth';

interface Props {
  permissions: Set<Permission>
}

const props = defineProps<Props>();
const {permissions} = toRefs(props);

const userHasRequiredPermissions = computed(() => {
  return Array.from(permissions.value)
      .every((requiredPermission) => userPermissions.value.has(requiredPermission));
});
</script>
