<template>
  <li
    class="rounded-lg border border-l-[6px] border-l-gray-200 my-2"
    :class="borderClass"
  >
    <div class="flex-1 min-w-0 py-4 px-4">
      <div class="flex items-center justify-between">
        <p class="text-sm font-medium text-gray-900 truncate">
          <template v-if="partialOrder.contractor">
            {{ partialOrder.contractor.name }}
          </template>
          <template v-else>
            {{ $t('truckOffer.details.partialOrders.anonymous') }} <span class="font-light text-gray-600">({{ $t('truckOffer.details.partialOrders.anonymousNotice') }})</span>
          </template>
        </p>
        <PartialOrderActions
          :can-accept="canAccept"
          :can-reject="canReject"
          :can-revoke="canRevoke"
          :can-confirm="canConfirm"
          @partial-order-accepted="$emit('partial-order-accepted', partialOrder)"
          @partial-order-rejected="$emit('partial-order-rejected', partialOrder)"
          @partial-order-revoked="$emit('partial-order-revoked', partialOrder)"
          @partial-order-confirmed="$emit('partial-order-confirmed', partialOrder)"
        />
      </div>

      <div class="mt-2 lg:flex lg:justify-between lg:space-x-4">
        <div class="lg:flex lg:space-x-4 flex-1">
          <div class="mt-2 flex text-sm text-gray-500 lg:mt-0">
            <p v-if="partialOrder.message">
              "{{ partialOrder.message }}"
            </p>
            <p
              v-else
              class="italic"
            >
              {{ $t('tender.page.partialOrders.noMessage') }}
            </p>
          </div>
        </div>


        <div class="flex flex-col">
          <div
            class="mt-2 flex flex-col lg:flex-row items-start lg:items-center text-sm text-gray-500 lg:mt-0 space-y-1 lg:space-y-0 lg:space-x-4 flex-none"
          >
            <div class="flex items-center">
              <span
                class="inline-flex items-center gap-x-1.5 rounded-md"
              >
                <svg
                  :class="`h-1.5 w-1.5 mx-2 lg:mx-0 ${badgeColor} ${partialOrder.status === truckOfferPartialOrderStatuses.PENDING ? 'animate-pulse' : ''}`"
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                >
                  <circle
                    cx="3"
                    cy="3"
                    r="3"
                  />
                </svg>
                {{ $t('tender.page.partialOrders.' + partialOrder.status.toLowerCase()) }}
              </span>
            </div>
            <div class="mt-2 lg:mt-0 flex items-center">
              <CurrencyEuroIcon
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Money :value="partialOrder.paymentAmount" />
              {{ $t(`tender.paymentScheme.${partialOrder.paymentScheme.toLowerCase()}`) }}
            </div>
            <div class="flex items-center">
              <CalendarIcon
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <p>
                <time :datetime="partialOrder.createdAt.toISODate()">{{
                  partialOrder.createdAt.toHumanFull()
                }}
                </time>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import {TruckOfferPartialOrder} from '@app/truckoffer/TruckOfferPartialOrder';
import {computed, toRefs} from 'vue';
import {truckOfferPartialOrderStatuses} from '@app/truckoffer/TruckOfferPartialOrderStatus';
import PartialOrderActions from '@app/truckoffer/PartialOrderActions.vue';
import {userCompanyId} from '@app/auth/Auth';
import {TruckOffer} from '@app/truckoffer/TruckOffer';
import {TruckOfferStatus} from '@app/truckoffer/TruckOfferStatus';
import {CalendarIcon, CurrencyEuroIcon} from '@heroicons/vue/20/solid';
import Money from '@app/money/money/Money.vue';

interface Props {
  truckOffer: TruckOffer
  partialOrder: TruckOfferPartialOrder
}

const props = defineProps<Props>();

interface Events {
  (e: 'partial-order-rejected', partialOrder: TruckOfferPartialOrder): void,
  (e: 'partial-order-accepted', partialOrder: TruckOfferPartialOrder): void,
  (e: 'partial-order-revoked', partialOrder: TruckOfferPartialOrder): void,
  (e: 'partial-order-confirmed', partialOrder: TruckOfferPartialOrder): void,
}

defineEmits<Events>();

const {partialOrder, truckOffer} = toRefs(props);

const isTruckOfferCreatorCompany = computed(() => {
  if (!truckOffer.value?.company.id) {
    return false;
  }

  return userCompanyId.value?.equals(truckOffer.value.company.id) ?? false;
});
const isPartialOrderCreatorCompany = computed(() => {
  if (!partialOrder.value?.contractor) {
    return false;
  }

  return userCompanyId.value?.equals(partialOrder.value.contractor.id) ?? false;
});

const canAccept = computed(() =>
    isTruckOfferCreatorCompany.value
    && TruckOfferStatus.OPEN === truckOffer.value.status
    && (truckOfferPartialOrderStatuses.PENDING === partialOrder.value.status || truckOfferPartialOrderStatuses.REJECTED === partialOrder.value.status),
);

const canReject = computed(() =>
    isTruckOfferCreatorCompany.value
    && [TruckOfferStatus.OPEN, TruckOfferStatus.TAKEN].includes(truckOffer.value.status)
    && (truckOfferPartialOrderStatuses.PENDING === partialOrder.value.status || truckOfferPartialOrderStatuses.ACCEPTED === partialOrder.value.status),
);

const canRevoke = computed(() =>
    isPartialOrderCreatorCompany.value
    && [TruckOfferStatus.OPEN, TruckOfferStatus.TAKEN].includes(truckOffer.value.status)
    && truckOfferPartialOrderStatuses.REJECTED !== partialOrder.value.status
    && truckOfferPartialOrderStatuses.REVOKED !== partialOrder.value.status,
);

const canConfirm = computed(() =>
    isPartialOrderCreatorCompany.value
    && [TruckOfferStatus.OPEN, TruckOfferStatus.TAKEN].includes(truckOffer.value.status)
    && truckOfferPartialOrderStatuses.OUTDATED === partialOrder.value.status,
);

const borderClass = computed(() => {
  return ({
    [truckOfferPartialOrderStatuses.PENDING]: 'border-l-yellow-300',
    [truckOfferPartialOrderStatuses.ACCEPTED]: 'border-l-green-300',
    [truckOfferPartialOrderStatuses.REVOKED]: 'border-l-red-300',
    [truckOfferPartialOrderStatuses.REJECTED]: 'border-l-red-300',
    [truckOfferPartialOrderStatuses.OUTDATED]: 'border-l-gray-300',
  })[partialOrder.value?.status];
});

const badgeColor = computed(() => {
  return {
    [truckOfferPartialOrderStatuses.PENDING]: 'fill-yellow-500',
    [truckOfferPartialOrderStatuses.ACCEPTED]: 'fill-green-500',
    [truckOfferPartialOrderStatuses.REVOKED]: 'fill-red-500',
    [truckOfferPartialOrderStatuses.REJECTED]: 'fill-red-500',
    [truckOfferPartialOrderStatuses.OUTDATED]: 'fill-gray-500',
  }[partialOrder.value.status];
});
</script>
