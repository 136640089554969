<template>
  <div class="relative">
    <div
      class="absolute inset-0 flex items-center"
      aria-hidden="true"
    >
      <div class="w-full border-t border-gray-300" />
    </div>
    <div class="relative flex justify-center">
      <slot />
    </div>
  </div>
</template>
