<template>
  <DatePicker
    v-bind="$attrs"
    :model-value="modelValue"
    :class="{
      'appearance-none rounded-md block w-full focus:outline-none sm:text-sm rounded-m': true,
      'border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500': !hasErrors,
      'border-red-300 pr-10 text-red-900 focus:ring-red-500 focus:border-red-500': hasErrors,
    }"
    :min-date="minDate"
    :max-date="maxDate"
    :aria-invalid="hasErrors.toString()"
    @update:model-value="onUpdateModelValue"
  />

  <FieldErrors :errors="combinedErrors" />
</template>

<script lang="ts" setup>
import DatePicker from '@app/time/DatePicker/DatePicker.vue';
import {toRefs} from 'vue';
import Calendar from '@app/time/Calendar';
import {useErrorHandling} from '@app/forms/useErrorHandling';
import Rule from '@app/forms/rules/Rule';
import FieldErrors from '@app/forms/FieldErrors.vue';

defineOptions({
    inheritAttrs: false,
});

interface Props {
  modelValue?: Calendar,
  minDate?: Calendar,
  maxDate?: Calendar,
  errors?: string[],
  rules?: Rule<Calendar|undefined>[],
}

const props = withDefaults(defineProps<Props>(),{
  modelValue: undefined,
  minDate: undefined,
  maxDate: undefined,
  errors: () => [],
  rules: () => [],
});

const {errors, rules, modelValue} = toRefs(props);

const emit = defineEmits<{
  (e: 'update:modelValue', date: Calendar|undefined): void
  (e: 'update:errors', date: string[]): void
}>();

const {combinedErrors, hasErrors, resetInternalErrors} = useErrorHandling(rules, modelValue, 'date', errors);

const onUpdateModelValue = (value: Calendar|undefined) => {
  emit('update:modelValue', value);
  emit('update:errors', []);

  resetInternalErrors();
};
</script>
