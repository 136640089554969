<template>
  <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all sm:my-4 sm:align-middle sm:w-full">
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
          :class="{
            'bg-blue-100': true,
          }"
        >
          <InformationCircleIcon
            class="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ title }}
          </h3>
          <div class="mt-2 text-sm text-gray-500">
            {{ body }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {InformationCircleIcon} from '@heroicons/vue/24/outline';
import {defineComponent} from 'vue';

export default defineComponent({
  components: {
    InformationCircleIcon,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    body: {
      type: String,
      required: true,
    },
  },
});
</script>
