<template>
  <button
    type="button"
    :class="classes"
  >
    <slot />
  </button>
</template>

<script lang="ts" setup>
import {useLink} from '@app/navigation/useLink';
import {ref} from 'vue';
import {LinkColorScheme} from '@app/navigation/LinkColorScheme';

const { classObject: classes } = useLink(ref(LinkColorScheme.PRIMARY));
</script>
