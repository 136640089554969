import {UuidFactoryContract} from '@app/uuid/UuidFactoryContract';
import {Uuid} from '@app/uuid/Uuid';
import {v4} from 'uuid';

class UuidFactory implements UuidFactoryContract{
    v4(): Uuid {
        return Uuid.fromString(v4());
    }
}

export default new UuidFactory();
