<template>
  <button
    type="button"
    class="text-gray-400 hover:text-gray-500 flex items-center focus-visible:ring-primary-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
    @click="goBack"
  >
    <ArrowLeftIcon
      class="h-5 w-5 flex-shrink-0 mr-2"
      aria-hidden="true"
    />
    zurück
  </button>
</template>

<script lang="ts" setup>
import { ArrowLeftIcon } from '@heroicons/vue/20/solid';
import {useRouter} from 'vue-router';

const router = useRouter();

function goBack() {
  router.go(-1);
}
</script>
